import {TargetSubCompany} from "./targetSubCompany";

/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PreviewLetterDetailsModel {
    complementData?: string;
    correctionData?: string;
    targetId?: string;
  targetSubCompanies?: Array<TargetSubCompany>;
    type?: PreviewLetterDetailsModel.TypeEnum;
    jsonData?: string;
}
export namespace PreviewLetterDetailsModel {
    export type TypeEnum = 'CORRECT' | 'DELETE' | 'REQUEST' | 'REPLY' | 'REMINDER';
    export const TypeEnum = {
        CORRECT: 'CORRECT' as TypeEnum,
        DELETE: 'DELETE' as TypeEnum,
        REQUEST: 'REQUEST' as TypeEnum,
        REPLY: 'REPLY' as TypeEnum,
        REMINDER: 'REMINDER' as TypeEnum
    }
}
