export enum InputTypeEnum {
  EMAIL = <any> 'email',
  PASSWORD = <any> 'password',
  TEXT = <any> 'text',
  PASSWORDTEXT = <any> 'text'
}

export enum BucketEnum {
  MY_PROFILE = <any>'MY_PROFILE',
  MY_HOME = <any>'MY_HOME',
  TRAVEL = <any>'TRAVEL',
  SPORT = <any>'SPORT',
  HEALTH = <any>'HEALTH',
  VACATION = <any>'VACATION',
  INTERNET = <any>'INTERNET',
  COMMUNITY = <any>'COMMUNITY',
  MEDIA = <any>'MEDIA',
  AUTHORITIES = <any> 'AUTHORITIES',
  SHOPPING = <any>'SHOPPING',
  INTERESTS = <any>'INTERESTS',
  FINANCIAL = <any>'FINANCIAL',
  EDUCATION = <any>'EDUCATION'
}

export enum FileDimension {
  PICTURE = 15000000,
  ZIP = 15000000,
  TOTAL_DIMENSION = 15000000,
  MAXIMUM_ZIP_DIMENSION = 2000000000
}

export enum SimpleModalAction {
  DELETE_AVATAR = <any> 'DELETE_AVATAR'
}
