import { Injectable, EventEmitter } from '@angular/core';
import { Observable  } from 'rxjs';


@Injectable()
export class LoadingSpinnerService {

    private spinnerSubject: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    show() {
        this.spinnerSubject.emit(true);
    }

    hide() {
        this.spinnerSubject.emit(false);
    }

    getMessage(): Observable<any> {
        return this.spinnerSubject.asObservable();
    }
}
