import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-session-logout-modal',
  templateUrl: './session-logout-modal.component.html',
  styleUrls: ['./session-logout-modal.component.scss']
})
export class SessionLogoutModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SessionLogoutModalComponent>,
              public translate: TranslateService,
              ) { }
  exitHovered = false;
  ngOnInit(): void {
  }
  onCloseBtnClick() {
    this.dialogRef.close({data: 'close_modal'});
  }
}
