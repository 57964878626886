import { Angulartics2 } from 'angulartics2';
import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';

@Injectable()
export class TrackingService {

  constructor(private tracker: Angulartics2, private matomoTracker: MatomoTracker) { }


  public static PAYMENT="Payment";
  public static ACTION_PRESS_BUTTON="Press pay button";
  public static ACTION_EXPAND_PAYMENT="ExpandPaymentScreen";

  public static ACTION_ERROR_PAYMENT="ErrorPayment";
  public static ACTION_SUCCES_PAYMENT="SuccessPayment";

  public static TARGET_CATEGORY_EVENT="Target";
  public static TARGET_UPLOAD_EXTENSION_ERROR="UploadExtensionError";
  public static TARGET_UPLOAD_MAX_DIMENSION_ERROR="UploadDimensionError";
  public static ACTION_UPLOAD="Upload";
  public static ACTION_REUPLOAD="ReUpload";
  public static ACTION_UPLOAD_ERROR_FRONTEND="ErrorFrontend";

  public static DATA_REQUEST_EVENT="DataRequest";
    public static ACTION_SEND="Send";
  public static ACTION_CANCEL="Cancel";

  public static DATA_CATEGORY_EVENT="Data";
  public static ACTION_CORRECTION="Correction";
  public static ACTION_DELETION="Deletion";

  trackEventWithCustomDimension(category: string, action: string, name?: string, value?: number, dimension?: string){
    this.addCustomDimension(dimension);
    this.trackEvent(category, action, name);
  }

  //TBD: requires custom dimension setup in Matomo Console
  addCustomDimension(dimensionTarget?: string){
    // if (dimensionTarget){
    //   let property = {
    //     dimension1: dimensionTarget,
    //     dimension2: "LoggedIn"
    //   }
    //   console.log("TRACKER addCustomDimension " +  property);
    //   this.piwik.setUserProperties(property);
    // }
  }

  //TBD: requires custom dimension setup in Matomo Console
  setLoggedInDimension(isLoggedIn){
    // let loggedin = isLoggedIn?"LoggedIn":"NotLoggedIn";
    // let property = {
    //   dimension2: loggedin
    // }
    // console.log("TRACKER isLoggedIn " + loggedin);
    // this.piwik.setUserProperties(property);
  }

  setUsername(userId?: string) {
    console.log("TRACKER setUsername " +  userId);
    this.matomoTracker.setUserId(userId);
  }

  trackEvent(category: string, action: string, name?: string) {
    console.log("trackEvent "+category+" "+action+" "+name);
    let eventObject = {
      category: category,
      action: action,
      name: name,
    };
    // console.log("TRACKER trackEvent ", eventObject);
    this.matomoTracker.trackEvent(category,action,name);
  }

  trackAddToCart(productInfo?: any) {
    let product = {
      productSKU: productInfo.productSKU,
      productName: productInfo.productName,
      productCategory: productInfo.productCategory,
      price: productInfo.price,
      quantity: 1
    };
    console.log("TRACKER trackEvent " +  product.productSKU + product.productName + product.productCategory + product.price);
      this.matomoTracker.setEcommerceView(productInfo.productSKU,product.productName,product.productCategory,product.price)
  }

  trackOrderTransaction(orderid?: string, price?:string) {
    console.log("ORDERID: " +  orderid);
    let ecommerceOrder = {
      orderId: orderid,
      grandTotal: price
    };
    // console.log("TRACKER trackEvent ", ecommerceOrder);
      this.matomoTracker.trackEcommerceOrder(orderid,parseFloat(price));
  }

  trackLink(action: string, url: string, linkType: string) {
    let linkObject = {
      url: url,
      linkType: linkType
    };
    //  console.log("TRACKER trackLink ", linkObject);
    this.tracker.eventTrack.next({ action: action, properties: linkObject });
  }


}
