import {
  Component,
  Inject,
  ViewChild,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ClientControllerService, ParameterModel, PrerequisitesModel, TargetDataModel} from '../shared/api-models';
import {TranslateService} from '@ngx-translate/core';
import { TrackingService } from '../shared/services/tracking.service';
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import PrerequisiteTypeEnum = PrerequisitesModel.PrerequisiteTypeEnum;
import {MatStepper} from "@angular/material/stepper";
import {UserDataService} from "../shared/services/user-data.service";
import {DatePipe} from "@angular/common";
import { Modal } from '../shared/models/modal.model';
import { GeneralDialog } from './general.dialog';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import {Subject} from "rxjs";
declare var $: any;



@Component({
    selector: 'prerequirements.dialog',
    templateUrl: 'prerequirements.dialog.html',
    styleUrls: ['prerequirements.dialog.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class PrerequirementsDialog implements OnInit{

  @Input() config: any[] = [];

  // @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('stepper') private stepper: MatStepper;

  title: string;
  body: string;
  exitHovered = false;

  startingIndex: number = 0;
  prerequirementsData = {};
  finalPrerequirementsData = {data: null, userClosed: false};

  buttonDisabled: boolean = false;

  prerequisitesArrayBasic: PrerequisitesModel[] = [];
  prerequisitesArrayTargetRelated: PrerequisitesModel[] = [];

  public pdfInputList: Input[] = [];
  prerequisitesArray: PrerequisitesModel[];
  target: TargetDataModel;
  isFromCertificate: boolean = false;
  public currentIndex: number;
  shouldStep3BeDisabled: boolean = false;
  shouldStep2BeDisabled: boolean = true;
  userPhoneNumber: any = '';

  public shouldDisableCloseButtonInStep1: boolean = true;

  sendSmsEventSubject: Subject<void> = new Subject<void>();

  constructor(private translate: TranslateService,
              private userService: UserDataService,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private authService: AuthService,
              private router: Router,
              private clientService: ClientControllerService,
              public dialogRef: MatDialogRef<PrerequirementsDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any, private tracker: TrackingService) {
    this.prerequisitesArray = data["metadata"];
    this.target = data["target"];

    //prevent dialog close by clicking outside
    dialogRef.disableClose = true;
    // this.isFromCertificate=data["isFromCertificate"];
  }

  ngOnInit(){
    this.initLabels();
    this.initButtonLabelAndLineStyle(this.startingIndex);
    // this.stepper.selectedIndex = this.startingIndex;
    this.currentIndex = this.startingIndex;
    //fix for ie11 scroll bug
    if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)){
      $('.cdk-overlay-pane').css('overflow','auto');
    }
}

  onNoClick(): void {
    if(this.target !== undefined){
      this.tracker.trackEventWithCustomDimension(TrackingService.DATA_REQUEST_EVENT, TrackingService.ACTION_CANCEL, this.target.targetName, this.prerequisitesArray.length, this.target.targetName);
    }
    this.finalPrerequirementsData.userClosed = true;
    if(this.finalPrerequirementsData != null && this.finalPrerequirementsData.data != null){
      this.dialogRef.close(this.finalPrerequirementsData);
    }else{
      this.dialogRef.close({userClosed: true});
    }
    this.removeDataFromStorage();
  }

  removeDataFromStorage(){
    if(localStorage.getItem('dbClientModel') != null){
      localStorage.removeItem('dbClientModel');
    }
    if(localStorage.getItem('dbTarget') != null){
      localStorage.removeItem('dbTarget');
    }
    if(localStorage.getItem('identificationSource') != null){
      localStorage.removeItem('identificationSource');
    }
  }

  sendSmsEventToChild(){
    this.sendSmsEventSubject.next();
  }

  initLabels(){
    this.title = this.translate.instant('PREREQUIREMENTS.TITLE');
    this.body = this.translate.instant('PREREQUIREMENTS.CONTENT');
    this.translatePrerequirements(this.prerequisitesArray);
    this.sortTargetsPrerequirements()
  }

  sortTargetsPrerequirements(){
    this.prerequisitesArray.forEach(res => {
      if(res.prerequisiteType == PrerequisiteTypeEnum.BASICREQUIRED){
        this.prerequisitesArrayBasic.push(res);
      }else{
        this.prerequisitesArrayTargetRelated.push(res);
      }
      //check phone number needed
      if(res.field == 'phoneNumber'){
        this.shouldStep2BeDisabled = false;
      }
    })
    this.userService.getCurrentClientWithCallback(res => {
      //in case no basic data is required, we should automatically skip this step
      if(this.prerequisitesArrayBasic.length == 0 && !this.shouldStep2BeDisabled){
        this.startingIndex = 1;
      }else if(this.prerequisitesArrayBasic.length == 0 && this.shouldStep2BeDisabled){
        this.startingIndex = 2;
      }
      if(this.prerequisitesArrayTargetRelated.length == 0){
        this.shouldStep3BeDisabled = true;
      }//check if user got validated phone number
      if(!res.verifiedPhoneNumber && this.prerequisitesArrayBasic.length == 0){
        this.startingIndex = 1;
        setTimeout(() => {
          this.sendSmsEventToChild()
        },600);
        this.userPhoneNumber = res.phoneNumber;
      }
    })
  }

  lineStyleLogic(index){
    setTimeout(() => {
      let lines = document.getElementsByClassName('mat-stepper-horizontal-line');
      if(index === 1){
        // @ts-ignore
        lines[0].style.borderTopColor = '#00CFA2';
      }
      if(index === 0){
        // @ts-ignore
        lines[0].style.borderTopColor = 'rgba(0, 0, 0, 0.12)';
      }
    },200);

    // let lines = document.getElementsByClassName('mat-stepper-horizontal-line');
    // if(index === 1){
    //   // @ts-ignore
    //   lines[0].style.borderTopColor = '#F1743C';
    // }
    // if(index === 2){
    //   // @ts-ignore
    //   lines[0].style.borderTopColor = '#F1743C';
    //   // @ts-ignore
    //   lines[1].style.borderTopColor = '#F1743C';
    // }
    // if(index === 3){
    //   // @ts-ignore
    //   lines[0].style.borderTopColor = '#F1743C';
    //   // @ts-ignore
    //   lines[1].style.borderTopColor = '#F1743C';
    //   // @ts-ignore
    //   lines[2].style.borderTopColor = '#F1743C';
    // }
  }

  goToNextStep(editable) {
    this.stepper.selected.completed = true;
    this.stepper.selected.editable = editable;
    this.stepper.next();
  }

  initButtonLabelAndLineStyle(index){
    // switch(index){
    //   case 0:
    //     if(this.shouldStep2BeDisabled){
    //       this.buttonText = 'Send data';
    //     }else{
    //       this.buttonText = 'Go to next step';
    //     }
    //     break;
    //   case 1:
    //     this.buttonText = 'Send data';
    //     break;
    // }
    this.lineStyleLogic(index);
  }

  buttonAction(){
    switch(this.stepper.selectedIndex){
      case 0:
        // this.saveCheckbox();
        this.goToNextStep(false);
        break;
      case 1:
        break;
    }
  }

  selectionChange($event: StepperSelectionEvent) {
    this.currentIndex = $event.selectedIndex;
    this.initButtonLabelAndLineStyle(this.currentIndex);
    // if($event.selectedIndex == 1){
    //   // this.startDotsAnimation();
    //   // this.startTimeCountDown();
    //   // window.location.href = environment.deutscheBankUrlMietermappe;
    //   this.checkForValidCredential();
    // }else if($event.selectedIndex == 3){
    //   this.buttonDisabled = false;
    //   // this.startStep4TimeCountDown(this.CURRENT_WAITING_TIME);
    // }
    // console.log($event);
  }

  translatePrerequirements(prerequirements){

    for (let i = 0, len = prerequirements.length; i < len; i++){
      switch(prerequirements[i].description){
        case 'Street Number*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.STREET_NUMBER');
          prerequirements[i].placeholder = '21';
          break;
        case 'Street*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.STREET');
          prerequirements[i].placeholder = 'Parkstraße';
          break;
        case 'City*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.CITY');
          prerequirements[i].placeholder = 'Berlin';
          break;
        case 'Country*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.COUNTRY');
          prerequirements[i].placeholder = 'Deutschland';
          break;
        case 'Postal Code*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.POSTAL_CODE');
          prerequirements[i].placeholder = '12345';
          break;
        case 'Birthday*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.BIRTHDAY');
          break;
        case 'Customer Number*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.CUSTOMER_NUMBER');
          break;
        case 'Customer Email*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.CUSTOMER_EMAIL');
          break;
        case 'Phone Number*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.PHONE_NUMBER');
          break;
        case 'Customer Phone Number*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.CUSTOMER_PHONE_NUMBER');
          break;
        case 'Customer Interested Number*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.CUSTOMER_INTERESTED_NUMBER');
          break;
        case 'Credit Card Number*':
          prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.CUSTOMER_CREDIT_CARD_NUMBER');
          break;
        case 'PayBack Customer Number*':
            prerequirements[i].description = this.translate.instant('PREREQUIREMENTS.PAYBACK_CUSTOMER_NUMBER');
            break;
      }

    }
  }

  dynamicFormAction($event: any) {
    let shouldSaveData: boolean = false;
    if(this.currentIndex == 0){
      shouldSaveData = true;
      if(this.shouldStep2BeDisabled){
        // we need to jump over phone confirmation step if the number is already confirmed
        this.stepper.selectedIndex = 2;
        this.currentIndex = 2;
      }else{
        this.goToNextStep(false);
      }
    }else{
      this.goToNextStep(false);
    }
    if($event.currentStepData == 0){
      this.finalPrerequirementsData = $event;
    }
    this.finalPrerequirementsData.data = Object.assign(this.prerequirementsData, $event.data);
    this.finalPrerequirementsData.userClosed = false;
    this.userPhoneNumber = this.finalPrerequirementsData.data.phoneNumber;
    // this.prerequirementsData = $event.data;
    if($event.currentStepData == 0){
      if(this.shouldStep2BeDisabled && this.shouldStep3BeDisabled){
        this.dialogRef.close(this.finalPrerequirementsData);
      }
    }else if($event.currentStepData == 1){
      if(this.shouldStep3BeDisabled){
        this.dialogRef.close(this.finalPrerequirementsData);
      }
    }else if($event.currentStepData == 2){
      this.dialogRef.close(this.finalPrerequirementsData);
    }
    if(shouldSaveData){
      this.savePrerequirementsData(this.finalPrerequirementsData);
    }
    // if($event.currentStepData == 2){
    //   this.dialogRef.close(this.finalPrerequirementsData);
    // }
    // if($event.currentStepData == 0 && this.shouldStep3BeDisabled && this.shouldStep2BeDisabled){
    //   this.dialogRef.close(this.finalPrerequirementsData);
    // }
    // if($event.currentStepData == 1 && this.shouldStep3BeDisabled){
    //   this.dialogRef.close(this.finalPrerequirementsData);
    // }
  }

  mergePrerequirements(){

  }

  sendPhoneEvent($event: any) {
    if(this.startingIndex == 1 && this.shouldStep3BeDisabled){
      this.dialogRef.close({fromPhoneConfirmation: true});
    }else{
      this.dynamicFormAction({currentStepData: 1});
    }
  }

  disableCloseButton($event: any) {
    //hide close button on step 1
    this.shouldDisableCloseButtonInStep1 = $event
  }

  savePrerequirementsData(result){
      this.removeDataFromStorage();
      if (result && result["data"]) {
        let filledPrerequisites = [];
        let dataPrerequirements = result["data"];
        let phoneNumber = null;
        this.prerequisitesArray.forEach(element => {
          let key = element.field;
          let value = dataPrerequirements[key];
          if (key == "birthday" && value != null) {
            value = this.datePipe.transform(value, "yyyy-MM-dd");
          }
          if (key == "phoneNumber" && value != null) {
            phoneNumber = value;
          }

          let parameter: ParameterModel = {
            targetId: element.targetId,
            prerequisitesId: element.id,
            value: value
          };
          filledPrerequisites.push(parameter);
        });

        if(result["userData"]) {
          let nameParameters = result["userData"];
          let firstNameValue = nameParameters["firstName"];
          if(firstNameValue != null && firstNameValue != undefined && firstNameValue != "") {
            let parameter: ParameterModel = {
              targetId: this.target.targetId,
              prerequisitesId: "",
              value: firstNameValue,
              type: "firstName"
            };
            filledPrerequisites.push(parameter);
          }

          let lastNameValue = nameParameters["lastName"];
          if(lastNameValue != null && lastNameValue != undefined && lastNameValue != "") {
            let parameter: ParameterModel = {
              targetId: this.target.targetId,
              prerequisitesId: "",
              value: lastNameValue,
              type: "lastName"
            };
            filledPrerequisites.push(parameter);
          }

          let genderValue = nameParameters["salutationValue"];
          if(genderValue != null && genderValue.salutationValue != null) {
            genderValue = nameParameters["salutationValue"].salutationValue;
          }
          if(genderValue != null) {
            let parameter: ParameterModel = {
              targetId: this.target.targetId,
              prerequisitesId: "",
              value: genderValue,
              type: "gender"
            };
            filledPrerequisites.push(parameter);
          }
        }

        this.clientService.updateClientProfileFromPrerequisitesUsingPUT(filledPrerequisites, result['identificationSource']).subscribe(response => {
          this.userService.setCurrentClient(null);
          this.userService.getCurrentClientWithCallback(res => {
            this.userService.sendClientMessage(res);
          });
          this.sendSmsEventToChild();
        }, error => {
          this.dialogRef.close({});
          this.openErrorModal(this.translate.instant('ERRORS.' + error.error.code));
        });
      }

  }

  openErrorModal(errorMessage) {
    let modalModel: Modal = {
      closeAction: true,
      extendedTitle: null,
      title: null,
      content: errorMessage,
      input: null,
      action: this.translate.instant('ERROR_MODAL.ACTION'),
      attachments: null,
      footerAction: null,
      type: null
    }
    this.dialog.open(GeneralDialog, {
      width: '522px',
      data: { metadata: modalModel }
    }).afterClosed().subscribe(res => {
      if(errorMessage.includes('bereits ein Userkonto') || errorMessage.includes('already have a user account')) {
        this.authService.logout();
        this.router.navigate(['/login']);
      }
    });
  }
}
