/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Configuration } from "../configuration";
import { BASE_PATH } from "../variables";
import { Observable } from "rxjs";
import { FinoAccountModel } from "../model/finoAccountModel";
import { FinoMoneyTransferModel } from "../model/finoMoneyTransferModel";


@Injectable()
export class IdentificationControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

     /**
     * validateClient
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public validateClientUsingGET(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public validateClientUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public validateClientUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public validateClientUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             '*/*'
         ];
         let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set("Accept", httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         let consumes: string[] = [
             'application/json'
         ];
 
         let basicAuthHeader = localStorage.getItem('access_token');
         headers = headers.set("Authorization", "bearer " + basicAuthHeader);
         return this.httpClient.get<boolean>(`${this.basePath}/identification/fino/validate-client`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

    /**
    * checkFinoMoneyTransferStatus
    * 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public checkFinoMoneyTransferStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public checkFinoMoneyTransferStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public checkFinoMoneyTransferStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public checkFinoMoneyTransferStatusUsingGET(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get(`${this.basePath}/identification/fino/money-transfer-status`,
            {
                withCredentials: this.configuration.withCredentials,
                responseType: 'text',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAccountList
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountListUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<FinoAccountModel>>;
    public getAccountListUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FinoAccountModel>>>;
    public getAccountListUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FinoAccountModel>>>;
    public getAccountListUsingGET(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<FinoAccountModel>>(`${this.basePath}/identification/fino/get-accounts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMoneyTransferValidationRedirectUrl
     * 
     * @param finoMoneyTransferModel finoMoneyTransferModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMoneyTransferValidationRedirectUrlUsingPOST(finoMoneyTransferModel: FinoMoneyTransferModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getMoneyTransferValidationRedirectUrlUsingPOST(finoMoneyTransferModel: FinoMoneyTransferModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getMoneyTransferValidationRedirectUrlUsingPOST(finoMoneyTransferModel: FinoMoneyTransferModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getMoneyTransferValidationRedirectUrlUsingPOST(finoMoneyTransferModel: FinoMoneyTransferModel, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (finoMoneyTransferModel === null || finoMoneyTransferModel === undefined) {
            throw new Error('Required parameter finoMoneyTransferModel was null or undefined when calling getMoneyTransferValidationRedirectUrlUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post(`${this.basePath}/identification/fino/money-transfer-validation-url`,
            finoMoneyTransferModel,
            {
                withCredentials: this.configuration.withCredentials,
                responseType: 'text',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRedirectUrl
     * 
     * @param redirectPath redirectPath
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRedirectUrlUsingPOST(redirectPath: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getRedirectUrlUsingPOST(redirectPath: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getRedirectUrlUsingPOST(redirectPath: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getRedirectUrlUsingPOST(redirectPath: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (redirectPath === null || redirectPath === undefined) {
            throw new Error('Required parameter redirectPath was null or undefined when calling getRedirectUrlUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post(`${this.basePath}/identification/fino/get-redirect-url`,
            redirectPath,
            {
                withCredentials: this.configuration.withCredentials,
                responseType: 'text',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
