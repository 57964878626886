<div class="dialog-container">
  <div class="logo-container">
    <p class="copy-text-primary" style="margin-top:0 !important;" *ngIf="modalModel.content!=null">{{getPreviewTitle()}} </p>
    <hr style="margin-top: 0;" class="line-break">
  </div>

  <div style="height: 100%" class="dialog-content">
    <div style="height: 100%" *ngIf="modalModel.file.type === 'application/pdf'" id="example1" #fileViewer></div>
    <div *ngIf="modalModel.file.type !== 'application/pdf'" class="zoom">
      <img [src]="imageUrl" class="document-image" alt="document image">
    </div>
  </div>

  <div class="modal-alignament" >
    <hr class="down-line-break">
    <button style="margin-right:20px;" *ngIf="modalModel.buttonText !== null || modalModel.buttonText !== undefined" button
            type="submit" class="imd-button-secondary" (click)="onCloseBtnClick()">{{modalModel.buttonText}}</button>
    <button style="margin-right:20px;" *ngIf="modalModel.downloadButton !== null || modalModel.downloadButton !== undefined" button
            type="submit" class="imd-button-secondary" (click)="downloadButton()">{{modalModel.downloadButton}}</button>
    <button *ngIf="showShareButton"
             class="imd-button-secondary" (click)="shareEmail()">{{this.translate.instant('SHARED_CERTIFICATE.HISTORY_BUTTON')}}</button>
  </div>

</div>

