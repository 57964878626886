<div class="dialog-container">
  <div class="logo-container">
    <img alt="logo-image" style="visibility: hidden" class="logo-image" src="../../../assets/logo_images/main-logo/logo_primary_dark.svg">
    <img (click)="onCloseBtnClick()" class="close-button-image"
         (mouseenter)="exitHovered = true;"
         (mouseleave)="exitHovered = false;"
         alt="back-button"
         style="width: 27px; height: 27px;"
         [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
  </div>
  <hr class="line-break">
  <div class="dialog-content">
<!--    forward email text-->
    <div>
      <p *ngIf="modalModel.data.content !== null || modalModel.data.content !== undefined" [innerHTML]="modalModel.data.content" id="mainText" class="copy-text body-text-content">
         </p>
    </div>

    <span *ngIf="modalModel.data.showIcon" style="font-family: 'Font Awesome 5 Free',sans-serif;" class="letter-image fas fa-envelope-open-text"></span>


  </div>

  <mat-dialog-actions align="center">
    <div>
      <button *ngIf="modalModel.data.buttonText !== null || modalModel.data.buttonText !== undefined" button
              type="submit" class="imd-button close-letter-btn" (click)="copyButton()">{{modalModel.data.buttonText}}</button>
    </div>
  </mat-dialog-actions>
<!--  hacky way to copy user email to clipboard, because of browser security restrictions.-->
  <div id="secretInfo" style="display: none;">{{modalModel.imdEmail}}</div>
</div>
