import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {PersistenceService} from "./persistence-service";
import {Router} from "@angular/router";

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
    constructor(private injector: Injector,
                private persistence: PersistenceService,
                private router: Router,
    ) {
        super();
    }
    public handleError(error: any): void {
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case 401:
                  this.persistence.resetLocalStorage();
                  window.location.pathname = '/login';
                  break;
                case 403:
                  this.persistence.resetLocalStorage();
                  window.location.pathname = '/login';
                  break;
                default:
                  throw error;
            }
        }
    }
}
