/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {
  HttpClient, HttpHeaders,
  HttpResponse, HttpEvent, HttpParams
} from '@angular/common/http';

import { Observable }                                        from 'rxjs';


import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { DigitalSelfrequest } from '../model/digitalSelfrequest';
import {ClientCertificateStatuses} from "../model/clientCertificateStatuses";
import {CustomHttpUrlEncodingCodec} from "../encoder";
import {DigitalSelfrequestStatuses} from "../model/digitalSelfrequestStatuses";
import DigitalSelfrequestTypeEnum = DigitalSelfrequest.DigitalSelfrequestTypeEnum;


@Injectable()
export class DigitalSelfRequestControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getDigitalSelfRequest
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getDigitalSelfRequestUsingGET(observe?: 'body', reportProgress?: boolean): Observable<DigitalSelfrequest>;
     public getDigitalSelfRequestUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DigitalSelfrequest>>;
     public getDigitalSelfRequestUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DigitalSelfrequest>>;
     public getDigitalSelfRequestUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         let headers = this.defaultHeaders;

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set("Accept", httpHeaderAcceptSelected);
         }
         let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
         return this.httpClient.get<DigitalSelfrequest>(`${this.basePath}/digitalSelfrequest/get`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

  /**
   * getDigitalSelfRequestByClientAndType
   *
   * @param dsbType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDigitalSelfRequestByClientAndTypeUsingGET(dsbType: string, observe?: 'body', reportProgress?: boolean): Observable<DigitalSelfrequest>;
  public getDigitalSelfRequestByClientAndTypeUsingGET(dsbType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DigitalSelfrequest>>;
  public getDigitalSelfRequestByClientAndTypeUsingGET(dsbType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DigitalSelfrequest>>;
  public getDigitalSelfRequestByClientAndTypeUsingGET(dsbType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<DigitalSelfrequest>(`${this.basePath}/digitalSelfrequest/getDigitalSelfrequestByType/${encodeURIComponent(String(dsbType))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * getOnlyCertificateStatus
   *
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public getOnlyDSBStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<string>;
  public getOnlyDSBStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public getOnlyDSBStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public getOnlyDSBStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/digitalSelfrequest/returnStatus`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


    /**
     * getDigitalSelfRequestStatus
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDigitalSelfRequestStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getDigitalSelfRequestStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getDigitalSelfRequestStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getDigitalSelfRequestStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<string>(`${this.basePath}/digitalSelfrequest/getStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestRefreshData
     * 
     * @param type type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestRefreshDataUsingPOST(type: string, observe?: 'body', reportProgress?: boolean): Observable<DigitalSelfrequest>;
    public requestRefreshDataUsingPOST(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DigitalSelfrequest>>;
    public requestRefreshDataUsingPOST(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DigitalSelfrequest>>;
    public requestRefreshDataUsingPOST(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling requestRefreshDataUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<DigitalSelfrequest>(`${this.basePath}/digitalSelfrequest/update`,
            type,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
   * getDigitalSelfRequestStatusByType
   *
   * @param dsbType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDigitalSelfRequestStatusByTypeUsingGET(dsbType: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public getDigitalSelfRequestStatusByTypeUsingGET(dsbType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public getDigitalSelfRequestStatusByTypeUsingGET(dsbType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public getDigitalSelfRequestStatusByTypeUsingGET(dsbType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/digitalSelfrequest/getStatus/${encodeURIComponent(String(dsbType))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getClientCertificateStatusesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ClientCertificateStatuses>;
  public getClientCertificateStatusesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientCertificateStatuses>>;
  public getClientCertificateStatusesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientCertificateStatuses>>;
  public getClientCertificateStatusesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<ClientCertificateStatuses>(`${this.basePath}/digitalSelfrequest/getClientCertificateStatuses`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getDigitalSelfrequestStatusesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<DigitalSelfrequestStatuses>;
  public getDigitalSelfrequestStatusesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DigitalSelfrequestStatuses>>;
  public getDigitalSelfrequestStatusesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DigitalSelfrequestStatuses>>;
  public getDigitalSelfrequestStatusesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<DigitalSelfrequestStatuses>(`${this.basePath}/digitalSelfrequest/getDigitalSelfrequestStatuses`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public createDigitalSelfRequest(productId: string, observe?: "body", reportProgress?: boolean): Observable<DigitalSelfrequest>;
  public createDigitalSelfRequest(productId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DigitalSelfrequest>>;
  public createDigitalSelfRequest(productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DigitalSelfrequest>>;
  public createDigitalSelfRequest(productId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<DigitalSelfrequest>(`${this.basePath}/digitalSelfrequest/create`,
      productId,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

      /**
     * createOrUpdateSelfrequestS
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrUpdateSelfrequestSUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createOrUpdateSelfrequestSUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createOrUpdateSelfrequestSUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createOrUpdateSelfrequestSUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<any>(`${this.basePath}/digitalSelfrequest/create-selfrequest-s-after-identification`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


  public updateStatusDigitalSelfRequest(userId: string, observe?: "body", reportProgress?: boolean): Observable<any>;
  public updateStatusDigitalSelfRequest(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateStatusDigitalSelfRequest(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateStatusDigitalSelfRequest(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<DigitalSelfrequest>(`${this.basePath}/digitalSelfrequest/updateStatus`,
      userId,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * generateCertificate
   *
   * @param showTargets
   * @param fileType fileType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public generateBriefCertificateUsingPOST(showTargets: boolean, fileType?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public generateBriefCertificateUsingPOST(showTargets: boolean, fileType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public generateBriefCertificateUsingPOST(showTargets: boolean, fileType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public generateBriefCertificateUsingPOST(showTargets: boolean, fileType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (showTargets === null || showTargets === undefined) {
      throw new Error('Required parameter targets was null or undefined when calling generateCertificateUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (fileType !== undefined) {
      queryParameters = queryParameters.set('fileType', <any>fileType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post(`${this.basePath}/digitalSelfrequest/generateCertificate`,
      showTargets,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob',
        reportProgress: reportProgress
      }
    );
  }

}
