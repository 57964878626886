import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ConfirmPhoneModal} from '../../shared/models/modal.model';
import {ClientControllerService} from '../../shared/api-models';
import {UserDataService} from '../../shared/services/user-data.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'phone-code-confirm.dialog',
    templateUrl: 'phone-code-confirm.dialog.html',
    styleUrls: ['./phone-code-confirm.dialog.scss'],
  })
  export class PhoneCodeConfirmDialog implements OnInit{

  modalModel: ConfirmPhoneModal;
  smsCode: string = '';

  inputValue: string = '';
  disabledStatus: boolean;
  public errorVisibility: boolean;
  public errorMessage: string;
  languagesList: any[] = [];
  currentLanguage: string;

  exitHovered = false;
  public resendSMSCodeDisabled: boolean = false;
  public showResendCodeMessage: boolean = false;

  constructor(public dialogRef: MatDialogRef<PhoneCodeConfirmDialog>, @Inject(MAT_DIALOG_DATA, ) public data: any,
              private client: ClientControllerService, private userService: UserDataService, public translate: TranslateService) {
    this.modalModel = data['metadata'];
    this.checkSubmitButtonStatus();
  }

  checkSubmitButtonStatus() {
    if (this.inputValue != null) {
      this.disabledStatus = this.inputValue.trim().length !== 6;
      console.log(this.disabledStatus);
    } else {
      this.disabledStatus = false;
    }
  }

  onCloseBtnClick(): void {
    this.dialogRef.close({});
  }

  onButtonAction($event) {
    this.client.validatePhoneNumberUsingPUT(this.smsCode, this.modalModel.phoneNumber).subscribe(res => {
      this.userService.setCurrentClient(res);
      this.dialogRef.close({data: 'success'});
    }, error => {
        this.errorVisibility = true;
        if (error.error.code === 'INVALID_CODE') {
          this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.INVALID_CODE');
        } else if (error.error.code === 'CLIENT_NOT_FOUND') {
          this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.CLIENT_NOT_FOUND');
        } else if (error.error.code === 'ACTIVATION_CODE_EXPIRED') {
          this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.ACTIVATION_CODE_EXPIRED');
        } else if (error.error.code === 'CODE_ALREADY_USED') {
          this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.CODE_ALREADY_USED');
        } else if (error.error.code === 'PHONE_NUMBER_MISMATCH') {
          this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.PHONE_NUMBER_MISMATCH');
        }
        this.disabledStatus = true;
      });
    }

  onResendButtonAction() {
    this.resendSMSCodeDisabled = true;
    this.client.resendPhoneNumberValidationUsingGET(this.modalModel.phoneNumber).subscribe(res => {
      this.showResendCodeMessage = true;
      setTimeout(() => {
        this.showResendCodeMessage = false;
        this.resendSMSCodeDisabled = false;
      }, 30000)
    },error => {
      this.requestPhoneSmsErrors(error);
    });
  }

  onTextChange(event) {
    this.checkSubmitButtonStatus();
    this.smsCode = event.target.value;
    this.errorVisibility = false;
  }

  ngOnInit(): void {
    this.initLanguages();
    if(this.modalModel.hasLimitError){
      this.errorVisibility = true;
      this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.SMS_CODE_LIMIT');
      this.disabledStatus = true;
      this.resendSMSCodeDisabled = true;
    }
  }
  initLanguages() {
    this.languagesList = this.translate.getLangs();
    this.currentLanguage = this.translate.currentLang;
  }

  requestPhoneSmsErrors(error){
    this.errorVisibility = true;
    if (error.error.code === 'PHONE_VALIDATION_LIMIT') {
      this.errorMessage = this.translate.instant('CONFIRM_SMS_CODE.ERROR.SMS_CODE_LIMIT');
      this.disabledStatus = true;
      this.resendSMSCodeDisabled = true;
    }
  }

}
