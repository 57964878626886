export class BrowserHelper {

  public static isIE() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    return isIE;
  }

  public static detectBrowser(){

    if(navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1 )
    {
      return "Opera";
    }
    else if(navigator.userAgent.indexOf("Edge") != -1 )
    {
      return "Microsoft Edge";
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 )
    {
      return "Google Chrome";
    }
    else if(navigator.userAgent.indexOf("Safari") != -1)
    {
      return "Safari";
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )
    {
      return "Mozilla Firefox"
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 )) //IF IE > 10
    {
      return "Internet Explorer"
    }
    else
    {
      return "Other browser"
    }
  }

}
