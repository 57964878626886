import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {Observable} from "rxjs";
import {TemporaryAddressProductModel} from "../model/TemporaryAddressProductModel";

@Injectable()
export class  WUCControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }



  public getGeoLocationStatus(temporaryAddressProductModel: TemporaryAddressProductModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getGeoLocationStatus(temporaryAddressProductModel: TemporaryAddressProductModel, observe?: 'response', reportProgress?: boolean): Observable<any>;
  public getGeoLocationStatus(temporaryAddressProductModel: TemporaryAddressProductModel, observe?: 'events', reportProgress?: boolean): Observable<any>;
  public getGeoLocationStatus(temporaryAddressProductModel: TemporaryAddressProductModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (temporaryAddressProductModel === null || temporaryAddressProductModel === undefined) {
      throw new Error('Required parameter temporaryAddressProductModel was null or undefined when calling saveTemporaryAddress.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post(`${this.basePath}/wuc/getGeoLocationStatus`,
      temporaryAddressProductModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


}
