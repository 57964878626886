import {map} from 'rxjs/operators';
import {Injectable, OnInit} from '@angular/core';
import { ClientModel } from '../../shared/api-models/model/clientModel';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { ClientControllerService } from '../api-models';
import { addMinutes} from 'date-fns';
import {PersistenceService} from "./persistence-service";
import {TrackingService} from "./tracking.service";
import {ClientEmail} from "../api-models/model/clientEmail";


@Injectable()
export class UserDataService implements OnInit {

    public clientData: ClientModel;
    callComp: any;
    private lastUserRequestDate;
    private cachedClientData: ClientModel;
    public clientEmails: ClientEmail[];

   private storageSub = new Subject<ClientModel>();
   public triggerProfileReload = new BehaviorSubject<boolean>(false);
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor(
        private clientService: ClientControllerService,
        private trackingService:TrackingService,
        private persistence: PersistenceService) { }

    ngOnInit() {
       this.getCurrentClient();
       this.initClientEmails();
    }

  deleteData(){
    return this.triggerProfileReload.asObservable();
  }

  changeMessage(message: string) {
    this.messageSource.error(message);
    this.messageSource.next(message);
  }

    public setCurrentClient(client) {
        this.clientData = client;
        if (this.clientData != null) {
          this.initClientEmails();
          this.trackingService.setLoggedInDimension(true);
          if(this.cachedClientData != null && this.cachedClientData.attachmentModel){
            this.clientData.attachmentModel = this.cachedClientData.attachmentModel;
          }
          this.cachedClientData = this.clientData;
          this.sendClientMessage(this.clientData);
        }else{
          this.trackingService.setLoggedInDimension(false);

        }
    }

    getCurrentClient(fromComp?: any): ClientModel {
        this.callComp = fromComp;
        let shouldForceRefresh = addMinutes(this.lastUserRequestDate, 5) < new Date() || this.clientData == null;
        if(shouldForceRefresh){
          this.lastUserRequestDate = new Date();
          this.getUserData().subscribe(res => {
            this.setCurrentClient(res);
            if (this.callComp) {
              this.clientService.getProfileImageUsingGET().subscribe(profileImage=>{
                this.clientData.attachmentModel = profileImage;
                this.setCurrentClient(this.clientData);
                this.callComp.updateClientData(this.clientData);
              });
            }
          })
        }else{
          return this.clientData;
        }
    }

    getCurrentClientWithCallback(callback: (client:ClientModel) => void){
      let shouldForceRefresh = addMinutes(this.lastUserRequestDate, 5) < new Date() || this.clientData == null || this.clientData.instantBoniversumData == null ;
      if(shouldForceRefresh){
        this.lastUserRequestDate = new Date();
        this.getUserData().subscribe(res => {
          this.setCurrentClient(res);
          callback(res);
          this.clientService.getProfileImageUsingGET().subscribe(profileImage=>{
            this.clientData.attachmentModel = profileImage;
            this.setCurrentClient(this.clientData);
          });
        })
      }else{
        callback(this.clientData);
      }
    }

    forceClientRefresh(callback: (client:ClientModel) => void){
      this.getUserData().subscribe(res => {
        this.setCurrentClient(res);
        callback(res);
        this.clientService.getProfileImageUsingGET().subscribe(profileImage=>{
          this.clientData.attachmentModel = profileImage;
          this.setCurrentClient(this.clientData);
        });
      });
    }

    getClientData(): ClientModel {
      return this.cachedClientData;
    }

    getUserData(): Observable<ClientModel> {
      return this.clientService.getClientProfileUsingGET().pipe(map(response => {
        this.setCurrentClient(response);
        return response as ClientModel
      },error => {
        throw error;
      }));

    }

    //Observer

    getClientMessage(): Observable<any> {
      return this.storageSub.asObservable();
    }

    sendClientMessage(client: ClientModel) {
      this.storageSub.next(client);
    }

    updateClient(client: ClientModel){
    this.clientData= client;
    }

    clearClientMessage() {
       this.storageSub.next(null);
    }


    getBriefName() {
      if(this.clientData != null && this.clientData .firstName != null && this.clientData .lastName != null)
      {
        return "Bonitätsbrief_" + this.clientData .firstName + this.clientData .lastName + ".pdf";
      } else {
        return "Bonitätsbrief.pdf";
      }
    }

    getCertificateName() {
      if(this.clientData != null && this.clientData .firstName != null && this.clientData .lastName != null)
      {
        return "Bonitätszertifikat_" + this.clientData .firstName + this.clientData .lastName + ".pdf";
      } else {
        return "Bonitätszertifikat.pdf";
      }
    }


  computeUserAge() {
    let dateString = this.clientData.birthday;
    if(dateString===""|| dateString===undefined || dateString === null){
      return 0;
    }
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
      age--;
    }
    return age;
  }


  initClientEmails() {
    this.clientService.getAllClientEmailsUsingGET().subscribe(response =>{
      this.clientEmails = response;
    })
  }

  getClientEmails(): ClientEmail[] {
    return this.clientEmails;
  }

}


