import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ClientControllerService, OldAddressModel} from "../../shared/api-models";
import {UserDataService} from "../../shared/services/user-data.service";
import * as countriesJson from '../../../assets/countries.json'
import {StringHelper} from "../../shared/helpers/string-helper";


@Component({
  selector: 'upload-success.dialog',
  templateUrl: 'old-address.dialog.html',
  styleUrls: ['./old-address.dialog.scss'],
  animations: [
    trigger("openClose", [
      state('visible', style({
        opacity:1,
        visibility:'visible',
        margin:'0'
      })),
      state('invisible',style({
        opacity:0,
        visibility:'hidden',
        margin:'-41% 0 0 0'
      })),
      transition('visible => invisible',[
        animate('0.5s')
      ]),
      transition('invisible => visible',[
        animate('0.5s')
      ]),
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]),
    trigger("openCloseInfo", [
      state('visible', style({
        opacity:1,
        visibility:'visible',
        margin:'-1% 0 1% 0'
      })),
      state('invisible',style({
        opacity:0,
        visibility:'hidden',
        margin:'-5% 0 0 0'
      })),
      transition('visible => invisible',[
        animate('0.5s')
      ]),
      transition('invisible => visible',[
        animate('0.5s')
      ]),
    ])
  ]
})
export class OldAddressDialog implements OnInit {

  // modalModel: UploadSuccessModal;
  public popupText: string = null;
  addressFormData: any;
  checkboxData: any;
  countryArray: any =  countriesJson;
  infoClicked: boolean = false;

  exitHovered = false;

  constructor(private dialog: MatDialog,
              private router: Router,
              public dialogRef: MatDialogRef<OldAddressDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public translate: TranslateService,
              private clientService: ClientControllerService,
              private userService: UserDataService,
              private cd: ChangeDetectorRef,) {
    // this.modalModel = data["metadata"];
  }

  ngOnInit() {
    // @ts-ignore
    this.countryArray = countriesJson.default;
    this.addressFormData = new UntypedFormGroup({
      streetId: new UntypedFormControl('',Validators.required),
      streetNumberId: new UntypedFormControl('',Validators.required),
      postalCodeId: new UntypedFormControl('',Validators.required),
      cityId: new UntypedFormControl('',Validators.required),
      countryId: new UntypedFormControl('',Validators.required)
    })
    this.addressFormData.controls['countryId'].setValue('Deutschland', {onlySelf: true});
  }

  onCloseBtnClick(): void {
    this.dialogRef.close({});
  }

  goTo(to){
    if(to === 'myData'){
      this.router.navigateByUrl('/dashboard');
      this.dialogRef.close({});
    }else if(to === 'certificate'){
      this.router.navigateByUrl('/certificate-details');
      this.dialogRef.close({});
    }
  }

  sendButtonAction() {
    let oldAddressModel: OldAddressModel = {
      oldStreet: this.addressFormData.value.streetId,
      oldStreetNumber: this.addressFormData.value.streetNumberId,
      oldPostalCode: this.addressFormData.value.postalCodeId,
      oldCity: this.addressFormData.value.cityId,
      hasOldAddress: this.checkboxData === 'POSITIVE',
      oldCountry: this.addressFormData.value.countryId
    };
    if(this.checkboxData === 'POSITIVE'){
      let address = StringHelper.prepareAddressForGeocodeValidation(
        oldAddressModel.oldStreet,
        oldAddressModel.oldStreetNumber,
        oldAddressModel.oldPostalCode,
        oldAddressModel.oldCity,
        oldAddressModel.oldCountry);

        this.cd.detectChanges();
        this.sendDataToServer(oldAddressModel);
    }else{
     this.sendDataToServer(oldAddressModel);
    }
  }

  sendDataToServer(oldAddressModel) {
    this.clientService.saveOldAddressUsingPUT(oldAddressModel).subscribe(res => {
      this.userService.sendClientMessage(res);
      this.userService.updateClient(res);
      this.dialogRef.close({closeAction: "CLOSED_FROM_SEND"});
    });
  }
}
