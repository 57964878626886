/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CertificateModel } from '../model/certificateModel';
import { RequestDataModel } from '../model/requestDataModel';
import { ResponseEntity } from '../model/responseEntity';
import { TargetDataModel } from '../model/targetDataModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
  CertificateValidateModel,
  ClientModel,
  ClientCertificateStatuses,
  CertificateBucketValues, CertificateValidationStep2Model
} from '../model/models';
import {CertificateValidationResponse} from "../model/CertificateValidationResponseModel";
import {StatusByClientModel} from "../model/statusByClientModel";


@Injectable()
export class CertificateControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    public getCertificationTargetsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TargetDataModel>>;
    public getCertificationTargetsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetDataModel>>>;
    public getCertificationTargetsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetDataModel>>>;
    public getCertificationTargetsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<TargetDataModel>>(`${this.basePath}/digitalSelfrequest/targets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOnlyCertificateStatus
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */

    public getOnlyCertificateStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<StatusByClientModel>;
    public getOnlyCertificateStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatusByClientModel>>;
    public getOnlyCertificateStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatusByClientModel>>;
    public getOnlyCertificateStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<string>(`${this.basePath}/certificate/status-by-client`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


     /**
     * getReceipt
     *
     * @param communicationId communicationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReceiptUsingGET(communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getReceiptUsingGET(communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getReceiptUsingGET(communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getReceiptUsingGET(communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (communicationId === null || communicationId === undefined) {
            throw new Error('Required parameter communicationId was null or undefined when calling getReceiptUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get(`${this.basePath}/digitalSelfrequest/getReceipt/${encodeURIComponent(String(communicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


  public getReceiptNameUsingGET(communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public getReceiptNameUsingGET(communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public getReceiptNameUsingGET(communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public getReceiptNameUsingGET(communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get(`${this.basePath}/digitalSelfrequest/getReceiptName/${encodeURIComponent(String(communicationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        responseType: 'text',
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

     /**
     * requestCertificateData
     *
     * @param requestDataModel requestDataModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestCertificateDataUsingPOST(requestDataModel: RequestDataModel, observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
    public requestCertificateDataUsingPOST(requestDataModel: RequestDataModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
    public requestCertificateDataUsingPOST(requestDataModel: RequestDataModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
    public requestCertificateDataUsingPOST(requestDataModel: RequestDataModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (requestDataModel === null || requestDataModel === undefined) {
            throw new Error('Required parameter requestDataModel was null or undefined when calling requestCertificateDataUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<ClientModel>(`${this.basePath}/certificate/requestData`,
            requestDataModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * generateCertificate
   *
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public generateCertificateUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public generateCertificateUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public generateCertificateUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public generateCertificateUsingGET(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get(`${this.basePath}/certificate/generate-certificate-pdf`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob',
        reportProgress: reportProgress
      }
    );
  }


  /**
   * validateCertificate
   *
   * @param code code
   * @param lastName lastName
   * @param certificateType certificateType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validateCertificateUsingPOST(code: string, lastName?: string, certificateType?: string, observe?: 'body', reportProgress?: boolean): Observable<CertificateValidateModel>;
  public validateCertificateUsingPOST(code: string, lastName?: string, certificateType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateValidateModel>>;
  public validateCertificateUsingPOST(code: string, lastName?: string, certificateType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateValidateModel>>;
  public validateCertificateUsingPOST(code: string, lastName?: string, certificateType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling validateCertificateUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (lastName !== undefined) {
      queryParameters = queryParameters.set('lastName', <any>lastName);
    }
    if (certificateType !== undefined) {
      queryParameters = queryParameters.set('certificateType', <any>certificateType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<CertificateValidateModel>(`${this.basePath}/certificate/validateCertificate`,
      code,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * @param code code
   * @param lastName lastName
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validateBoniCertificateUsingGET(code: string, lastName?: string, observe?: 'body', reportProgress?: boolean): Observable<CertificateValidateModel>;
  public validateBoniCertificateUsingGET(code: string, lastName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateValidateModel>>;
  public validateBoniCertificateUsingGET(code: string, lastName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateValidateModel>>;
  public validateBoniCertificateUsingGET(code: string, lastName?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling validateBoniCertificateUsingGET.');
    }
    let headers = this.defaultHeaders;
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (code !== undefined) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (lastName !== undefined) {
      queryParameters = queryParameters.set('clientLastName', <any>lastName);
    }

    return this.httpClient.get(`${this.basePath}/certificate/code-validation`,
      {
        params: queryParameters,
        withCredentials: false,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );

  }


  /**
   * validateCertificateStep2
   *
   * @param certificateValidationStep2Model certificateValidationStep2Model
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validateCertificateStep2UsingPOST(certificateValidationStep2Model: CertificateValidationStep2Model, observe?: 'body', reportProgress?: boolean): Observable<CertificateValidationResponse>;
  public validateCertificateStep2UsingPOST(certificateValidationStep2Model: CertificateValidationStep2Model, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateValidationResponse>>;
  public validateCertificateStep2UsingPOST(certificateValidationStep2Model: CertificateValidationStep2Model, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateValidationResponse>>;
  public validateCertificateStep2UsingPOST(certificateValidationStep2Model: CertificateValidationStep2Model, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (certificateValidationStep2Model === null || certificateValidationStep2Model === undefined) {
      throw new Error('Required parameter certificateValidationStep2Model was null or undefined when calling validateCertificateStep2UsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<boolean>(`${this.basePath}/certificate/code-validation-step-two`,
      certificateValidationStep2Model,
      {
        withCredentials: false,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getClientCertificateBucketValues
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getClientCertificateBucketValuesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<CertificateBucketValues>;
  public getClientCertificateBucketValuesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateBucketValues>>;
  public getClientCertificateBucketValuesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateBucketValues>>;
  public getClientCertificateBucketValuesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<CertificateBucketValues>(`${this.basePath}/getBucketCertificateValues`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * saveHiddenCertificateValues
   *
   * @param hiddenTargets hiddenTargets
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveHiddenCertificateValuesUsingPUT(hiddenTargets: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public saveHiddenCertificateValuesUsingPUT(hiddenTargets: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public saveHiddenCertificateValuesUsingPUT(hiddenTargets: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public saveHiddenCertificateValuesUsingPUT(hiddenTargets: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (hiddenTargets === null || hiddenTargets === undefined) {
      throw new Error('Required parameter hiddenTargets was null or undefined when calling saveHiddenCertificateValuesUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.put<ResponseEntity>(`${this.basePath}/saveHiddenCertificate`,
      hiddenTargets,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
