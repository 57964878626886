/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { NewTargetModel } from '../model/newTargetModel';
import { PrerequisitesModel } from '../model/prerequisitesModel';
import { TargetDataModel } from '../model/targetDataModel';
import { TargetKPIModel } from '../model/targetKPIModel';
import { TargetModel } from '../model/targetModel';
import { TargetSuggestion } from '../model/targetSuggestion';
import { TargetSuggestionModel } from '../model/targetSuggestionModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {SearchModel} from "../model/searchModel";
import {PdfForm} from '../model/models';


@Injectable()
export class TargetControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addTargetSuggestion
     *
     * @param targetSuggestionModel targetSuggestionModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTargetSuggestionUsingPOST(targetSuggestionModel: TargetSuggestionModel, observe?: 'body', reportProgress?: boolean): Observable<TargetSuggestion>;
    public addTargetSuggestionUsingPOST(targetSuggestionModel: TargetSuggestionModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetSuggestion>>;
    public addTargetSuggestionUsingPOST(targetSuggestionModel: TargetSuggestionModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetSuggestion>>;
    public addTargetSuggestionUsingPOST(targetSuggestionModel: TargetSuggestionModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (targetSuggestionModel === null || targetSuggestionModel === undefined) {
            throw new Error('Required parameter targetSuggestionModel was null or undefined when calling addTargetSuggestionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<TargetSuggestion>(`${this.basePath}/target/suggestion`,
            targetSuggestionModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTargetWithPrerequisites
     *
     * @param newTargetModel newTargetModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTargetWithPrerequisitesUsingPOST(newTargetModel: NewTargetModel, observe?: 'body', reportProgress?: boolean): Observable<NewTargetModel>;
    public createTargetWithPrerequisitesUsingPOST(newTargetModel: NewTargetModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NewTargetModel>>;
    public createTargetWithPrerequisitesUsingPOST(newTargetModel: NewTargetModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NewTargetModel>>;
    public createTargetWithPrerequisitesUsingPOST(newTargetModel: NewTargetModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (newTargetModel === null || newTargetModel === undefined) {
            throw new Error('Required parameter newTargetModel was null or undefined when calling createTargetWithPrerequisitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<NewTargetModel>(`${this.basePath}/target`,
            newTargetModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * getAddressedCreditScoringTargets
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAddressedCreditScoringTargetsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TargetModel>>;
  public getAddressedCreditScoringTargetsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetModel>>>;
  public getAddressedCreditScoringTargetsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetModel>>>;
  public getAddressedCreditScoringTargetsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<Array<TargetModel>>(`${this.basePath}/addressedCreditScoringTargets`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

    /**
     * getAddressedTargets
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAddressedTargetsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TargetModel>>;
    public getAddressedTargetsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetModel>>>;
    public getAddressedTargetsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetModel>>>;
    public getAddressedTargetsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<TargetModel>>(`${this.basePath}/addressedTargets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getExistingTargetSuggestions
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExistingTargetSuggestionsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TargetModel>>;
    public getExistingTargetSuggestionsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetModel>>>;
    public getExistingTargetSuggestionsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetModel>>>;
    public getExistingTargetSuggestionsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.get<Array<TargetModel>>(`${this.basePath}/existingTargetSuggestions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * getFormPrerequisite
   *
   * @param targetId targetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFormPrerequisiteUsingGET(targetId: string, observe?: 'body', reportProgress?: boolean): Observable<PdfForm>;
  public getFormPrerequisiteUsingGET(targetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PdfForm>>;
  public getFormPrerequisiteUsingGET(targetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PdfForm>>;
  public getFormPrerequisiteUsingGET(targetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (targetId === null || targetId === undefined) {
      throw new Error('Required parameter targetId was null or undefined when calling getFormPrerequisiteUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    return this.httpClient.get<PdfForm>(`${this.basePath}/getFormPrerequisite/${encodeURIComponent(String(targetId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

    /**
     * getTargetKPI
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetKPIUsingGET(observe?: 'body', reportProgress?: boolean): Observable<TargetKPIModel>;
    public getTargetKPIUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetKPIModel>>;
    public getTargetKPIUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetKPIModel>>;
    public getTargetKPIUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<TargetKPIModel>(`${this.basePath}/target/kpi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTargetPrerequisites
     *
     * @param targetId targetId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetPrerequisitesUsingGET(targetId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PrerequisitesModel>>;
    public getTargetPrerequisitesUsingGET(targetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PrerequisitesModel>>>;
    public getTargetPrerequisitesUsingGET(targetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PrerequisitesModel>>>;
    public getTargetPrerequisitesUsingGET(targetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (targetId === null || targetId === undefined) {
            throw new Error('Required parameter targetId was null or undefined when calling getTargetPrerequisitesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<PrerequisitesModel>>(`${this.basePath}/prerequisitesByTarget/${encodeURIComponent(String(targetId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTargetSuggestions
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetSuggestionsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TargetSuggestion>>;
    public getTargetSuggestionsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetSuggestion>>>;
    public getTargetSuggestionsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetSuggestion>>>;
    public getTargetSuggestionsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.get<Array<TargetSuggestion>>(`${this.basePath}/target/suggestion`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTargetsBySubcategory
     *
     * @param subcategory subcategory
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetsBySubcategoryUsingGET(subcategory: string, section: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TargetDataModel>>;
    public getTargetsBySubcategoryUsingGET(subcategory: string, section: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetDataModel>>>;
    public getTargetsBySubcategoryUsingGET(subcategory: string, section: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetDataModel>>>;
    public getTargetsBySubcategoryUsingGET(subcategory: string, section: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategory === null || subcategory === undefined) {
            throw new Error('Required parameter subcategory was null or undefined when calling getTargetsBySubcategoryUsingGET.');
        }

      if (section === null || section === undefined) {
        throw new Error('Required parameter section was null or undefined when calling getTargetsBySubcategoryUsingGET.');
      }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<TargetDataModel>>(`${this.basePath}/target/${encodeURIComponent(String(subcategory))}/${encodeURIComponent(String(section))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTargets
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TargetModel>>;
    public getTargetsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetModel>>>;
    public getTargetsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetModel>>>;
    public getTargetsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<TargetModel>>(`${this.basePath}/target`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTargetsWithStatus
     *
     * @param targetStatus targetStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetsWithStatusUsingGET(targetStatus: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TargetModel>>;
    public getTargetsWithStatusUsingGET(targetStatus: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetModel>>>;
    public getTargetsWithStatusUsingGET(targetStatus: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetModel>>>;
    public getTargetsWithStatusUsingGET(targetStatus: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (targetStatus === null || targetStatus === undefined) {
            throw new Error('Required parameter targetStatus was null or undefined when calling getTargetsWithStatusUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.get<Array<TargetModel>>(`${this.basePath}/target/${encodeURIComponent(String(targetStatus))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * getTargetsBySearch
   *
   * @param searchModel searchModel
   * @param subcategory subcategory
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTargetsBySearchUsingPOST(searchModel: SearchModel, subcategory?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TargetDataModel>>;
  public getTargetsBySearchUsingPOST(searchModel: SearchModel, subcategory?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetDataModel>>>;
  public getTargetsBySearchUsingPOST(searchModel: SearchModel, subcategory?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetDataModel>>>;
  public getTargetsBySearchUsingPOST(searchModel: SearchModel, subcategory?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (searchModel === null || searchModel === undefined) {
      throw new Error('Required parameter searchModel was null or undefined when calling getTargetsBySearchUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (subcategory !== undefined) {
      queryParameters = queryParameters.set('subcategory', <any>subcategory);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    return this.httpClient.post<Array<TargetDataModel>>(`${this.basePath}/target/search`,
      searchModel,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
