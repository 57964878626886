import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AuthGuard, BrowserOutdatedGuard} from "./shared/services/auth-guard.service";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ValidationHelper } from './shared/helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import {
  ClientControllerService,
  CommunicationControllerService,
  AdminNotificationControllerService,
  BASE_PATH,
  TargetControllerService,
  UserTargetControllerService, CertificateControllerService, DigitalSelfRequestControllerService,ExternalPartnerControllerService,SharedControllerService, ExternalCredentialControllerService, IdentificationControllerService
} from './shared/api-models';
import { AuthService } from "./shared/services/auth.service";
import { HttpClient2 } from "./shared/services/http-client.service";
import { ErrorHandler } from '@angular/core';
import { CustomErrorHandler } from "./shared/services/custom-error-handler";
import { UserDataService } from './shared/services/user-data.service';
import {DownloadService} from './shared/services/download.service';
import {environment} from "../environments/environment";
import {BASE_AUTH_PATH} from './shared/api-models';
import {Angulartics2Module} from 'angulartics2';
import { MatomoModule } from 'ngx-matomo';
// import {Angulartics2Piwik} from 'angulartics2/piwik';
import {NgChartsModule} from 'ng2-charts';
import { TrackingService } from './shared/services/tracking.service';
import { LoadingSpinnerService } from './shared/components/spinner/spinner.service';
import { DatePipe } from '@angular/common';
import {PersistenceService} from "./shared/services/persistence-service";
import {CertificatesEventsService} from "./shared/services/certificates-events.service";
import {JwtModule} from "@auth0/angular-jwt";
import {MatDialogModule} from "@angular/material/dialog";
import {MatExpansionModule} from "@angular/material/expansion";
import {AppRoutingModule} from "./app-routing.module";
import {ViewLetterService} from "./shared/services/view-letter.service";
import {WUCControllerService} from "./shared/api-models/api/WUCController.service";
import {PresenceService} from "./shared/services/presence.service";
import {httpInterceptorProviders} from "./http-interceptors";




export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n_translations/', '.json');
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('de');
    return translate.use('de').toPromise();
  };
}

// export function authHttpService(http: Http, options: RequestOptions) {
//     return new AuthHttp(new AuthConfig({
//         tokenGetter: (() => localStorage.getItem('access_token')),
//         globalHeaders: [{'Content-Type': 'application/json'}],
//     }), http, options);
// }

// token getter for jwt module
export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
      ],
  imports: [
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    BrowserModule,
    FormsModule,
    MatDialogModule,
    NgChartsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatomoModule.forRoot({
      scriptUrl: '//cdn.matomo.cloud/meinboniversum.matomo.cloud/matomo.js',
      trackers: [
        {
          trackerUrl: 'https://meinboniversum.matomo.cloud/matomo.php',
          siteId: 1
        }
      ],
      routeTracking: {
        enable: true
      }
    }),
    HttpClientModule,
    // todo to add whitelist and blacklist domains to config in jwt
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:4200','imd.zynksoftware.com','portal.itsmydata.de'],
        disallowedRoutes: ['']
      }
    }),
    TranslateModule.forRoot({
      useDefaultLang:true,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
        AuthGuard,
        BrowserOutdatedGuard,
        TrackingService,
        TargetControllerService,
        LoadingSpinnerService,
        DatePipe,
        UserTargetControllerService,
        ExternalPartnerControllerService,
        SharedControllerService,
        WUCControllerService,
        IdentificationControllerService,
        CustomErrorHandler,
        // Angulartics2Piwik,
        ValidationHelper,
        ClientControllerService,
        PersistenceService,
        CommunicationControllerService,
        AdminNotificationControllerService,
        DownloadService,
        AuthService,HttpClient2,
        CertificateControllerService,
        CertificatesEventsService,
        DigitalSelfRequestControllerService,
        ExternalCredentialControllerService,
        UserDataService,
        ViewLetterService,
        PresenceService,
        httpInterceptorProviders,
        // {
        //         provide: AuthHttp,
        //         useFactory: authHttpService,
        //         deps: [Http, RequestOptions]
        // },
        [
               { provide: BASE_PATH, useValue: environment.apiUrl }, // - server url,
               { provide: BASE_AUTH_PATH, useValue: environment.authUrl } // - server url

        ],
          {provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }

    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
