import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';
import {PresenceService} from "../shared/services/presence.service";

/** Pass untouched request through to the next request handler and update the last action timestamp. */
@Injectable()
export class PresenceInterceptor implements HttpInterceptor {

  constructor(private presenceService: PresenceService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req?.url?.includes('/notification/unacknowledged')) {
      this.presenceService.resetLastActionTimestamp();
    }
    return next.handle(req);
  }
}
