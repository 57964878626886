/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PrerequisitesModel {
  placeholder: Boolean;
  description?: string;
  field?: string;
  id?: string;
  incorrectValue?: string;
  index?: number;
  isIncorrect?: boolean;
  isOptional?: boolean;
  prerequisiteType?: PrerequisitesModel.PrerequisiteTypeEnum;
  targetId?: string;
  type?: string;
}
export namespace PrerequisitesModel {
  export type PrerequisiteTypeEnum = 'BASIC_REQUIRED' | 'TARGET_REQUIRED';
  export const PrerequisiteTypeEnum = {
    BASICREQUIRED: 'BASIC_REQUIRED' as PrerequisiteTypeEnum,
    TARGETREQUIRED: 'TARGET_REQUIRED' as PrerequisiteTypeEnum
  }
}
