/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


 export interface DigitalSelfrequest {
    clientId?: string;
    id?: string;
    lastCommunicationId?: string;
    lastPaymentDate?: Date;
    lastProductOrderId?: string;
    pricePaid?: string;
    remainingValidDays?:number;
    status?: DigitalSelfrequest.StatusEnum;
    certificateName?: string;
    requestDataCounter?: number;
}
export namespace DigitalSelfrequest {
    export type StatusEnum = 'NONE' | 'PENDING' | 'PAID' | 'UPDATED' | 'READY_FOR_UPDATE' |  'EXPIRED' | 'REFRESH_OLD_S';
    export const StatusEnum = {
        NONE: 'NONE' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        PAID: 'PAID' as StatusEnum,
        UPDATED: 'UPDATED' as StatusEnum,
        READYFORUPDATE: 'READY_FOR_UPDATE' as StatusEnum,
        REFRESHOLDS: 'REFRESH_OLD_S' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum
    }
}
export namespace DigitalSelfrequest {
  export type DigitalSelfrequestTypeEnum = 'SOFORT_S' | 'SOFORT_M_L';
  export const DigitalSelfrequestTypeEnum = {
    SOFORT_S: 'SOFORT_S' as DigitalSelfrequestTypeEnum,
    SOFORT_M_L: 'SOFORT_M_L' as DigitalSelfrequestTypeEnum
  }
}
