import { Injectable } from '@angular/core';

@Injectable()
export  class PersistenceService {

  public setCurrentLanguage (language){
    if (language && language != 'undefined'){
      localStorage.setItem('current_language', language);}
    else{
      localStorage.setItem('current_language', 'de');
    }
  }

  public  getCurrentLanguage():string{
    this.resetLanguageToDefault();
    return localStorage.getItem('current_language');
  }

  public  resetLanguageToDefault(){
    if (!localStorage.getItem('current_language') || localStorage.getItem('current_language') == 'undefined' ) {
      localStorage.setItem('current_language', 'de');
    }
  }

  ///

  resetLocalStorage(){
    localStorage.removeItem('token');
    localStorage.removeItem('pre_access_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('has_two_factor');
    localStorage.removeItem('access_token_expiration_date');

    this.resetLanguageToDefault();
  }

}
