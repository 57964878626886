/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminNotificationModel } from '../model/adminNotificationModel';
import { ResponseEntity } from '../model/responseEntity';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminNotificationControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * correctOCRData
     *
     * @param code code
     * @param json json
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public correctOCRDataUsingPOST(code: string, json: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public correctOCRDataUsingPOST(code: string, json: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public correctOCRDataUsingPOST(code: string, json: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public correctOCRDataUsingPOST(code: string, json: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling correctOCRDataUsingPOST.');
        }
        if (json === null || json === undefined) {
            throw new Error('Required parameter json was null or undefined when calling correctOCRDataUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post(`${this.basePath}/adminNotification/correctOCRData/${encodeURIComponent(String(code))}`,
            json,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getNotification
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotificationUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<AdminNotificationModel>;
    public getNotificationUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminNotificationModel>>;
    public getNotificationUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminNotificationModel>>;
    public getNotificationUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getNotificationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<AdminNotificationModel>(`${this.basePath}/adminNotification/getNotification/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
  /**
   * getTechAdminNotification
   *
   * @param code code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTechAdminNotificationUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<AdminNotificationModel>;
  public getTechAdminNotificationUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminNotificationModel>>;
  public getTechAdminNotificationUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminNotificationModel>>;
  public getTechAdminNotificationUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling getTechAdminNotificationUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<AdminNotificationModel>(`${this.basePath}/adminNotification/getTechAdminNotification/${encodeURIComponent(String(code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * reparseData
   *
   * @param code code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public reparseDataUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public reparseDataUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public reparseDataUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public reparseDataUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling reparseDataUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<ResponseEntity>(`${this.basePath}/adminNotification/reparseData/${encodeURIComponent(String(code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

    /**
   * replaceAndParseAttachments
   *
   * @param code code
   * @param files files
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public replaceAndParseAttachmentsUsingPOST(code: string, files: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public replaceAndParseAttachmentsUsingPOST(code: string, files: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public replaceAndParseAttachmentsUsingPOST(code: string, files: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public replaceAndParseAttachmentsUsingPOST(code: string, files: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling reuploadAttachmentsUsingPOST.');
    }
    if (files === null || files === undefined) {
      throw new Error('Required parameter files was null or undefined when calling reuploadAttachmentsUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'multipart/form-data'
    ];

    let formParams: { append(param: string, value: any): void; };
    let useForm = true;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    if (files) {
      files.forEach((element) => {
        // @ts-ignore
        formParams = formParams.append('files', <any>element) || formParams;
      })
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<ResponseEntity>(`${this.basePath}/adminNotification/replaceAndParseAttachments/${encodeURIComponent(String(code))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * sendTextToParsingRules
   *
   * @param code code
   * @param parsingText parsingText
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendTextToParsingRulesPOST(code: string, parsingText: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public sendTextToParsingRulesPOST(code: string, parsingText: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public sendTextToParsingRulesPOST(code: string, parsingText: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public sendTextToParsingRulesPOST(code: string, parsingText: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling sendTextToParsingRulesPOST.');
    }
    if (parsingText === null || parsingText === undefined) {
      throw new Error('Required parameter parsingText was null or undefined when calling sendTextToParsingRulesPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post(`${this.basePath}/adminNotification/sendTextToParsingRules/${encodeURIComponent(String(code))}`,
      parsingText,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        responseType: 'text',
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
     * sendErrorToClient
     *
     * @param code code
     * @param parsingErrorType parsingErrorType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendErrorToClientUsingGET(code: string, parsingErrorType: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public sendErrorToClientUsingGET(code: string, parsingErrorType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public sendErrorToClientUsingGET(code: string, parsingErrorType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public sendErrorToClientUsingGET(code: string, parsingErrorType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling sendErrorToClientUsingGET.');
        }
        if (parsingErrorType === null || parsingErrorType === undefined) {
            throw new Error('Required parameter parsingErrorType was null or undefined when calling sendErrorToClientUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/adminNotification/sendErrorToClient/${encodeURIComponent(String(code))}/${encodeURIComponent(String(parsingErrorType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * sendParsingDelayEmailToClient
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
      public sendParsingDelayEmailToClientUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
      public sendParsingDelayEmailToClientUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
      public sendParsingDelayEmailToClientUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
      public sendParsingDelayEmailToClientUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
          if (code === null || code === undefined) {
              throw new Error('Required parameter code was null or undefined when calling sendParsingDelayEmailToClientUsingGET.');
          }

          let headers = this.defaultHeaders;

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              '*/*'
          ];
          let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set("Accept", httpHeaderAcceptSelected);
          }
          let basicAuthHeader = localStorage.getItem('access_token');
          headers = headers.set("Authorization", "bearer " + basicAuthHeader);
          return this.httpClient.get<ResponseEntity>(`${this.basePath}/adminNotification/sendParsingDelayEmailToClient/${encodeURIComponent(String(code))}`,
              {
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

    /**
     * validateOCR
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateOCRUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public validateOCRUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public validateOCRUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public validateOCRUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling validateOCRUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/adminNotification/validateOCR/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
