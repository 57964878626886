import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';


import {Observable} from 'rxjs';


import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {LetterModel} from "../model/letterModel";
import {CustomHttpUrlEncodingCodec} from "../encoder";

@Injectable()
export class SharedControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public previewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe?: 'body', reportProgress?: boolean): Observable<LetterModel>;
  public previewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LetterModel>>;
  public previewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LetterModel>>;
  public previewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (firstName === null || firstName === undefined || lastName === null || lastName === undefined || receiverEmail === null || receiverEmail === undefined) {
      throw new Error('Required parameter (firstName, lastName, receiverEmail) was null or undefined when calling previewLetterCertificateUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (emailId !== undefined) {
      queryParameters = queryParameters.set('emailId', <any>emailId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    let receiverData = {
      firstName: firstName,
      lastName: lastName,
      email: receiverEmail
    }

    return this.httpClient.post<LetterModel>(`${this.basePath}/shared/certificatePreview`,
      receiverData,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public sendPreviewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public sendPreviewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public sendPreviewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public sendPreviewLetterCertificateUsingPOST(firstName, lastName, receiverEmail, emailId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (firstName === null || firstName === undefined || lastName === null || lastName === undefined || receiverEmail === null || receiverEmail === undefined) {
      throw new Error('Required parameter (firstName, lastName, receiverEmail) was null or undefined when calling previewLetterCertificateUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (emailId !== undefined) {
      queryParameters = queryParameters.set('emailId', <any>emailId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    let receiverData = {
      firstName: firstName,
      lastName: lastName,
      email: receiverEmail
    }

    return this.httpClient.post<LetterModel>(`${this.basePath}/shared/sendCertificatePreview`,
      receiverData,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
