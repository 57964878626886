<div class="dialog-container">
    <div *ngIf="(this.currentIndex == 1 && !shouldDisableCloseButtonInStep1) || this.currentIndex != 1" class="close-dialog-button" (click)="onNoClick()">
        <img (mouseenter)="exitHovered = true;"
             (mouseleave)="exitHovered = false;"
             alt="close-button"
             style="width: 27px; height: 27px;"
             [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
    </div>
  <mat-horizontal-stepper [selectedIndex]="currentIndex" (selectionChange)="selectionChange($event)" [@.disabled]="true" [linear]="false" #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      <span class="stepper-icon fas fa-check"></span>
    </ng-template>
    <ng-template matStepperIcon="done" let-index="index">
      <span class="stepper-icon fas fa-check"></span>
    </ng-template>
    <mat-step [completed]="startingIndex > 0">
      <ng-template matStepLabel>Basic Data</ng-template>
      <div class="dialog-content">
        <dynamic-form (emitAction)="dynamicFormAction($event)" [step3Disabled]="shouldStep3BeDisabled"
                      [currentStepperIndex]="0" [isFromCertificate]="isFromCertificate" [prerequisites]="prerequisitesArrayBasic" [target]=target [dialogRef]="dialogRef"></dynamic-form>
      </div>
    </mat-step>

    <mat-step [completed]="startingIndex > 1 || shouldStep2BeDisabled">
      <ng-template matStepLabel>Phone confirm</ng-template>
        <div class="dialog-content" style="width: 100%; padding: 0 !important;">
          <phone-confirm
            (sendPhoneEvent)="sendPhoneEvent($event)"
            (closeButtonDisableEvent)="disableCloseButton($event)"
            *ngIf="userPhoneNumber != ''"
            [sendSmsEvent]="sendSmsEventSubject.asObservable()"
            [phoneNumber]="this.userPhoneNumber"></phone-confirm >
        </div>
    </mat-step>

    <mat-step [completed]="startingIndex > 2 || shouldStep3BeDisabled">
      <ng-template matStepLabel>Target data</ng-template>
      <div class="dialog-content">
        <dynamic-form (emitAction)="dynamicFormAction($event)" [currentStepperIndex]="2" [isFromCertificate]="isFromCertificate" [prerequisites]="prerequisitesArrayTargetRelated"  [target]=target [dialogRef]="dialogRef"></dynamic-form>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>


