<div class="dialog-container">
  <div class="close-dialog-button" *ngIf="this.modalModel && this.modalModel.editAction" (click)="onCloseBtnClick()">
    <img (mouseenter)="exitHovered = true;"
         (mouseleave)="exitHovered = false;"
         alt="close-button"
         style="width: 27px; height: 27px;"
         [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
  </div>
  <div class="letter-header" [ngClass]="letterSubject == 'PREVIEW'?'letter-header-background':''">
    <div class="header-content" ngClass="">
      <div *ngIf="letterSubject !== 'PREVIEW'">
        <div class="letter-subject copy-text-primary">{{letterSubject}}</div>
        <div class="letter-date copy-text-primary">{{letterDate}}</div>
      </div>
      <div *ngIf="letterSubject === 'PREVIEW'" class="copy-text-primary">
        {{translate.instant('LETTER_DIALOG.REQUEST_DATA_PREVIEW')}}
        <span class="letter-header-link copy-text-primary hypertext" (click)="goToProfile()">{{translate.instant('LETTER_DIALOG.REQUEST_DATA_PREVIEW_CLICKABLE')}}</span>
        <span>{{translate.instant('LETTER_DIALOG.REQUEST_DATA_PREVIEW_2')}}</span>
      </div>
    </div>

  </div>

  <div class="letter-content" [ngClass]="(this.modalModel && this.modalModel.editAction) ? 'letter-content-edit' : ''">
    <hr>
    <div class="letter-body">
      <div class="letter-attachments" *ngIf="modalModel.attachments!=null && modalModel.attachments.length>0">
        <ul class="attachment-list">
          <li *ngFor="let file of modalModel.attachments; let i = index" [attr.data-index]="i">
            <button (click)="onlyMediaFile(file.type) ? onAttachmentPreview(file) : onAttachmentDownload(file)" class="attachment-btn imd-button-orange">
              <p class="attachment-text"> {{ (file.name.length>20)? (file.name | slice:0:20)+'..':(file.name) }}
                <img *ngIf="!onlyMediaFile(file.type)"
                     alt="preview-file"
                src="../../../assets/images/file_download_orange.svg">
                <span *ngIf="onlyMediaFile(file.type)" class="open-button-span">{{translate.instant('LETTER_DIALOG.PREVIEW_FILE_BUTTON')}}</span>
              </p>
            </button>
          </li>
        </ul>
        <hr>
      </div>
      <div class="email-details-message" #dynamicElement id="viewEmailBody"></div>

    </div>
  </div>
  <div class="letter-footer">
    <hr class="my-hr">
    <button button type="submit" [ngClass]="modalModel.allowDeletion? 'right-button imd-button-secondary':'imd-button-secondary'" (click)="onButtonAction($event)">
      {{customActionLabel}}
    </button>
    <button *ngIf="this.modalModel.sendAction" button type="submit" class="imd-button-secondary"
            (click)="onSendAction($event)">{{sendLabel}}
    </button>
    <button *ngIf="isShowDataVisible()" button type="submit" class="imd-button-secondary"
            (click)="onDisplayInMyData($event)">{{showDataLabel}}
    </button>
    <button *ngIf="modalModel.allowDeletion && isDataLoading==false"
            button type="submit"
            class="left-button imd-button-secondary"
            (click)="onDeleteAction()">
      {{translate.instant('COMMUNICATIONS.BUTTONS.DELETE')}}
    </button>
    <div class="left-button" *ngIf="isDataLoading"><img alt="loading-icon" src="assets/images/loading_small.svg" width="40" height="40"/></div>

    <button *ngIf="showCorrectDataButton()" button type="submit" class="imd-button-secondary"
            (click)="onCorrectDataAction($event)">{{showCorrectDataLabel}}
    </button>
    <button *ngIf="showRenewDataButton()" button type="submit" class="imd-button-secondary"
            (click)="onRenewDataAction($event)">{{renewDataRequestLabel}}
    </button>
    <button *ngIf="showSendReplyLetterButton()" button type="submit" class="imd-button-secondary"
            (click)="onSendReplyLetterAction($event)">{{sendReplyLetterLabel}}
    </button>
    <button *ngIf="showDecryptDataButton()" button type="submit" class="imd-button-secondary"
            (click)="onDecryptDataAction()">{{showDecryptDataLabel}}
    </button>
  </div>

</div>


