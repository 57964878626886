import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { DigitalSelfRequestControllerService} from "../api-models";


@Injectable()
export class CertificatesEventsService {
  constructor(private digitalSelfRequestService: DigitalSelfRequestControllerService) {
  }
  // Observable sources
  private _subject = new Subject<any>();

  // Service message commands
  refreshCertificateStatus(event) {
    this.digitalSelfRequestService.getClientCertificateStatusesUsingGET().subscribe(response => {
      this._subject.next(response);
    });

  }

  // Observable streams
  getEvent$ = this._subject.asObservable();
}
