import {BucketValueModel} from "../api-models";

export class StringHelper {

  static string2Bin(str) {
    var result = [];
    for (var i = 0; i < str.length; i++) {
      result.push(str.charCodeAt(i));
    }
    return result;
  }

  static prepareAddressForGeocodeValidation(street, streetNumber, postalCode, city, country){

    if(street == null){
      street = "";
    }

    if(streetNumber == null){
      streetNumber = "";
    }

    if(postalCode == null){
      postalCode = "";
    }

    if(city == null){
      city = "";
    }

    if(country == null){
      country = "";
    }

    return street + " " + streetNumber + " " + postalCode + " " + city + " " + country;
  }

  public static getFileExtensionFromName(fileName: string): string{
    return fileName.split('.')[fileName.split('.').length - 1];
  }

  public static getFilePreviewType(fileType){
    const permittedFileTypes = ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf', 'image/vnd.wap.wbmp',
    'image/bmp', 'image/x-windows-bmp', 'image/jpg'];
    return permittedFileTypes.some(type => type === fileType);
  }

  static jaroWinklerDistance(s1, s2, options) {
    var m = 0;
    var defaults = { caseSensitive: false };
    var settings = StringHelper.extend(defaults, options);
    var i;
    var j;

    // Exit early if either are empty.
    if (s1.length === 0 || s2.length === 0) {
      return 0;
    }

    // Convert to upper if case-sensitive is false.
    if (!settings.caseSensitive) {
      s1 = s1.toUpperCase();
      s2 = s2.toUpperCase();
    }

    // Exit early if they're an exact match.
    if (s1 === s2) {
      return 1;
    }

    var range = (Math.floor(Math.max(s1.length, s2.length) / 2)) - 1;
    var s1Matches = new Array(s1.length);
    var s2Matches = new Array(s2.length);

    for (i = 0; i < s1.length; i++) {
      var low  = (i >= range) ? i - range : 0;
      var high = (i + range <= (s2.length - 1)) ? (i + range) : (s2.length - 1);

      for (j = low; j <= high; j++) {
        if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
          ++m;
          s1Matches[i] = s2Matches[j] = true;
          break;
        }
      }
    }

    // Exit early if no matches were found.
    if (m === 0) {
      return 0;
    }

    // Count the transpositions.
    var k = 0;
    var numTrans = 0;

    for (i = 0; i < s1.length; i++) {
      if (s1Matches[i] === true) {
        for (j = k; j < s2.length; j++) {
          if (s2Matches[j] === true) {
            k = j + 1;
            break;
          }
        }

        if (s1[i] !== s2[j]) {
          ++numTrans;
        }
      }
    }

    var weight = (m / s1.length + m / s2.length + (m - (numTrans / 2)) / m) / 3;
    var l = 0;
    var p = 0.1;

    if (weight > 0.7) {
      while (s1[l] === s2[l] && l < 4) {
        ++l;
      }

      weight = weight + l * p * (1 - weight);
    }

    return weight;
  }


  static extend(a, b) {
    for (var property in b) {
      if (b.hasOwnProperty(property)) {
        a[property] = b[property];
      }
    }

    return a;
  }

  static formatBucketValue(value,type):string{
    if (type == BucketValueModel.TypeEnum.ADDRESS || type == BucketValueModel.TypeEnum.CURRENTADDRESS || type == BucketValueModel.TypeEnum.LOCATIONS || type == BucketValueModel.TypeEnum.LOCATION || type == BucketValueModel.TypeEnum.PLACESLIVED){
      const regex  =  /(\d+),\s(.+?),\s(.+?),?\s(\d+),?/;
      let match = value.match(regex);
      if (match){
        let city = match[2];
        let street = match[3];
        let zipcode =match[1];
        let number = match[4];
        return street+" "+number+", "+zipcode+" "+city;
      }
      else {
        return value;
      }
    }else{
      return value;
    }

  }

  static editPrice(currentPrice) {
    let length = currentPrice.length;
    let editedPrice = "";
    if(length == 1 ) {
      editedPrice = "0," + "0" + currentPrice;
    } else if(length == 2) {
      editedPrice = "0," + currentPrice;
    } else {
      editedPrice = currentPrice.substr(0,length-2) + ',' + currentPrice.substr(-2);
    }
    return editedPrice;
  }
}
