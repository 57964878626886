import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';


import { Observable }                                        from 'rxjs';


import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {BridgeStatusModel} from "../model/bridgeStatusModel";


@Injectable()
export class ExternalPartnerControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public registerUserToIMD(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public registerUserToIMD(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public registerUserToIMD(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public registerUserToIMD(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/partner/imd/send-register-request`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }

    );
  }

  public generateCertificateOnIMD(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public generateCertificateOnIMD(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public generateCertificateOnIMD(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public generateCertificateOnIMD(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/partner/imd/request-imd-certificate`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }

    );
  }



  public generateCreditScoringOnIMD(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public generateCreditScoringOnIMD(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public generateCreditScoringOnIMD(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public generateCreditScoringOnIMD(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/partner/imd/request-imd-credit-scoring`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }

    );
  }

  public getUserIfIsLinked(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getUserIfIsLinked(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getUserIfIsLinked(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getUserIfIsLinked(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/partner/imd/check-for-registered-user`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getBridgeStatus(observe?: 'body', reportProgress?: boolean): Observable<BridgeStatusModel>;
  public getBridgeStatus(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BridgeStatusModel>>;
  public getBridgeStatus(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BridgeStatusModel>>;
  public getBridgeStatus(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<string>(`${this.basePath}/partner/imd/bridge-status`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
