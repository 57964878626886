import { AttachmentModel } from '../api-models/model/attachmentModel';
import { TargetDataModel, CommunicationModel, LetterModel, UserTargetModel } from "../api-models";
import {DBDataModel} from "./DBDataModel";
import {WebActivityReportType} from "../api-models/model/webActivityReportType";

export class Modal {
    closeAction: boolean = true;
    title: string = null;
    extendedTitle: string = null;
    content: string = null;
    input: string = null;
    action: string = null;
    attachments: Array<AttachmentModel> ;
    footerAction: string = '';
    type: string = '';
    shouldAutoClose?:boolean = null;
    clickableContent?: string;
    urlLink?: string;

}

export class Input {
  name: string;
  type: string; // bind this in HTML when this issue will be fixed https://github.com/angular/angular/issues/13243
  top: number;
  left: number;
  width: number;
  height: number;
  value; any;
}


export class CanvasModal{
    title: string = null;
    content: string = null;
    action: string = null;
    close: string = null;
    file: File = null;
}

export class GdprModal {
  type: string = null;
  downloadContentArray: string[] = [];
  uploadContentArray: string[] = [];
  downloadButton: string = null;
  uploadButton: string = null;
}

export class ConfirmPhoneModal {

  closeAction: boolean = true;
  title: string = null;
  extendedTitle: string = '';
  content: string = null;
  input: string = null;
  action: string = null;
  phoneNumber: string = null;
  hasLimitError: boolean =  false;
}

export class LetterModal {
    date:string;
    subject:string;
    htmlContent:string;
    actionType: CommunicationModel.ActionEnum;
    cancelAction: boolean;
    sendAction: boolean;
    editAction: boolean;
    attachments?: Array<AttachmentModel>;
    // communication: any;
    errorType?: TargetDataModel.ErrorTypeEnum;
    isDataProcessed: boolean;
    actionSubType?: CommunicationModel.ActionSubTypeEnum;
    targetName?: string;
    allowDeletion: boolean;
    previewLetterType?:LetterModel.PreviewLetterType;
    targetId?: string;
    communicationId?:string;
    targetStatus?:UserTargetModel.StatusEnum;

}

export class UploadDataModal {
  communicationId: string = null;
  closeAction: boolean = true;
  linkedInAction: boolean = true;
  reuploadAction:boolean=true;
  errorType: string = null;
  letterUploadAction: boolean = true;
  title: string = null;
  extendedTitle: string = null;
  content: string = null;
  targetId: string = null;
  targetName: string = null;
  action: string = null;
  attachments: Array<AttachmentModel> = null;
  footerAction: string = null;
  fileType: any = [];
  fileDimension: any;
  errorFileInvalid: string = null;
  errorFileLimit: string = null;
  totalDimension: any = null;
  fileNumberLimit: any = null;
  totalDimensionError: string = null;
  letterResponseType: string = null;
  techAdminReupload?: boolean = null;
  code?: string = null;
}

export class UploadDataModalCommunication {
  closeAction: boolean = true;
  reuploadAction: boolean =true;
  errorType: string = null;
  linkedInAction: boolean = true;
  letterUploadAction: boolean = true;
  title: string = null;
  extendedTitle: string = null;
  content: string = null;
  targetId: string = null;
  targetName: string = null;
  communicationId: string = null;
  action: string = null;
  attachments: Array<AttachmentModel> = null;
  footerAction: string = null;
  fileType: any = [];
  fileDimension: any;
  errorFileInvalid: string = null;
  errorFileLimit: string = null;
  totalDimension: any = null;
  fileNumberLimit: any = null;
  totalDimensionError: string = null;
  letterResponseType: string = null;
  techAdminReupload?: boolean = null;
  code?: string = null;
}

export class VideoModel{
  videoUrl: string = null;
  closeBtn: string = null;
}

export class UploadIdentityDocumentModal{
  closeAction: boolean = true;
  identityDocumentName: string = null;
  title: string = null;
  dragZoneLeft:string=null;
  dragZoneRight:string=null;
  fileDimension:any;
  attachments: Array<AttachmentModel> = null;
  fileType: any = [];
  targetId: string=null;
  errorFileInvalid: string = null;
  errorFileLimit: string = null;
  totalDimension: any = null;
  totalDimensionError: string = null;
  fileNumberLimit: any = null;
  action: string = null;

}
export class ZipUploadModal {
  closeAction: boolean = true;
  linkedInAction: boolean = true;
  letterUploadAction: boolean = true;
  title: string = null;
  extendedTitle: string = null;
  content: string = null;
  targetId: string = null;
  targetName: string = null;
  action: string = null;
  attachments: Array<AttachmentModel> = null;
  footerAction: string = null;
  fileType: any = [];
  fileDimension: any;
  errorFileInvalid: string = null;
  errorFileLimit: string = null;
  fileNumberLimit: any = null;
  totalDimension: any = null;
  totalDimensionError: string = null;
  whiteListFiles: any = [];
}
export class SecurityLevelModal {
  closeAction: boolean = true;
  phoneConfirmed: boolean = false;
  title: string = null;
  securityLevel: string = null;
  action: string = null;
  gender: any = null;

}
export class SaveSuccessModal {
  title: string = null;
  action: string = null;
}

export class DeleteCommunicationModal {
  title: string = null;
  confirmation: string = null;
  cancelation: string = null;
}

export class TopWebsitesModal {
  title: string = null;
  category: string = null;
  type: WebActivityReportType = null;
}

export class SendSuccessModal {
  title: string = null;
  emails: string[] = [];
  action: string = null;
}

export class AddressTargetSuccessModal {
  title1: string = null;
  title2: string = null;
  body: string = null;
  action: string = null;
  copyButton?: string = null;
  copyText?: string = null;
  shouldCloseAutomatically?: boolean = false;
}

export class WelcomeModal {
  firstLogin:boolean = false;
}

export class Level3DialogModel {
  subcategoryType: any
  bucketType:any
  navigationID:any
  tableValue: any
}
export class DownloadModel {
  closeAction:string=null;
  title: string=null;
  button: string=null;
}

export class ForwardEmailToImdModal {
  targetName: string = null;
  imdEmail: string = null;
}

export class NewGeneralModal {
  targetName: string = null;
  imdEmail: string = null;
  from: string = null;
  data: GeneralModalData = null;
  autoClose: boolean = false;
}

export class TargetInfoModal {
  target: TargetDataModel = null;
}

export class TargetSubCompanyModal{
  target: TargetDataModel = null;
  from: string;
}

export class RentModal{
  rent: DBDataModel[] = null;
  from: string;
}

export class StepperModal{
  rent: DBDataModel[] = null;
  startingIndex: number = null;
}

export class UploadSuccessModal {
  targetName: string = null;
  targetId: string = null;
  uploadType: string = null;
}

export class FilePreviewModal {
  targetName: string = null;
  file:any = null;
  fileName:any = null;
  content:any = null;
  buttonText:any = null;
  downloadButton:any = null;
}

export class GeneralModalData {
  content: string = null;
  buttonText: string = null;
  showIcon: boolean = false;
}

export class NewInfoModal{
  content: string = null;
  buttonText: string = null;
  from: string = null;
}

export class UserDeleteModal{
  title: string = null;
  content: string = null;
  buttonText: string = null;
}

export class SuccessModalModel {
  title: string;
  subtitle: string;
  action: string;
}

export class LetterCloseResult {
  action: CloseNextAction;
  letterModal: LetterModal;
}

export enum CloseNextAction {
  NAVIGATE_TO_MY_DATA = 1,
  OPEN_REQUEST_LETTER,
  CLOSE_AFTER_DELETE,
  DECRYPT_DATA
}

export class ReplyTargetModal {
  communication: CommunicationModel
}
// Utils


export class PopupDimensions {
  width: string;
  height: string;
}
