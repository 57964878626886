import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {Observable} from "rxjs";
import {CassandraPage} from "../model/cassandraPageModel";
import {NotificationModel} from "../model/notificationModel";

@Injectable()
export class NotificationControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  public getAllNotifications(pagingState?: any, observe?: 'body', reportProgress?: boolean): Observable<CassandraPage<NotificationModel>>;
  public getAllNotifications(pagingState?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CassandraPage<NotificationModel>>>;
  public getAllNotifications(pagingState?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CassandraPage<NotificationModel>>>;
  public getAllNotifications(pagingState?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<CassandraPage<NotificationModel>>(`${this.basePath}/notification/`,
      pagingState,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getNewNotifications(pagingState?: any, observe?: 'body', reportProgress?: boolean): Observable<CassandraPage<NotificationModel>>;
  public getNewNotifications(pagingState?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CassandraPage<NotificationModel>>>;
  public getNewNotifications(pagingState?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CassandraPage<NotificationModel>>>;
  public getNewNotifications(pagingState?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<CassandraPage<NotificationModel>>(`${this.basePath}/notification/unacknowledged`,
      pagingState,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getOldNotifications(pagingState?: any, observe?: 'body', reportProgress?: boolean): Observable<CassandraPage<NotificationModel>>;
  public getOldNotifications(pagingState?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CassandraPage<NotificationModel>>>;
  public getOldNotifications(pagingState?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CassandraPage<NotificationModel>>>;
  public getOldNotifications(pagingState?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<CassandraPage<NotificationModel>>(`${this.basePath}/notification/acknowledged`,
      pagingState,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public acknowledgeNotification(notification: NotificationModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public acknowledgeNotification(notification: NotificationModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public acknowledgeNotification(notification: NotificationModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public acknowledgeNotification(notification: NotificationModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<any>(`${this.basePath}/notification/acknowledge/${notification.id}`,
      {},
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public acknowledgeAllNotifications(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public acknowledgeAllNotifications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public acknowledgeAllNotifications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public acknowledgeAllNotifications( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<any>(`${this.basePath}/notification/acknowledge`,
      {},
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteNotification
   *
   * @param notificationId notificationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteNotificationUsingDELETE(notificationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteNotificationUsingDELETE(notificationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteNotificationUsingDELETE(notificationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteNotificationUsingDELETE(notificationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error('Required parameter notificationId was null or undefined when calling deleteNotificationUsingDELETE.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.delete<any>(`${this.basePath}/notification/delete/${encodeURIComponent(String(notificationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
