/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CertificateValidateModel {
  certificateCode?: string;
  certificateIssueDate?: string;
  gotCreditScore?: boolean;
  certificateStatus?: CertificateValidateModel.CertificateStatusEnum;
  mietermappeStatus?: CertificateValidateModel.MietermappeStatusEnum;
  userFullName?: string;
  scoreTargetId?: string;
  scoreTargetName?: string;
}
export namespace CertificateValidateModel {
  export type CertificateStatusEnum = 'NONE' | 'PENDING' | 'CAN_BE_ORDERED' | 'PENDING_PAYMENT' | 'PAID' | 'READY_FOR_DOWNLOAD' | 'DATA_EXPIRED' | 'EXPIRED' | 'READY_FOR_PAY' | 'PAID_AND_READY_FOR_DOWNLOAD';
  export const CertificateStatusEnum = {
    NONE: 'NONE' as CertificateStatusEnum,
    PENDING: 'PENDING' as CertificateStatusEnum,
    CANBEORDERED: 'CAN_BE_ORDERED' as CertificateStatusEnum,
    PENDINGPAYMENT: 'PENDING_PAYMENT' as CertificateStatusEnum,
    PAID: 'PAID' as CertificateStatusEnum,
    READYFORDOWNLOAD: 'READY_FOR_DOWNLOAD' as CertificateStatusEnum,
    DATAEXPIRED: 'DATA_EXPIRED' as CertificateStatusEnum,
    EXPIRED: 'EXPIRED' as CertificateStatusEnum,
    READYFORPAY: 'READY_FOR_PAY' as CertificateStatusEnum,
    PAIDANDREADYFORDOWNLOAD: 'PAID_AND_READY_FOR_DOWNLOAD' as CertificateStatusEnum
  }
  export type MietermappeStatusEnum = 'NONE' | 'CAN_BE_ORDERED' | 'DEUTSCHE_BANK_LOGIN' | 'READY_FOR_DOWNLOAD' | 'EXPIRED';
  export const MietermappeStatusEnum = {
    NONE: 'NONE' as MietermappeStatusEnum,
    CANBEORDERED: 'CAN_BE_ORDERED' as MietermappeStatusEnum,
    DEUTSCHEBANKLOGIN: 'DEUTSCHE_BANK_LOGIN' as MietermappeStatusEnum,
    READYFORDOWNLOAD: 'READY_FOR_DOWNLOAD' as MietermappeStatusEnum,
    EXPIRED: 'EXPIRED' as MietermappeStatusEnum
  }
}
