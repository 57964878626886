import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SaveSuccessModal} from '../../shared/models/modal.model';
import { Location } from '@angular/common';

@Component({
  selector: 'save-success.dialog',
  templateUrl: 'save-success.html',
  styleUrls: ['./save-success.scss'],
})
export class SaveSuccess implements OnInit {

  modalModel: SaveSuccessModal;
  componentName?: string;
  exitHovered = false;

  constructor(public dialogRef: MatDialogRef<SaveSuccess>, @Inject(MAT_DIALOG_DATA) public data: any, private location: Location) {
    this.modalModel = data["metadata"];
    this.componentName= data["componentName"];
  }

  ngOnInit() {
  }

  onButtonAction() {
    this.dialogRef.close({data: 'close_modal'});
    if(this.componentName=='FAQ' || this.componentName=='Help'){
      this.location.back();
    }
  }
}
