export namespace TchiboFormInputTypes {
  export type TypeEnum = 'personal_data_name_input' | 'personal_data_address_input' | 'personal_data_phone_input' |
    'personal_data_email_input' | 'client_clientnumber_checkbox' | 'client_clientnumber_input' | 'client_tchibo_cardnumber_input' |
    'tchiboexpert_checkbox' | 'tchiboexpert_email' | 'tchiboreisen_checkbox' | 'tchiboreisen_reisennummer_input' | 'qbo_inhaber_checkbox' |
    'qbo_inhaber_email_input' | 'newletter_checkbox' | 'newletter_email_input' | 'geschaftspartner_checkbox' |
    'geschaftspartner_company_input' | 'bewerber_checkbox' | 'bewerber_email_input' | 'journalist_checkbox' | 'journalist_input' |
    'tchibo_blog_checkbox' | 'tchibo_blog_email_input' | 'lieferant_checkbox' | 'lieferant_input' | 'tchibo_community_checkbox'
    | 'sonstige_checkbox' | 'tchibo_community_input' | 'sonstige_lieferant_number_input' | 'auskunft_all_checkbox' | 'auskunft_bankkonto_checkbox' |
    'auskunft_bonitatsdaten_checkbox' | 'auskunft_bestellhistorie_checkbox' | 'auskunft_kontoauszug_checkbox' |
    'auskunft_tchibocard_daten_checkbox' | 'auskunft_servicekontakte_checkbox' | 'auskunft_informationen_checkbox' | 'auskunft_reisebuchungen' |
    'auskunft_tchibo_experts_checkbox' | 'auskunft_blog_checkbox' | 'auskunft_qbo_profil_checkbox' | 'auskunft_online_checkbox' |
    'auskunft_online_ja_checkbox' | 'sofern_bewerbungsunterlagen_checkbox' | 'zur_bearbeitung_checkbox' | 'ja_email_daten_checkbox' | 'place_date_input' ;
  export const TypeEnum = {
    PERSONAL_DATA_NAME_INPUT: 'personal_data_name_input' as TypeEnum,
    PERSONAL_DATA_ADDRESS_INPUT: 'personal_data_address_input' as TypeEnum,
    PERSONAL_DATA_PHONE_INPUT: 'personal_data_phone_input' as TypeEnum,
    PERSONAL_DATA_EMAIL_INPUT: 'personal_data_email_input' as TypeEnum,

    CLIENT_CLIENTNUMBER_CHECKBOX: 'client_clientnumber_checkbox' as TypeEnum,
    CLIENT_CLIENTNUMBER_INPUT: 'client_clientnumber_input' as TypeEnum,
    CLIENT_TCHIBO_CARDNUMBER: 'client_tchibo_cardnumber_input' as TypeEnum,

    TCHIBOEXPERT_CHECKBOX: 'tchiboexpert_checkbox' as TypeEnum,
    TCHIBOEXPERT_EMAIL: 'tchiboexpert_email' as TypeEnum,

    TCHIBOREISEN_CHECKBOX: 'tchiboreisen_checkbox' as TypeEnum,
    TCHIBOREISEN_REISENNUMMER_INPUT: 'tchiboreisen_reisennummer_input' as TypeEnum,

    QBO_INHABER_CHECKBOX: 'qbo_inhaber_checkbox' as TypeEnum,
    QBO_INHABER_EMAIL_INPUT: 'qbo_inhaber_email_input' as TypeEnum,

    NEWLETTER_CHECKBOX: 'newletter_checkbox' as TypeEnum,
    NEWLETTER_EMAIL_INPUT: 'newletter_email_input' as TypeEnum,

    GESCHAFTSPARTNER_CHECKBOX: 'geschaftspartner_checkbox' as TypeEnum,
    GESCHAFTSPARTNER_COMPANY_INPUT: 'geschaftspartner_company_input' as TypeEnum,



    BEWERBER_CHECKBOX: 'bewerber_checkbox' as TypeEnum,
    BEWERBER_EMAIL_INPUT: 'bewerber_email_input' as TypeEnum,

    JOURNALIST_CHECKBOX: 'journalist_checkbox' as TypeEnum,
    JOURNALIST_INPUT: 'journalist_input' as TypeEnum,

    TCHIBO_BLOG_CHECKBOX: 'tchibo_blog_checkbox' as TypeEnum,
    TCHIBO_BLOG_EMAIL_INPUT: 'tchibo_blog_email_input' as TypeEnum,

    LIEFERANT_CHECKBOX: 'lieferant_checkbox' as TypeEnum,
    LIEFERANT_INPUT: 'lieferant_input' as TypeEnum,

    TCHIBO_COMMUNITY_CHECKBOX: 'tchibo_community_checkbox' as TypeEnum,
    TCHIBO_COMMUNITY_INPUT: 'tchibo_community_input' as TypeEnum,

    SONSTIGE_CHECKBOX: 'sonstige_checkbox' as TypeEnum,
    SONSTIGE_LIEFERANT_NUMBER_INPUT: 'sonstige_lieferant_number_input' as TypeEnum,

    AUSKUNFT_ALL_CHECKBOX: 'auskunft_all_checkbox' as TypeEnum,

    AUSKUNFT_BANKKONTO_CHECKBOX: 'auskunft_bankkonto_checkbox' as TypeEnum,
    AUSKUNFT_BONITATSDATEN_CHECKBOX: 'auskunft_bonitatsdaten_checkbox' as TypeEnum,
    AUSKUNFT_BESTELLHISTORIE_CHECKBOX: 'auskunft_bestellhistorie_checkbox' as TypeEnum,
    AUSKUNFT_KONTOAUSZUG_CHECKBOX: 'auskunft_kontoauszug_checkbox' as TypeEnum,
    AUSKUNFT_TCHIBOCARD_DATEN_CHECKBOX: 'auskunft_tchibocard_daten_checkbox' as TypeEnum,
    AUSKUNFT_SERVICEKONTAKTE_CHECKBOX: 'auskunft_servicekontakte_checkbox' as TypeEnum,
    AUSKUNFT_INFORMATIONEN_CHECKBOX: 'auskunft_informationen_checkbox' as TypeEnum,
    AUSKUNFT_REISEBUCHUNGEN: 'auskunft_reisebuchungen' as TypeEnum,
    AUSKUNFT_TCHIBO_EXPERTS_CHECKBOX: 'auskunft_tchibo_experts_checkbox' as TypeEnum,
    AUSKUNFT_BLOG_CHECKBOX: 'auskunft_blog_checkbox' as TypeEnum,
    AUSKUNFT_QBO_PROFIL_CHECKBOX: 'auskunft_qbo_profil_checkbox' as TypeEnum,
    AUSKUNFT_ONLINE_CHECKBOX: 'auskunft_online_checkbox' as TypeEnum,
    AUSKUNFT_ONLINE_JA_CHECKBOX: 'auskunft_online_ja_checkbox' as TypeEnum,



    SOFERN_BEWERBUNGSUNTERLAGEN_CHECKBOX: 'sofern_bewerbungsunterlagen_checkbox' as TypeEnum,

    ZUR_BEARBEITUNG_CHECKBOX: 'zur_bearbeitung_checkbox' as TypeEnum,

    JA_EMAIL_DATEN_CHECKBOX: 'ja_email_daten_checkbox' as TypeEnum,

    PLACE_DATE_INPUT: 'place_date_input' as TypeEnum,
  }
}
