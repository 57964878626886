import {AbstractControl, UntypedFormGroup, ValidationErrors} from "@angular/forms";
import { TargetDataModel } from "../api-models";
import * as moment from 'moment';

export class ValidationHelper {

   public static validateEmail(email){
        var isValid = false;
        if (validate(email)) {
            isValid = true;
        }

        function validate(email) {
            var re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            return re.test(email);
        }
        return isValid;
    }

    public static validatePasswordStrength(password){
     var isStrong=false;
     if(passwordIsStrong(password)){
       isStrong=true;
     }

     function passwordIsStrong(password){
       var pattern = /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;
       return pattern.test(password);
     }
     return isStrong;
    }

    public static validatePasswordMatch(password1, password2){
     var passwordsMatch=true;
     if(password1!==password2){
       passwordsMatch=false;
     }
     return passwordsMatch;
    }

    public static validateEmailMatch(email1, email2){
        var emailsMatch = true;
        if (email1 !== email2) {
            emailsMatch = false;
        }
        return emailsMatch;
    }

    public static validatePhoneNumber(number) {
      var x = number;
      var regex=/^(?:[+]?[\s]?\d){10,13}$/;
      if (x.match(regex))
      {
        return true;
      } else{
        return false;
      }
    }

    public static validateProfilePhoneNumber(number){
      var x = number;
      var regex=/^(?:[+]?[\s]?\d){10,13}$/;
      return !!x.match(regex);
    }

  public static validateAge(ageValue) {
    let birthday:any = moment(ageValue,'YYYYMMDD').format('x');
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (age < 18){
      return false;
    }else {
      return true;
    }
  }

}

export class ValidationRules{
    minLength?:number;
    maxLength?:number;
}

export function PasswordMatchValidator(c: AbstractControl): any {
  if(!c.parent || !c) return;
  const pwd = c.parent.get('passwordField');
  const cpwd= c.parent.get('confirmPasswordField')

  if(!pwd || !cpwd) return ;
  if (pwd.value !== cpwd.value) {
    return { invalidPasswordMatch: true };

  }
}
export function EmailMatchValidator(c: AbstractControl): any {
  if(!c.parent || !c) return;
  const pwd = c.parent.get('emailField');
  const cpwd= c.parent.get('confirmEmailField')

  if(!pwd || !cpwd) return ;
  if (pwd.value !== cpwd.value) {
    return { invalidEmailMatch: true };

  }
}
export function ValidateEmptyString(control: AbstractControl): any {
  var value = control.value;
  if (value == undefined || value.length === 0 || !value.trim()) {
    return { emptyString: true };
  }
  return null;
}

export function ValidatePostalCodeForm(control: AbstractControl): any{
  let regex = new RegExp('^[0-9]{5}$');
  let value = control.value;
  if(!regex.test(value)){
    return { invalidPostalCode: !regex.test(value)};
  }
  return null;

}

export function ValidateCityForm(control: AbstractControl): any {
  let regex = new RegExp('^([a-zA-ZÀ-ž \.\-]*)$');
  let value = control.value;
  if(!regex.test(value)){
    return { invalidCityName: !regex.test(value)};
  }
}

export function ValidateCustomerNumberForm(targetId: string){
  return  (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;
    let target: TargetDataModel = {
      targetId: targetId
    }
    if(PrerequisitesValidator.validateCustomerNumber(value, target)){
      return null;
    }else {
      return {invalidCustomerNumber: true}
    }
  }

}

export function ValidateStreetNumberForm(control: AbstractControl): any{
  //^[0-9]{1,4}[a-zA-Z]*$
  //. , / \ - *
  let regex = new RegExp('^[a-zA-Z0-9\-.*,\\\/]*$');
  let value = control.value;
  if(!regex.test(value)){
    return { invalidStreetNumber: !regex.test(value)};
  }
  return null;

}

export function ValidateSpecialCharacters(control: AbstractControl): any{
  let regex = new RegExp('^([a-zA-ZÀ-ž0-9 \.\-]*)$');
  let value = control.value;
  if(!regex.test(value)){
    return { specialCharacters: !regex.test(value)};
  }
  return null;

}

export function ValidatePasswordStrengthForm(control: AbstractControl): any{
  let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{7,}$');
  let value = control.value;
  if(!regex.test(value)){
    return { invalidPassword: !regex.test(value)};
  }
  return null;

}

export function ValidateEmailForm(control: AbstractControl): any{
  let regex = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$');
  let value = control.value;
  if(!regex.test(value)){
    return { invalidEmail: !regex.test(value)};
  }
  return null;

}


export function ValidatePostalCode(value: string): boolean {
  let regex = new RegExp('^[0-9]{5}$');
  return regex.test(value);
}

export function ValidateStreetNumber(value: string): boolean {
  let regex = new RegExp('^[a-zA-ZÀ-ž0-9\-.*,\\\/]*$');
  return regex.test(value);
}

export function ValidateCity(value: string): boolean {
  let regex = new RegExp('^([a-zA-ZÀ-ž \.\-]*)$');
  return regex.test(value);
}

export class RegistrationValidator {
    static validateEmails(emailFormGroup: UntypedFormGroup) {
        let email = emailFormGroup.controls.emailField.value;
        let repeatEmail = emailFormGroup.controls.confirmEmailField.value;

        if (repeatEmail == null ||  repeatEmail.length <= 0) {
            return null;
        }

        if (repeatEmail !== email) {
            return {
                invalidEmailMatch: true
            };
        }

        return null;

    }
    static validatePasswords(passwordFormGroup: UntypedFormGroup) {
        let password = passwordFormGroup.controls.passwordField.value;
        let repeatPassword = passwordFormGroup.controls.confirmPasswordField.value;

        if (repeatPassword == null || repeatPassword.length <= 0) {
            return null;
        }

        if (repeatPassword !== password) {
            return {
                invalidPasswordMatch: true
            };
        }

        return null;

    }
}


export class PrerequisitesValidator {

  static validateCustomerNumber(customerNumber: string, target: TargetDataModel): boolean {
    if (target.targetId == 'd5d52fa8-59bb-11e8-9c2d-fa7ae01bbebc') {
      let pattern = /^[0-9]{6,10}$/;
      return pattern.test(customerNumber);
    } else if (target.targetId == 'd5d5339a-59bb-11e8-9c2d-fa7ae01bbebc') {
      let pattern = /^[0-9]{5,20}$/;
      return pattern.test(customerNumber);
    } else if (target.targetId == '1c392a1c-89b4-11e8-9a94-a6cf71072f73') {
      let pattern = /^[0-9a-zA-Z]{10}$/;
      return pattern.test(customerNumber);
    } else if (target.targetId == 'b6c77f3e-89b4-11e8-9a94-a6cf71072f73') {
      let pattern = /^[0-9]{15}$/;
      return pattern.test(customerNumber);
    } else if (target.targetId == '0ddb53d6-89c4-11e8-9a94-a6cf71072f73') {
      let pattern = /^[0-9]{9}$/;
      return pattern.test(customerNumber);
    } else if (target.targetId == 'd5d52508-59bb-11e8-9c2d-fa7ae01bbebc') {
      let pattern = /^(k|K)[0-9a-zA-Z]{1,}$/;
      return pattern.test(customerNumber);
    } else {
      return true;
    }
  }
}
