import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AddressTargetSuccessModal} from '../../shared/models/modal.model';
import { Location } from '@angular/common';

@Component({
  selector: 'address-target-success.dialog',
  templateUrl: 'address-target-success.html',
  styleUrls: ['./address-target-success.scss'],
})
export class AddressTargetSuccess implements OnInit {

  modalModel: AddressTargetSuccessModal;
  componentName: any;
  exitHovered: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddressTargetSuccessModal>, @Inject(MAT_DIALOG_DATA) public data: any, private location: Location) {
    this.modalModel = data["metadata"];
    this.componentName= data["componentName"];
  }

  ngOnInit() {
    if(this.modalModel.shouldCloseAutomatically){
      setTimeout(() => {
        this.dialogRef.close({data: 'close_modal'});
      }, 16000)
    }
  }

  onButtonAction() {
    this.dialogRef.close({data: 'close_modal'});
    if(this.componentName=='FAQ' || this.componentName=='Help'){
      this.location.back();
    }
  }

  copyMessage(copyText: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onCloseBtnClick() {
    this.dialogRef.close({data: 'close_modal'});
  }
}
