/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttachmentModel } from './attachmentModel';
import { CommunicationModel } from './communicationModel';
import { UserTargetModel } from './userTargetModel';
import { TargetDataModel } from './targetDataModel';


export interface LetterModel {
    previewLetterType?:LetterModel.PreviewLetterType;

    attatchments?: Array<AttachmentModel>;
    date?: string;
    letterBody?: string;
    letterSubject?: string;

    communicationId?: string;
    communicationAction?: CommunicationModel.ActionEnum;
    communicationActionSubtype?: CommunicationModel.ActionSubTypeEnum;
    parsingErrorType?: TargetDataModel.ErrorTypeEnum
    targetId?: string;
    targetStatus?: UserTargetModel.StatusEnum;
    targetName?: string;

    allowDeletion?: boolean;
    
}

export namespace LetterModel {
  export type PreviewLetterType = 'CORRECT' | 'DELETE' | 'REQUEST' | 'REMINDER' | 'REPLY' | 'DETAILS';
  export const PreviewLetterType = {
    INCOMING: 'CORRECT' as PreviewLetterType,
    OUTGOING: 'DELETE' as PreviewLetterType,
    PENDING: 'REQUEST' as PreviewLetterType,
    WELCOME: 'REMINDER' as PreviewLetterType,
    REPLY: 'REPLY' as PreviewLetterType,
    CERTIFICATE: 'DETAILS' as PreviewLetterType
  }

  export type ErrorTypeEnum = 'GENERAL' | 'NAME' | 'NO_DATA' | 'NO_SCORE';
  export const ErrorTypeEnum = {
    GENERAL: 'GENERAL' as ErrorTypeEnum,
    NAME: 'NAME' as ErrorTypeEnum,
    NODATA: 'NO_DATA' as ErrorTypeEnum,
    NOSCORE: 'NO_SCORE' as ErrorTypeEnum
  }
}
