import {Injectable} from '@angular/core';
import {Token} from '../models/token.model';
import {HttpClient2} from "./http-client.service";
import {UserDataService} from './user-data.service';
import {ClientModel} from '../api-models';
import {PersistenceService} from "./persistence-service";
import {TrackingService} from "./tracking.service";
import {SessionLogoutModalComponent} from "../session-logout-modal/session-logout-modal.component";
import {MatDialog} from "@angular/material/dialog";


@Injectable()
export class AuthService {

  private tokenExpirationDate: Date = null;
  private token: Token;

  constructor(private http: HttpClient2, private userService: UserDataService,
              private persistence: PersistenceService, private trackingService: TrackingService,
              private dialog: MatDialog
  ) {
    this.initCredentials();
  }

  login(username: string, password: string, loginType: ClientModel.LoginTypeEnum): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // localStorage.clear()
      if (!username.trim()) {
        reject('Username cannot be blank');
      }
      if (!password.trim()) {
        reject('Password cannot be blank');
      }

      //remove token before login, if exists
      this.removeToken();

      let payload = 'username=' + encodeURIComponent(username) + '&password='
        + encodeURIComponent(password) + '&grant_type=password';
      this.http.postAuth('/oauth/token', payload)
      .subscribe(
        data => {
          this.logout();
          this.saveCredentials(data, true).then(response => {
            console.log("RESPONSE")
          });
          resolve('OK');
          return data;
        },
        err => {
          reject(err);
          //TODO
        }
      );
    });
  }

  verify2FACode(code: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.getAuth('/oauth/validate/' + code).subscribe(
        data => {
          this.saveCredentials(data, false).then(response => {
            console.log("RESPONSE")
          });
          resolve('OK');
          return data;
        }, err => {
          reject(err);
        });
    })
  }

  resend2FACode(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.getAuth('/oauth/resendTwoFactorCode').subscribe(
        data => {
          resolve('OK');
          return data;
        }, err => {
          reject(err);
        });
    })
  }


  saveCredentials(data: any, firstLogin: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.token = data;
      this.tokenExpirationDate = new Date(new Date().getTime() + (this.token.expires_in * 1000));
      if (this.token.tfa != null && this.token.tfa && firstLogin) {
        localStorage.setItem('has_two_factor', 'true');
        localStorage.setItem('pre_access_token', this.token.access_token);
      } else {
        localStorage.setItem('token', JSON.stringify(this.token));
        localStorage.setItem('access_token', this.token.access_token);
        localStorage.setItem('access_token_expiration_date', this.tokenExpirationDate.toString());
        localStorage.removeItem('pre_access_token');
      }
      resolve('OK');
    })
  }

  initCredentials() {
    let tokenObject = localStorage.getItem('token');
    if (tokenObject) {
      this.token = JSON.parse(tokenObject);
      this.tokenExpirationDate = new Date(localStorage.getItem('access_token_expiration_date'));
    }
  }

  signOut(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.logout();
      resolve('OK');
    });
  }

  public isAuthenticated(): boolean {
    return this.validateToken();
  }

  private validateToken(): boolean {
    if (localStorage.getItem('token') != null && this.token && this.tokenExpirationDate > new Date()) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  private removeToken() {
    // remove token before login if exist
    if (localStorage.getItem('token') != null) {
      localStorage.removeItem('token');
    }
    if (localStorage.getItem('access_token') != null) {
      localStorage.removeItem('access_token');
    }
    if (localStorage.getItem('access_token_expiration_date') != null) {
      localStorage.removeItem('access_token_expiration_date');
    }
  }

  public logout(): void {
    localStorage.clear();
    this.userService.setCurrentClient(null);
    this.token = null;
    this.persistence.resetLocalStorage();
  }

  public showSessionLogOut(): void {
    this.dialog.closeAll();
    this.dialog.open(SessionLogoutModalComponent);
  }

  public static decodeAccessToken(access_token: string) {
    return JSON.parse(window.atob(access_token.split('.')[1]));
  }

}


