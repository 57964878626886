<div class="session-logout-main-container">
  <div class="close-dialog-button">
    <img (mouseenter)="exitHovered = true;"
         (mouseleave)="exitHovered = false;"
         alt="close-button"
         (click)="onCloseBtnClick()"
         style="width: 27px; height: 27px;"
         [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
  </div>
  <h2 class="align-title-center header-center">
    {{translate.instant("SESSION_LOGOUT.TITLE")}}
  </h2>
  <div class="modal-description-text">
    {{translate.instant("SESSION_LOGOUT.DESCRIPTION_1")}}
  </div>
  <div class="session-button-adjustment">
    <button class="imd-button-secondary"
            (click)="onCloseBtnClick()">{{translate.instant("SESSION_LOGOUT.BUTTON")}}</button>
  </div>
</div>
