<div style="min-width: 800px" class="parent-class">
              <div class="form-container">
                            <form id="input-form" [formGroup]="myForm" novalidate>
                              <div *ngFor="let input of inputList; let i=index" [ngSwitch]="input.type">

                                <input *ngSwitchCase="'checkbox'"
                                       [name]="input.name"
                                       type="checkbox"
                                       [formControlName]="input.name"
                                       [value]="input.value"
                                       [ngStyle]="getInputPosition(input)">

                                <input *ngSwitchCase="'radio'"
                                       [name]="input.name"
                                       type="radio"
                                       [formControlName]="input.name"
                                       [value]="input.value"
                                       [ngStyle]="getInputPosition(input)">

                                <input *ngSwitchDefault
                                       [name]="input.name"
                                       type="text"
                                       [formControlName]="input.name"
                                       [value]="input.value"
                                       [ngStyle]="getInputPosition(input)">

                              </div>
                            </form>
                        </div>
<!--       <pdf-viewer id="viewer" class="pdf-form-class" [src]="pdfSrc" (after-load-complete)="loadComplete($event)"></pdf-viewer>-->
       <button id="saveButton" (click)="saveChanges()" type="button" class="submit-modal-btn">{{translate.instant("PDF_FORM_DIALOG.SAVE")}}</button>
</div>
