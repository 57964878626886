import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Modal } from "../shared/models/modal.model";
import * as FileSaver from 'file-saver';
import {DownloadService} from '../shared/services/download.service';
import { UntypedFormControl} from '@angular/forms';
import { TargetControllerService } from '../shared/api-models/api/targetController.service';
import { ClientControllerService } from '../shared/api-models/api/clientController.service';
import { TargetModel } from '../shared/api-models/model/targetModel';
import {Router} from '@angular/router';
import {AttachmentModel} from "../shared/api-models";

@Component({
    selector: 'general.dialog',
    templateUrl: 'general.dialog.html',
    styleUrls: ['./general.dialog.scss'],
  })
  export class GeneralDialog implements OnInit{

  modalModel: Modal;
  existingTargetSuggestions: TargetModel[] = [];

  inputValue: string = '';
  disabledStatus: boolean;

  exitHovered = false;

  myControl = new UntypedFormControl();
  options = [];
  attachmentModelType = AttachmentModel.AttachmentSourceTypeEnum;

  constructor(public dialogRef: MatDialogRef<GeneralDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
               private downloadService: DownloadService, private targetService: TargetControllerService,
               private clientService: ClientControllerService) {
    this.modalModel = data["metadata"];
    this.checkSubmitButtonStatus();
  }

  ngOnInit() {
    if(this.modalModel.type == "SuggestionModal"){
     this.targetService.getExistingTargetSuggestionsUsingGET().subscribe(response => {
       this.existingTargetSuggestions = response;
     });
    }
    this.autoClose();
  }

  autoClose(){
    if(this.modalModel.shouldAutoClose != null && this.modalModel.shouldAutoClose){
      this.modalModel.action = null;
      setTimeout(() => {
        this.dialogRef.close({data: 'close_modal'});
      }, 2000)
    }
  }

  checkSubmitButtonStatus() {
    if (this.modalModel.input != null) {
      this.disabledStatus = this.inputValue.trim().length == 0;
    } else {
      this.disabledStatus = false;
    }
  }

  public updated() {
    this.options = [];
    if(this.existingTargetSuggestions != null) {
      if (this.myControl.value.length > 0) {
        this.disabledStatus = false;
        let searchedWord = this.myControl.value
        for(let i=0; i< this.existingTargetSuggestions.length; i ++) {
          let r = this.existingTargetSuggestions[i].name.search(new RegExp(searchedWord, "i"));
          if (r != -1 && this.options.length < 5) {
            this.options.push({
              'name': this.existingTargetSuggestions[i].name,
              'subcategory': this.existingTargetSuggestions[i].subcategory
            });
          }
        }
      } else {
        this.options = []
      }
    }
    //check string to not have white spaces only
    this.disabledStatus = !(this.inputValue.trim().length >= 1);
  }

  onCloseBtnClick(): void {
    if (this.modalModel.type == "CERTIFICATE_SHARED_SECTION") {
      this.router.navigate(['dashboard/home']);
    }
    this.dialogRef.close({});
  }

  goToExistingTarget(option){
   if(option != null){
    let existingTarget = [];
    existingTarget.push({
      'name': option.name,
      'subcategory': option.subcategory,
      'subcategoryIndex': this.checkTargetSubcategory( option)
    });
    this.dialogRef.close({dropdownSelect: existingTarget[0]});
   }
  }

  onButtonAction($event) {
    if(this.modalModel.type == "DeleteAvatar"){
      this.clientService.deleteProfileImageUsingDELETE().subscribe(() =>{
        this.dialogRef.close({success: "DELETE_SUCCESS"});
        }, error => {
          if (error.error.code === 'PICTURE_NOT_FOUND'){
            this.dialogRef.close({pictureNotFoundError: "PICTURE_NOT_FOUND_ERROR"});
          }else{
            this.dialogRef.close({generalError: "GENERAL_ERROR"});
          }
      })
    }
    else if(this.modalModel.type=="DeleteIdentityid"){
      var attachmentType=this.attachmentModelType.IDCARD;
          this.clientService.deleteUserImageAttachmentsUsingDELETE(attachmentType).subscribe(result=>{
            this.dialogRef.close({success: "DELETE_SUCCESS_ID"});
          })
    } else if (this.modalModel.type == "CERTIFICATE_SHARED_SECTION") {
      this.router.navigate(['dashboard/home']);
      this.dialogRef.close();
    }
    else if(this.modalModel.type=="DeleteIdentitydriversLicense"){
      var attachmentType=this.attachmentModelType.DRIVERSLICENSE;
      this.clientService.deleteUserImageAttachmentsUsingDELETE(attachmentType).subscribe(result=>{
        this.dialogRef.close({success: "DELETE_SUCCESS_DRIVERS_LICENSE"});
      })
    }
    else if(this.modalModel.type=="DeleteIdentitysignature"){
      var attachmentType=this.attachmentModelType.SIGNATURE;
      this.clientService.deleteUserImageAttachmentsUsingDELETE(attachmentType).subscribe(result=>{
        this.dialogRef.close({success: "DELETE_SUCCESS_SIGNATURE"});
      })
    }
    else if(this.modalModel.input != null && this.options[0] != null && this.inputValue.toLowerCase() == this.options[0].name.toLowerCase()){
      let existingTarget = [];
      existingTarget.push({
        'name': this.options[0].name,
        'subcategory': this.options[0].subcategory,
        'subcategoryIndex': this.checkTargetSubcategory( this.options[0])
      });
      this.dialogRef.close({option: existingTarget[0]});
    } else if (this.modalModel.input != null) {
        this.dialogRef.close({data: this.inputValue});
    } else {
        this.dialogRef.close({});
      }
    }

  onTextChange() {
    this.checkSubmitButtonStatus();
  }

  onAttachmentDownload(attachment){
    this.downloadService.getAttachment(attachment.id)
    .subscribe(
      res => {
        FileSaver.saveAs(res, attachment.name)}
    )
  }

  checkTargetSubcategory(targetModel){
    let menuTabNumber =0;
    switch(targetModel.subcategory){
      case 'SOCIAL_MEDIA':
        menuTabNumber = 1;
        break;
      case 'CREDIT_SCORING':
        menuTabNumber = 2;
        break;
      case 'DATA_BROKER':
        menuTabNumber = 3;
        break;
      case 'TELECOMMUNICATION_UTILITIES':
        menuTabNumber = 4;
        break;
      case 'LOYALTY_AGGREGATORS':
        menuTabNumber = 5;
        break;
      // case 'LOGISTIC':
      //   menuTabNumber = 7;
      //   break;
      case 'RETAIL':
        menuTabNumber = 6;
        break;
      case 'BANKING_FINANCIAL':
        menuTabNumber = 7;
        break;
      case 'INSURANCES':
        menuTabNumber = 8;
        break;
      case 'MOBILITY':
        menuTabNumber = 9;
        break;
      case 'MEDIA':
        menuTabNumber = 10;
        break;
      case 'AUTHORITIES':
        menuTabNumber = 11;
        break;
      case 'SPORTS':
        menuTabNumber = 12;
        break;
    }
    return menuTabNumber;

  }

  getExtendedTitle() {
    return this.modalModel.extendedTitle;
  }

  hyperClick() {
    this.router.navigate([this.modalModel.urlLink]);
    this.dialogRef.close();
  }
}
