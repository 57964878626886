import {Injectable} from '@angular/core';
import {
  AddUserTargetModel,
  ClientControllerService, DigitalSelfrequest,
  LetterModel,
  ParameterModel,
  PrerequisitesModel,
  PreviewLetterDetailsModel,
  RequestDataModel,
  TargetControllerService,
  TargetDataModel,
  UserTargetControllerService,
  UserTargetModel
} from '../api-models';
import {CustomErrorHandler} from './custom-error-handler';
import {PrerequirementsDialog} from '../../dialogs/prerequirements.dialog';
import {DatePipe} from '@angular/common';
import {UserDataService} from './user-data.service';
import {
  AddressTargetSuccessModal,
  ConfirmPhoneModal,
  LetterModal,
  Modal,
  NewGeneralModal,
  UploadSuccessModal
} from '../models/modal.model';
import {PhoneCodeConfirmDialog} from '../../dialogs/phone-code-confirm/phone-code-confirm.dialog';
import {TranslateService} from '@ngx-translate/core';
import {LoadingSpinnerService} from '../components/spinner/spinner.service';
import {ViewLetterDialog} from '../../dialogs/view-letter/view-letter.dialog';
import {GeneralDialog} from '../../dialogs/general.dialog';
import {AddressTargetSuccess} from '../../dialogs/address-target-success/address-target-success';
import {TrackingService} from './tracking.service';
import {Observable} from 'rxjs';
import {NewGeneralPopup} from '../../dialogs/new-general-popup/new-general-popup';
import {OldAddressDialog} from "../../dialogs/old-address-popup/old-address.dialog";
import {ClientPdfForm} from "../../dialogs/client-pdf-form/client-pdf-form.dialog";
import TargetLetterResponseTypeEnum = TargetDataModel.TargetLetterResponseTypeEnum;
import TargetStatusEnum = TargetDataModel.TargetStatusEnum;
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import DigitalSelfrequestTypeEnum = DigitalSelfrequest.DigitalSelfrequestTypeEnum;

@Injectable()
export class SendTargetRequestService {

  public static BONIVERSUM_TARGET_ID = 'dc23c4d1-c009-4377-84b1-a10eea4e3e79';
  public static TCHIBO_TARGET_ID = 'c6c87f3e-89b4-11e8-9a84-a6cf81072f73';
       availableTargets: TargetDataModel[];
       pdfJsonData: string =  '';

       constructor(private targetService: TargetControllerService,
              private clientService: ClientControllerService,
              private userTargetService: UserTargetControllerService,
              private userService: UserDataService,
              private errorHandler: CustomErrorHandler,
              private authService: AuthService,
              private router: Router,
              private dialog: MatDialog,
              private translate: TranslateService,
              private spinnerService: LoadingSpinnerService,
              private tracker: TrackingService,
              private datePipe: DatePipe) { }

       sendRequest(target) {
        return Observable.create(observer => {
          if(target.targetId == '954eda86-89ba-11e8-9a94-a6cf71072f73') { //CHECK 24 target id MVP-3667
            this.getCheck24NoticeMessage().subscribe(res => {
              this.sendRequestPromise(target).subscribe(res => {
                observer.next("OK");
                observer.complete();
                });
              });
            } else {
              this.sendRequestPromise(target).subscribe(res => {
              observer.next("OK");
              observer.complete();
            });
          }
        });
       }

      sendBoniversumApiRequest(digitalSelfrequestTypeEnum) {
        return Observable.create(observer => {
          this.userTargetService.requestBoniversumApiDataUsingGET(digitalSelfrequestTypeEnum).subscribe(response => {
            this.spinnerService.hide();
            this.showBoniversumAPISuccessPopup("Boniversum").subscribe();
            observer.next("OK");
            observer.complete();
          }, error => {
            this.spinnerService.hide();
            this.showBoniversumAPIErrorPopup("Boniversum").subscribe();
            observer.next("OK");
            observer.complete();
          });
        });

      }

       requestBoniversumData(target, observer){
         this.spinnerService.show();
         this.userTargetService.requestBoniversumApiDataUsingGET(DigitalSelfrequestTypeEnum.SOFORT_M_L).subscribe(response => {
           this.spinnerService.hide();
           this.showBoniversumAPISuccessPopup(target.targetName).subscribe();
           observer.next("OK");
           observer.complete();
         }, error => {
           this.spinnerService.hide();
           this.showBoniversumAPIErrorPopup(target.targetName).subscribe();
           observer.next("OK");
           observer.complete();
         });
       }

   sendRequestPromise(target) {
    return Observable.create(observer => {
      this.userService.getCurrentClientWithCallback(client => {
        let hasOldAddress = client.hasSeenOldAddressDialog;
      try {
        let canRequestBoniversumApiData = this.userService.getClientData().instantBoniversumData;
        if (canRequestBoniversumApiData && target.targetId == SendTargetRequestService.BONIVERSUM_TARGET_ID){
          this.requestBoniversumData(target, observer);
        }else{
          this.targetService.getTargetPrerequisitesUsingGET(target.targetId).subscribe(response => {
            console.log("pre", response);
            if (!client.verifiedPhoneNumber || response.length > 0) {
              if (target.targetId  == SendTargetRequestService.TCHIBO_TARGET_ID && target.targetStatus == TargetStatusEnum.OVERDUE){
                this.showPreviewRequestLetter(target, []).subscribe(res => {
                  observer.next("OK");
                  observer.complete();
                });
              }else{
                let sortedResponse = this.sortPrerequisites(response);
                this.parsePrerequisites(sortedResponse, target, true, hasOldAddress).subscribe(res => {
                  observer.next("OK");
                  observer.complete();
                });
              }
            }
            else {
              this.showPreviewRequestLetter(target, []).subscribe(res => {
                observer.next("OK");
                observer.complete();
              });
            }
          }, error => {
            this.errorHandler.handleError(error);
            observer.error('address target error: ' + error);
          });
        }
      } catch(error) {
        observer.error('address target error: ' + error);
        observer.complete();
      }
      })
    })

   }

  sendOrderCertificate() {
    return Observable.create(observer => {

      let boniversumId = 'dc23c4d1-c009-4377-84b1-a10eea4e3e79';
      let boniversumName = 'Creditreform Boniversum GmbH';

      this.userService.getCurrentClientWithCallback(client => {
        let oldAddress = client.hasSeenOldAddressDialog;
        let target = {
          targetId: boniversumId,
          targetName: boniversumName
        };
        try {
          this.targetService.getTargetPrerequisitesUsingGET(target.targetId).subscribe(response => {
            console.log("pre", response);
            if (!client.verifiedPhoneNumber || response.length > 0) {
              let sortedResponse = this.sortPrerequisites(response);
              this.parsePrerequisites(sortedResponse, target, false, oldAddress).subscribe(res => {
                  observer.next("OK");
                  observer.complete();
              }, e => observer.error(e), () => {
                observer.complete();
              });
            } else {
              observer.next("OK");
              observer.complete();
            }
          }, error => {
            observer.error('address target error: ' + error);
            this.errorHandler.handleError(error);
          });

        } catch (error) {
          observer.error('address target error: ' + error);
          observer.complete();
        }
      })
    });
  }

  checkPaymentPrerequirementsPromise():Promise<any>{
    let boniversumId = 'dc23c4d1-c009-4377-84b1-a10eea4e3e79';
    let boniversumName = 'Creditreform Boniversum GmbH';
         return new Promise((res, err) => {
           this.userService.getCurrentClientWithCallback(client => {
             let oldAddress = client.hasSeenOldAddressDialog;
             let target = {
               targetId: boniversumId,
               targetName: boniversumName
             };
             try {
               this.targetService.getTargetPrerequisitesUsingGET(target.targetId).subscribe(response => {
                 console.log("pre", response);
                 if (!client.verifiedPhoneNumber || response.length > 0) {
                   // let sortedResponse = this.sortPrerequisites(response);
                   err("MISSING_PREREQUIREMENTS")
                 } else {
                   res("OK")
                 }
               }, error => {
                 this.errorHandler.handleError(error);
                 err('address target error: ' + error);
               });

             } catch (error) {
               err('address target error: ' + error);
             }
           })
         })
  }

       openOldAddressPopup(target, observer, shouldShowLetter){
         let modalModel: UploadSuccessModal = {
           targetId:"11111",
           targetName:"dummy social test",
           uploadType:"Certificate_Upload_Target"
         }

         let dialog = this.dialog.open(OldAddressDialog, {
           width: '650px',
           panelClass:'new-modal-container',
           data: { metadata: modalModel}
         });
         dialog.afterClosed().subscribe(res =>{
           if(res.closeAction === 'CLOSED_FROM_SEND' && shouldShowLetter){
             this.showPreviewRequestLetter(target, []).subscribe(res => {
               observer.next("OK");
               observer.complete();
             });
           }else if(res.closeAction === 'CLOSED_FROM_SEND'){
             observer.next("OK");
             observer.complete();
           }else{
             observer.error("Old Address Popup not closed from Send.")
           }
         })
       }

       sortPrerequisites(prerequisites: PrerequisitesModel[]) {
         prerequisites.sort(function (prereq1, prereq2) {
            if (prereq1.index < prereq2.index) {
                return -1;
             } else if (prereq1.index > prereq2.index) {
                 return 1;
             } else {
                return 0;
             }
          });
          return prerequisites;
       }

  removeDataFromStorage(){
    if(localStorage.getItem('dbClientModel') != null){
      localStorage.removeItem('dbClientModel');
    }
    if(localStorage.getItem('dbTarget') != null){
      localStorage.removeItem('dbTarget');
    }
    if(localStorage.getItem('showDBButton') != null){
      localStorage.removeItem('showDBButton');
    }
    if(localStorage.getItem('identificationSource') != null){
      localStorage.removeItem('identificationSource');
    }
  }

       parsePrerequisites(prerequisites, target, showPreviewLetter, hasOldAddress) {
        return Observable.create(observer => {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = false;
              dialogConfig.height = 'auto';
              dialogConfig.width = '650px';
              dialogConfig.panelClass = "security-dialog";
              dialogConfig.data = {
                     metadata: prerequisites, target: target
              };
              let dialog = this.dialog.open(PrerequirementsDialog, dialogConfig);

              dialog.afterClosed().subscribe(result => {
                // console.log(result.userClosed);
                //if phone number validation comes from serivces order DSB, redirect to payment page
                  if(result != 'YES_BANK_CLOSE'){
                    this.removeDataFromStorage();
                  }
                     if (result && result["data"]) {
                            this.pdfJsonData = result["json"];
                            let filledPrerequisites = [];
                            let dataPrerequirements = result["data"];
                            let phoneNumber = null;
                            prerequisites.forEach(element => {
                                   let key = element.field;
                                   let value = dataPrerequirements[key];

                                   if (key == "birthday" && value != null) {
                                          value = this.datePipe.transform(value, "yyyy-MM-dd");
                                   }
                                   if (key == "phoneNumber" && value != null) {
                                          phoneNumber = value;
                                   }
                                   let parameter: ParameterModel = {
                                          prerequisitesId: element.id,
                                          targetId: element.targetId,
                                          value: value
                                   };
                                   filledPrerequisites.push(parameter);
                            });

                            if(result["userData"]) {
                              let nameParameters = result["userData"];
                              let firstNameValue = nameParameters["firstName"];
                              if(firstNameValue != null && firstNameValue != undefined && firstNameValue != "") {
                                let parameter: ParameterModel = {
                                  targetId: target.targetId,
                                  prerequisitesId: "",
                                  value: firstNameValue,
                                  type: "firstName"
                                };
                                filledPrerequisites.push(parameter);
                              }

                              let lastNameValue = nameParameters["lastName"];
                              if(lastNameValue != null && lastNameValue != undefined && lastNameValue != "") {
                                let parameter: ParameterModel = {
                                  targetId: target.targetId,
                                  prerequisitesId: "",
                                  value: lastNameValue,
                                  type: "lastName"
                                };
                                filledPrerequisites.push(parameter);
                              }

                              let genderValue = nameParameters["salutationValue"];

                              if(genderValue != null) {
                                if(genderValue.salutationValue != null && genderValue.salutationValue != undefined) {
                                  genderValue = nameParameters["salutationValue"].salutationValue;
                                }
                                let parameter: ParameterModel = {
                                  targetId: target.targetId,
                                  prerequisitesId: "",
                                  value: genderValue,
                                  type: "gender"
                                };
                                filledPrerequisites.push(parameter);
                              }
                            }

                            this.clientService.updateClientProfileFromPrerequisitesUsingPUT(filledPrerequisites, result['identificationSource']).subscribe(response => {
                              //get data
                              this.userService.setCurrentClient(null);
                              this.userService.getCurrentClientWithCallback(res => {
                                this.userService.sendClientMessage(res);
                                if(target.targetId === SendTargetRequestService.TCHIBO_TARGET_ID && res.verifiedPhoneNumber && !result.userClosed){
                                  this.openPdfViewer().then(res => {
                                    if (showPreviewLetter) {
                                      this.showPreviewRequestLetter(target, filledPrerequisites).subscribe(res => {
                                        observer.next("OK");
                                        observer.complete();
                                      });} else{
                                      if(!result.userClosed){
                                        observer.next('OK');
                                        observer.complete();
                                      }
                                    }
                                  })
                                }else{
                                  if (showPreviewLetter && !result.userClosed) {
                                    this.showPreviewRequestLetter(target, filledPrerequisites).subscribe(res => {
                                      observer.next("OK");
                                      observer.complete();
                                    });} else{
                                    if(!result.userClosed){
                                      observer.next('OK');
                                      observer.complete();
                                    }
                                  }
                                }
                              })
                            }, error => {
                              this.spinnerService.hide();
                              this.openErrorModal(this.translate.instant('ERRORS.' + error.error.code));
                             observer.complete();
                            });
                     }else if(result && result.fromPhoneConfirmation){
                       if(showPreviewLetter){
                         this.showPreviewRequestLetter(target, []).subscribe(res => {
                           observer.next("OK");
                           observer.complete();
                         });
                       } else {
                         observer.next("OK");
                         observer.complete();
                       }
                     } else {
                       observer.complete();
                     }
              }, error => observer.error());
        });
       }

  openPdfViewer() {
    return new Promise((resolve, reject) => {
      let dialog = this.dialog.open(ClientPdfForm, {
        width: '950px',
        height: '750px'
      });
      dialog.afterClosed().subscribe(result => {
        this.createPdfDataJson(result);
        resolve('popup closed')
      });
    })
  }

  createPdfDataJson(pdfInputList) {
    let jsonObject:any = [];
    for(let value in pdfInputList.data) {
      if(pdfInputList.data.hasOwnProperty(value)){
        if(pdfInputList.data[value] === true){
          jsonObject.push(value + ':' + "On");
        }else if(pdfInputList.data[value] === false){
          jsonObject.push(value + ':' + "Off");
        }else{
          jsonObject.push(value + ':' + pdfInputList.data[value]);
        }
      }
      // //hacky way to enable the 'send' button if form has been seen and completed by the user.
      // this.form.controls['form'].setValue('form_completed');
      this.pdfJsonData = JSON.stringify(jsonObject);
    }
    // this.jsonObject = pdfInputList.data;
  }


       validateUserPhoneNumber(phoneNumber, prerequisites, target,showPreviewLetter, hasOldAddress) {
        return Observable.create(observer => {
              this.clientService.requestPhoneNumberValidationUsingGET(phoneNumber).subscribe(response => {});
              let modalModel: ConfirmPhoneModal = {
                closeAction: true,
                extendedTitle: null,
                title: this.translate.instant('CONFIRM_SMS_CODE.TITLE'),
                content: null,
                input: this.translate.instant('CONFIRM_SMS_CODE.INPUT_MESSAGE'),
                action: this.translate.instant('CONFIRM_SMS_CODE.ACTION'),
                phoneNumber: phoneNumber,
                hasLimitError:  false
              }

              let dialog = this.dialog.open(PhoneCodeConfirmDialog, {
                width: '470px',
                data: { metadata: modalModel }
              });

           dialog.afterClosed().subscribe(response => {
             if (response["data"] == 'success') {
                 if (showPreviewLetter) {
                   this.showPreviewRequestLetter(target, prerequisites).subscribe(res => {
                     observer.next("OK");
                     observer.complete();
                   });
                 } else {
                   observer.next("OK");
                   observer.complete();
                 }
             }
           });
         });
       }


       showPreviewRequestLetter(target, prerequisites) {
        return Observable.create(observer => {
              console.log("info: ", target);
              this.spinnerService.show();
              const correctionModel: PreviewLetterDetailsModel = {
                targetId: target.targetId,
                targetSubCompanies: target.selectedSubCompanies,
                type: target.targetStatus == UserTargetModel.StatusEnum.OVERDUE ? PreviewLetterDetailsModel.TypeEnum.REMINDER : PreviewLetterDetailsModel.TypeEnum.REQUEST,
                jsonData: this.pdfJsonData
              }

              // this.spinnerService.hide();
              // let dialogRef = this.openEmailSelectDialog();
              // dialogRef.afterClosed().subscribe(response => {
              //   this.spinnerService.show();
              //   let emailId = response == undefined ? undefined : response.emailId;
                this.userTargetService.previewLetterUsingPUT(correctionModel, null).subscribe(
                response => {
                  this.spinnerService.hide();
                  this.openLetterDialog(response, target, prerequisites, null).subscribe(res => {
                    observer.next("OK");
                    observer.complete();
                  });
                },
                error => {
                  this.spinnerService.hide();
                  this.openErrorModal(this.translate.instant('ERROR_MODAL.BODY'));
                  observer.error(error);
                });
              });
        // });
       }


        openErrorModal(errorMessage) {
              let modalModel: Modal = {
                closeAction: true,
                extendedTitle: null,
                title: null,
                content: errorMessage,
                input: null,
                action: this.translate.instant('ERROR_MODAL.ACTION'),
                attachments: null,
                footerAction: null,
                type: null
              }
              this.dialog.open(GeneralDialog, {
                width: '522px',
                data: { metadata: modalModel }
              }).afterClosed().subscribe(res => {
                if(errorMessage.includes('bereits ein Userkonto') || errorMessage.includes('already have a user account')) {
                  this.authService.logout();
                  this.router.navigate(['/login']);
                }
              });
       }


        openLetterDialog(serverLetterModel: LetterModel, target, prerequisites, emailId) {
          return Observable.create(observer => {
              let letterWidth = '';
              let letterModel: LetterModal = new LetterModal();
              letterModel.subject = 'PREVIEW';
              letterModel.cancelAction = true;
              letterModel.sendAction = true;
              letterModel.attachments = serverLetterModel.attatchments;
              letterModel.date = this.datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm');
              letterModel.htmlContent = serverLetterModel.letterBody;

              if (window.matchMedia('(min-height:320px)').matches && window.matchMedia('(max-height:500px)').matches &&
                window.matchMedia('(orientation: landscape)')) {
                letterWidth = '90%';
              } else {
                letterWidth = '60%';
              }

              let dialog = this.dialog.open(ViewLetterDialog, {
                width: letterWidth,
                height: '90%',
                panelClass: 'letter-mobile',
                data: {
                  metadata: letterModel,
                  targetId: target.targetId,
                  companyName: target.targetName,
                  sendCallType: 'REQUEST'
                }
              });
              dialog.afterClosed().subscribe(
                data => {
                  if (data) {
                    if (data.action == "show_success_dialog") {
                      if(target.targetId == '954eda86-89ba-11e8-9a94-a6cf71072f73') {
                        //MVP-3713 skip show addressTargetDialog for CHECK 24
                        this.addUserTargetOrSendReminder(prerequisites, target, emailId).subscribe( result => {
                          observer.next("OK");
                          observer.complete();
                        })
                      } else {
                        this.addressTargetOpenDialog(target, prerequisites, emailId).subscribe(res => {
                          observer.next("OK");
                          observer.complete();
                        });
                      }
                    }
                  }
                })
          });
       }


       addressTargetOpenDialog(target, filledPrerequisites, emailId){
        return Observable.create(observer => {

              // const metadata = {
              //   closeAction: true,
              //   title: "",
              //   titleDE: this.translate.instant('ADDRESS_TARGET_MODAL.TITLE_DE'),
              //   target: target.targetName,
              //   sendBtn: this.translate.instant('ADDRESS_TARGET_MODAL.SEND_BTN'),
              //   cancelBtn: this.translate.instant('ADDRESS_TARGET_MODAL.CANCEL_BTN')
              // };
              //
              // if(target.targetStatus == TargetDataModel.TargetStatusEnum.OVERDUE){
              //   metadata.title=this.translate.instant('ADDRESS_TARGET_MODAL.TITLE_REMINDER');
              // }
              // else{
              //   metadata.title=this.translate.instant('ADDRESS_TARGET_MODAL.TITLE');
              // }
              // const dialogConfig = new MatDialogConfig();
              // dialogConfig.height = 'auto',
              //   dialogConfig.width = '522px'
              //   dialogConfig.data = {
              //     metadata: metadata
              //   };
              //
              // let dialog = this.dialog.open(AddressTargetDialog,dialogConfig);
              // dialog.afterClosed().subscribe(result => {
              //   if(result.action=='send_letter') {
              //     this.addUserTargetOrSendReminder(filledPrerequisites, target, emailId).subscribe( result => {
              //       observer.next("OK");
              //       observer.complete();
              //     })
              //   }
              // })

          this.addUserTargetOrSendReminder(filledPrerequisites, target, emailId).subscribe( result => {
            observer.next("OK");
            observer.complete();
          })
            });
      }

      addUserTargetOrSendReminder(filledPrerequisites, target, emailId) {
        return Observable.create(observer => {
          if(target.targetStatus != TargetDataModel.TargetStatusEnum.OVERDUE){
            this.addUserTarget(filledPrerequisites, target, emailId).subscribe(res => {
              observer.next("OK");
              observer.complete();
            });
          } else{
            this.sendReminder(target, emailId).subscribe(res => {
              observer.next("OK");
              observer.complete();
            });
          }
        });
      }


    addUserTarget(prerequisites, target, emailId){
        return Observable.create(observer => {
       this.tracker.trackEventWithCustomDimension(TrackingService.DATA_REQUEST_EVENT,TrackingService.ACTION_SEND, target.targetName, Date.now(), target.targetName);
       let userTargetModel: AddUserTargetModel = {
         targetId: target.targetId,
         verifier: ""
       }

       //TODO add json data from Tchibo Form
       let requestDataModel: RequestDataModel = {
         addUserTargetModel: userTargetModel,
         parameterModels: prerequisites,
         jsonData: this.pdfJsonData,
         targetSubCompanies: target.selectedSubCompanies
       }

       this.userTargetService.addUserTargetUsingPOST(requestDataModel, emailId).subscribe(response => {
         this.userService.setCurrentClient(null);
         this.userService.getCurrentClient();
         this.showSentRequestSuccessfullyPopup(target);
         observer.next("OK");
         observer.complete();
       }, error => {
         this.errorHandler.handleError(error);
          observer.error(error);} );
      });
    }


     private showSentRequestSuccessfullyPopup(target){
       var addressSuccessModalModel = this.getSuccessRequestedTargetMessage(target.targetResponseType,target.targetName);
       this.dialog.open(AddressTargetSuccess, {
         width: '550px',
         data: {metadata: addressSuccessModalModel}
       }).afterClosed().subscribe(response =>{
         if(target.responseType != null && target.responseType == TargetDataModel.TargetResponseTypeEnum.FORWARDEMAILTOIMD) {
           this.openForwardEmailToImdPopup(target.targetName);
         }
       });
     }

     private getCheck24NoticeMessage() {
      return Observable.create(observer => {
        let check24NoticeModal: AddressTargetSuccessModal = {
          title1:this.translate.instant('ADDRESS_CHECK24_MODAL.TITLE1'),
          title2: this.translate.instant('ADDRESS_CHECK24_MODAL.TITLE2'),
          body: null,
          action: this.translate.instant('ADDRESS_CHECK24_MODAL.ACTION'),
          shouldCloseAutomatically: true
        };

        this.dialog.open(AddressTargetSuccess, {
          width: '550px',
          data: {metadata: check24NoticeModal}
        }).afterClosed().subscribe(response =>{
          observer.next("OK");
          observer.complete();
        });
      });
     }

     private getSuccessRequestedTargetMessage(targetLetterResponseType,targetName):AddressTargetSuccessModal{
         var successModalModel = new AddressTargetSuccessModal();
         switch (targetLetterResponseType) {
           case TargetLetterResponseTypeEnum.POSTAL:
             successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_POSTAL.TITLE',{targetName: targetName});
             successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_POSTAL.SUBTITLE');
             successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_POSTAL.ACTION');
             successModalModel.shouldCloseAutomatically = true;
             break;
           case TargetLetterResponseTypeEnum.ELECTRONIC:
             successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_ELECTRONIC.TITLE',{targetName: targetName});
             successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_ELECTRONIC.SUBTITLE');
             successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_ELECTRONIC.ACTION');
             successModalModel.shouldCloseAutomatically = true;
             break;
           case TargetLetterResponseTypeEnum.PORTAL:
             successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PORTAL.TITLE',{targetName: targetName});
             successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PORTAL.SUBTITLE');
             successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PORTAL.ACTION');
             successModalModel.shouldCloseAutomatically = true;
             break;
           case TargetLetterResponseTypeEnum.DOWNLOADLINK:
             successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_DOWNLOAD_LINK.TITLE',{targetName: targetName});
             successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_DOWNLOAD_LINK.SUBTITLE');
             successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_DOWNLOAD_LINK.ACTION');
             successModalModel.shouldCloseAutomatically = true;
             break;
            case TargetLetterResponseTypeEnum.PERSONALEMAILREQUEST:
              let targetRequestEmail = this.decideTargetRequestDataEmail(targetName);
              successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PERSONAL_EMAIL_REQUEST.TITLE',{targetName: targetName});
              successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PERSONAL_EMAIL_REQUEST.SUBTITLE',{targetRequestDataEmail: targetRequestEmail});
              successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PERSONAL_EMAIL_REQUEST.ACTION');
              successModalModel.copyButton = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_PERSONAL_EMAIL_REQUEST.COPY_TEXT_BUTTON');
              successModalModel.copyText = targetRequestEmail;
              successModalModel.shouldCloseAutomatically = true;
              break;
            default:
              successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_POSTAL.TITLE',{targetName: targetName});
              successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_POSTAL.SUBTITLE');
              successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_POSTAL.ACTION');
              successModalModel.shouldCloseAutomatically = true;
              break;
         }
         return successModalModel;
     }

  decideTargetRequestDataEmail(targetName) {
    console.log("TARGET NAME: ", targetName);
    if(targetName.includes('Check24')) {
      return 'webseite_datenschutz@check24.de';
    }
  }

  showBoniversumAPISuccessPopup(targetName){
    return Observable.create(observer => {
    var successModalModel = new AddressTargetSuccessModal();

    successModalModel.title1 = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_API.TITLE',{targetName: targetName});
    successModalModel.body = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_API.SUBTITLE');
    successModalModel.action = this.translate.instant('ADDRESS_TARGET_SUCCESS_MODAL_API.ACTION');

    this.dialog.open(AddressTargetSuccess, {
      width: '550px',
      data: {metadata: successModalModel}
    }).afterClosed().subscribe(response =>{
      observer.next("OK");
      observer.complete();
    });
  });
}

  showBoniversumAPIErrorPopup(targetName){
    return Observable.create(observer => {
    var errorModalModel = new AddressTargetSuccessModal();

    errorModalModel.title1 = this.translate.instant('ADDRESS_TARGET_ERROR_MODAL_API.TITLE',{targetName: targetName});
    errorModalModel.body = this.translate.instant('ADDRESS_TARGET_ERROR_MODAL_API.SUBTITLE');
    errorModalModel.action = this.translate.instant('ADDRESS_TARGET_ERROR_MODAL_API.ACTION');

    this.dialog.open(AddressTargetSuccess, {
      width: '550px',
      data: {metadata: errorModalModel}
    }).afterClosed().subscribe(response =>{
      observer.next("OK");
      observer.complete();
    });
  });
  }

     openForwardEmailToImdPopup(targetName){
       let userEmail = this.userService.getClientData().itsMyDataEmail;
      //TODO Add client imd email
      let modalModel: NewGeneralModal;
      modalModel = {
        targetName: targetName,
        imdEmail: userEmail,
        from: 'FORWARD_EMAIL',
        autoClose: true,
        data: {
          content: this.translate.instant("FORWARD_EMAIL_TO_IMD_MODAL.TEXT",{targetName: targetName, imdEmail: userEmail}),
          buttonText: this.translate.instant('FORWARD_EMAIL_TO_IMD_MODAL.BUTTON'),
          showIcon: true
        }
      };
      this.dialog.open(NewGeneralPopup,{
        width:'600px',
        panelClass:'new-modal-container',
        data:{metadata:modalModel}
      })
    }


     sendReminder(target: any, emailId) {
      return Observable.create(observer => {
       let userTargetModel: AddUserTargetModel = {
         targetId: target.targetId,
         verifier: ""
       }

       let requestDataModel: RequestDataModel = {
         addUserTargetModel: userTargetModel,
       }

       this.userTargetService.sendReminderLetterUsingPOST(requestDataModel, emailId).subscribe(response => {
         this.userService.setCurrentClient(null);
         this.userService.getCurrentClient();
         this.showSentRequestSuccessfullyPopup(target);
         observer.next("OK");
         observer.complete();
       }, error => { this.errorHandler.handleError(error) });
     });
    }

}
