import {DatePipe} from "@angular/common";
import {LinechartDataModel} from "../api-models/model/linechartDataModel";

export class DateFormatter {

    public static dateToLocalDate(date: Date, datePipe: DatePipe): string {
        let dateString = datePipe.transform(date, "dd.MM.yyyy HH:mm:ss");
        return dateString;
    }

    public static removeFutureDatesFromLineChart(linechartModel: LinechartDataModel){
      let date = new Date();
      let currentDay: any = date.getDate();
      //rebuild timelabels
      let timeLabels = [];
      let timeItems = [];
      let foundIndex;
      linechartModel.timeLabels.forEach((time,index) => {
        if(time.split('.')[0] < currentDay){
          timeLabels.push(time);
          foundIndex = index;
        }
      })
      // rebuild items list
      linechartModel.items[0].data.forEach((data, index) => {
        if(index <= foundIndex){
          timeItems.push(data);
        }
      })
      console.log(timeLabels);
      console.log(timeItems);
      linechartModel.timeLabels = timeLabels;
      linechartModel.items[0].data = timeItems;
    }

  public static formatDateToLocalDate(date: Date, datePipe: DatePipe): string {
    return datePipe.transform(date, "dd.MM.yyyy");
  }

  public static stringToLocalDateString(dateString: string, datePipe: DatePipe): string {
    return datePipe.transform(dateString, "dd.MM.yyyy HH:mm:ss");
  }

  public static simpleStringToLocalDateString(dateString: string, datePipe: DatePipe): string {
    return datePipe.transform(dateString, "dd.MM.yyyy HH:mm");
  }
  public static formatStringDateToLocalDate(dateString: string, datePipe: DatePipe): string {
    return datePipe.transform(dateString, "dd.MM.yyyy");
  }

  public static stringToTimestamp(dateString: string): number {
    return new Date(dateString).getTime();
  }

}
