import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FilePreviewModal} from '../../shared/models/modal.model';
import * as pdfObject from "pdfobject";
import * as zoomImg from 'assets/libs/image-zoom/directive.js'
import * as FileSaver from 'file-saver';
import {FileHelper} from "../../shared/helpers/file-helper";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {TrackingService} from "../../shared/services/tracking.service";


@Component({
  selector: 'preview-file-popup',
  templateUrl: 'preview-file-popup.html',
  styleUrls: ['./preview-file-popup.scss'],
})
export class PreviewFilePopup implements OnInit {

  modalModel: FilePreviewModal;
  public popupText: string = null;
  @ViewChild("fileViewer") documentViewer: ElementRef;
  public imageUrl:any;

  exitHovered = false;
  showShareButton: boolean = false;

  constructor(public dialogRef: MatDialogRef<PreviewFilePopup>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              protected router: Router,
              private tracker: TrackingService,
              public translate: TranslateService,) {
    this.modalModel = data["metadata"];
  }

  getPreviewTitle() {
    if (this.modalModel.fileName === 'WUC_Data.pdf') {
      return this.translate.instant('PREVIEW_FILE_POPUP.TITLE_WUC');
    }
    if (this.modalModel?.fileName?.includes('MBV') || this.modalModel?.fileName?.includes('Rechnung')) {
      return this.translate.instant('PREVIEW_FILE_POPUP.TITLE_INVOICE');
    }
    if (this.modalModel?.fileName?.includes('Bonitätszertifikat')) {
      this.showShareButton = true;
      return this.translate.instant('PREVIEW_FILE_POPUP.TITLE_CERTIFICATE');
    }
    if (this.modalModel.fileName === 'Kostenlose Selbstauskunft.pdf') {
      return this.translate.instant('PREVIEW_FILE_POPUP.TITLE_DSB_FREE');
    }
    if (this.modalModel.fileName === 'Selbstauskunft 365.pdf') {
      return this.translate.instant('PREVIEW_FILE_POPUP.TITLE_DSB_365');
    }
    return this.translate.instant('PREVIEW_FILE_POPUP.TITLE');
  }

  ngOnInit() {
    this.initFile();
  }

  shareEmail(){
    this.onCloseBtnClick();
    this.router.navigate(['dashboard/certificate-shared-section']);
  }
  initFile(){
    if(this.modalModel.file.type === 'application/pdf'){
      // load pdf
      setTimeout(() => {
        pdfObject.embed(URL.createObjectURL(this.modalModel.file), "#example1");

      },100)
    }else{
      setTimeout(()=> {
        zoomImg.zoom();
      },200)
      FileHelper.blobToDataURL(this.modalModel.file, res => {
        this.imageUrl = res;
      })
    }
  }

  blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function(e) {callback(a.result);}
    a.readAsDataURL(blob);
  }

  onCloseBtnClick(): void {
    this.dialogRef.close({});
  }

  downloadButton(): void{
    if (this.modalModel?.fileName?.includes('Bonitätszertifikat')) {
      this.tracker.trackEvent("Download Certificate", "download", localStorage.getItem("userId") );
    }
    if (this.modalModel?.fileName?.includes('WUC_Data')) {
      this.tracker.trackEvent("Download WUC_Data", "download", localStorage.getItem("userId") );
    }
    if (this.modalModel?.fileName?.includes('MBV')) {
      this.tracker.trackEvent("Download MBV", "download", localStorage.getItem("userId") );
    }
    if (this.modalModel?.fileName?.includes('Boniversum_Data')) {
      this.tracker.trackEvent("Download S or M or L", "download", localStorage.getItem("userId") );
    }
    FileSaver.saveAs(this.modalModel.file,this.modalModel.fileName);
  }

}
