/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Attachment } from './attachment';


export interface AdminNotificationModel {
  client?: string;
  clientEmail?: string;
  communicationAttachments?: Array<Attachment>;
  communicationId?: string;
  correctedBucketResult?: string;
  errorType?: string;
  id?: string;
  ocrBucketResult?: string;
  ocrResult?: string;
  sentDate?: Date;
  solvedDate?: Date;
  status?: AdminNotificationModel.StatusEnum;
  targetName?: string;
  type?: AdminNotificationModel.TypeEnum;
  ruleLog?: string;
  parsingDelayEmailSent?: boolean;
}
export namespace AdminNotificationModel {
  export type StatusEnum = 'PENDING' | 'VALIDATED' | 'CORRECTED' | 'REPARSED' | 'PENDING_PARSING_ERROR' | 'CLIENT_ERROR_EMAIL_SENT' | 'INVALID';
  export const StatusEnum = {
    PENDING: 'PENDING' as StatusEnum,
    VALIDATED: 'VALIDATED' as StatusEnum,
    CORRECTED: 'CORRECTED' as StatusEnum,
    REPARSED: 'REPARSED' as StatusEnum,
    PENDINGPARSINGERROR: 'PENDING_PARSING_ERROR' as StatusEnum,
    CLIENTERROREMAILSENT: 'CLIENT_ERROR_EMAIL_SENT' as StatusEnum,
    INVALID: 'INVALID' as StatusEnum
  }
  export type TypeEnum = 'OCR_ADMIN_VALIDATE' | 'OCR_TECH_ERROR_VALIDATE' | 'OCR_TECH_ERROR_INVALID_DOCUMENT_DATE' | 'OCR_TECH_ERROR_DATA_EXPIRED' | 'OCR_TECH_ERROR_NO_DATA' | 'OCR_TECH_ERROR_NO_SCORE';
  export const TypeEnum = {
    ADMINVALIDATE: 'OCR_ADMIN_VALIDATE' as TypeEnum,
    TECHERRORVALIDATE: 'OCR_TECH_ERROR_VALIDATE' as TypeEnum,
    TECHERRORDATAEXPIRED: 'OCR_TECH_ERROR_DATA_EXPIRED' as TypeEnum,
    OCRTECHERRORINVALIDDOCUMENT_DATE: 'OCR_TECH_ERROR_INVALID_DOCUMENT_DATE' as TypeEnum,
    TECHERRORNODATA: 'OCR_TECH_ERROR_NO_DATA' as TypeEnum,
    TECHERRORNOSCORE: 'OCR_TECH_ERROR_NO_SCORE' as TypeEnum
  }
}
