import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('basePath');
export const BASE_AUTH_PATH = new InjectionToken<string>('baseAuthPath');

export const sofortauskunftMProductId: string = "9ee1044a-8245-4f6f-8224-bbe1bca7c5d4";
export const sofortauskunftWUCProductId: string = "07d8649f-9894-4032-80ea-ce07189573a3";
export const sofortauskunftLProductId: string = "ea6bf61f-3802-4627-b181-a085b259dfde";
export const sofortauskunftSProductId: string = "12c893e9-9de6-4632-aeef-e5bddfe41821";
export const bonitaetszertifikatProductId: string = 'cab84d02-f4dc-4ad5-89bf-c10156477b57';


export const COLLECTION_FORMATS = {
    'csv': ',',
    'tsv': '   ',
    'ssv': ' ',
    'pipes': '|'
}
