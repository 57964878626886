import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {Observable} from "rxjs";
import {PeerComparisonModel} from "../model/peerComparisonModel";
import {BucketValueModel} from "../model/bucketValueModel";

@Injectable()
export class PeerComparisonControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * getBucketHiddenTargets
   *
   * @param bucketType bucketType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public computePeerComparisonScore(comparisonModel: PeerComparisonModel, observe?: 'body', reportProgress?: boolean): Observable<PeerComparisonModel>;
  public computePeerComparisonScore(comparisonModel: PeerComparisonModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeerComparisonModel>>;
  public computePeerComparisonScore(comparisonModel: PeerComparisonModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeerComparisonModel>>;
  public computePeerComparisonScore(comparisonModel: PeerComparisonModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<PeerComparisonModel>(`${this.basePath}/peerComparison/score`,
      comparisonModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getBucketHiddenTargets
   *
   * @param bucketType bucketType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAvailableScoreTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<BucketValueModel.TypeEnum>>;
  public getAvailableScoreTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BucketValueModel.TypeEnum>>>;
  public getAvailableScoreTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BucketValueModel.TypeEnum>>>;
  public getAvailableScoreTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<Array<BucketValueModel.TypeEnum>>(`${this.basePath}/peerComparison/scoreTypes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
