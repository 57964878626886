export const environment = {
  certificatePrice: 11.90,
  dsbPrice: 5.90,
  production: false,
  live: false,
  appUrl: "https://stage.selbstauskunft-online.de",
  apiUrl : "https://stage.selbstauskunft-online.de/api", //DONE
  authUrl: "https://stage.selbstauskunft-online.de", //DONE
  facebookAppID: "-", ////Not needed
  facebookApprovedPermissions:'-', ///Not needed
  facebookNotApprovedPermissions:'-', ///Not needed
  oAuth2ClientId: 'ZJ47ZQ9TV5RER6QPKB1TFH4VIE6PDC282OIC', //done
  oAuth2ClientSecret: '1ea982a6e203ec8cc9b95c8970cf6396d259169f23b120b59a65334eb401ccab', //DONE
  oAuth2ClientIdPlugin: '--', //not needed
  oAuth2ClientSecretPlugin: '--', //not needed
  crefopayShopPublicKey: '25a6854d852bc16ef3699790ca0318e7856fcffe9ee7b012a1f52a28e15a4004', //DONE
  crefopayUrl: 'https://sandbox.crefopay.de/secureFields/',  ////done
  yesBankRegister: 'https://accounts.sandbox.openbanking.verimi.cloud/?client_id=sandbox.yes.com:a7e8d242-88c2-4816-bbff-2c900bf3c1c2&prompt=select_account',//DONE
  deutscheBankUrlRegister: '--',   ///Not needed
  deutscheBankClientData: '--',   ///Not needed
  deutscheBankProfileClientData: '--',   ///Not needed
  deutscheBankUrlMietermappe: '--',   ///Not needed
  imdPlatformUrl: 'https://imd.zynksoftware.com/login?country=DE',
  paypalClientId: 'AVlkc-HBIhii7dxzBVGypvyvQV1tqrETXeRnwPOyhdV9I0yRCsvj5dpEdzvFPB3pRL2_zkRdwLLoB6K1', //sandbox
  idleTimeoutForAutomaticLogout: 10
};
