/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttachmentModel } from './attachmentModel';
import {TargetDataModel} from "./targetDataModel";
import {CertificateModel} from "./certificateModel";
import {TargetSubCompany} from "./targetSubCompany";




export interface CommunicationModel {
    action?: CommunicationModel.ActionEnum;
    actionSubType?: CommunicationModel.ActionSubTypeEnum;
    attachments?: Array<AttachmentModel>;
    body?: string;
    date?: string;
    downloadLink?: string;
    errorType?: CommunicationModel.ErrorTypeEnum;
    id?: string;
    isForwarded?: boolean;
    subject?: string;
    targetId?: string;
    targetName?: string;
    targetStatus?: TargetDataModel.TargetStatusEnum;
    certificateStatus?: CertificateModel.StatusEnum;
    type?: CommunicationModel.TypeEnum;
    uploadButtonStatus?: string;
    userId?: string;
    actionLabel?: string;
    isUpdateData?: boolean;
    targetSubCompanies?: Array<TargetSubCompany>;
    targetLogoImage?: string,
    titleLabel?: string,
    timestamp?: number;
    shouldShowBrief?: boolean;
}
export namespace CommunicationModel {
  export type ActionEnum =
    'REQUEST_LETTER_SENT'
    | 'INTERMEDIATE_INFO_RECEIVED'
    | 'INTERMEDIATE_INFO_REPLY'
    | 'DATA_RECEIVED'
    | 'MARKED_REQUEST_COMPLETED'
    | 'DOWNLOAD_LINK_RECEIVED'
    | 'PIN_RECEIVED'
    | 'CHANGES_NOTIFIED'
    | 'DELETION_MANDATED'
    | 'OVERDUE'
    | 'REQUEST_FAILED'
    | 'DATA_PROCESSED'
    | 'DATA_UPDATE_REQUEST_SENT'
    | 'LOGGED_IN_SOCIAL_NETWORK'
    | 'DATA_CORRECTION_CONFIRMED'
    | 'DATA_CORRECTION_CONTRADICTED'
    | 'DATA_DELETION_CONFIRMED'
    | 'DATA_DELETION_CONTRADICTED'
    | 'VIEW_LETTER'
    | 'TO_BE_DECRYPTED'
    | 'LOGGED_WITH_PLUGIN'
    | 'DATA_RECEIVED_AVAILABLE'
    | 'RECEIVED_EMAIL'
    | 'CERTIFICATE_PURCHASE'
    | 'CERTIFICATE_PAYMENT'
    | 'CERTIFICATE_DOWNLOAD'
    | 'DELIVERED_BY_UPLOAD'
    | 'DELIVERED'
    | 'REPLY_RECEIVED'
    | 'DATA_RECEIVED_ENCRYPTED'
    | 'DATA_RECEIVED_NO_DATA'
    | 'DATA_RECEIVED_NO_SCORE'
    | 'DATA_PARSING_ERROR'
    | 'DATA_UPLOADED'
    | 'REMINDER_SENT'
    | 'READY_FOR_UPDATE'
    | 'CERTIFICATE_CAN_BE_ORDERED'
    | 'CERTIFICATE_PAYMENT_SUCCESSFUL'
    | 'CERTIFICATE_CAN_BE_DOWNLOADED'
    | 'CERTIFICATE_PAYMENT_REJECTED'
    | 'CERTIFICATE_PAYMENT_CHARGEBACK'
    | 'CERTIFICATE_DATA_EXPIRED'
    | 'DIGITAL_SELFREQUEST_PAYMENT_SUCCESSFUL'
    | 'DIGITAL_SELFREQUEST_PAYMENT_REJECTED'
    | 'DIGITAL_SELFREQUEST_PAYMENT_CHARGEBACK'
    | 'DIGITAL_SELFREQUEST_PAYMENT_EXPIRED'
    | 'DIGITAL_SELFREQUEST_EXPIRED'
    | "MIETERMAPPE_DEUTSCHE_BANK_LOGIN"
    | 'MIETERMAPPE_CAN_BE_ORDERED'
    | 'MIETERMAPPE_READY_FOR_DOWNLOAD'
    | 'MIETERMAPPE_EXPIRED'
    | 'TARGET_DATA_EXPIRED'
    | 'CERTIFICATE_EXPIRED'
    | 'CORRECT_REQUEST_LETTER'
    | 'DELETION_REPLY'
    | 'REPLY_TO_REMINDER'
    | 'SHARED_CERTIFICATE_ON_EMAIL'
    | 'DATA_DELETED'
    | 'DATA_DELETED_BY_USER'
    | 'REQUESTFAILED'
    | 'REPLY_SENT'
    | 'DIGITAL_SELFREQUEST_EXPIRED_REMINDER'
    | 'EXTERNAL_PARTNER_REQUEST_SUCCESSFUL';
    export const ActionEnum = {
        MIETERMAPPEDEUTSCHEBANKLOGIN: 'MIETERMAPPE_DEUTSCHE_BANK_LOGIN' as ActionEnum,
        MIETERMAPPEEXPIRED: 'MIETERMAPPE_EXPIRED' as ActionEnum,
        MIETERMAPPECANBEORDERED: 'MIETERMAPPE_CAN_BE_ORDERED' as ActionEnum,
        MIETERMAPPEREADYFORDOWNLOAD: 'MIETERMAPPE_READY_FOR_DOWNLOAD' as ActionEnum,
        REQUESTLETTERSENT: 'REQUEST_LETTER_SENT' as ActionEnum,
        INTERMEDIATEINFORECEIVED: 'INTERMEDIATE_INFO_RECEIVED' as ActionEnum,
        INTERMEDIATEINFOREPLY: 'INTERMEDIATE_INFO_REPLY' as ActionEnum,
        DATARECEIVED: 'DATA_RECEIVED' as ActionEnum,
        DATAREFRESHRECEIVED: 'DATA_REFRESH_RECEIVED' as ActionEnum,
        MARKEDREQUESTCOMPLETED: 'MARKED_REQUEST_COMPLETED' as ActionEnum,
        DOWNLOADLINKRECEIVED: 'DOWNLOAD_LINK_RECEIVED' as ActionEnum,
        PINRECEIVED: 'PIN_RECEIVED' as ActionEnum,
        CHANGESNOTIFIED: 'CHANGES_NOTIFIED' as ActionEnum,
        DELETIONMANDATED: 'DELETION_MANDATED' as ActionEnum,
        OVERDUE: 'OVERDUE' as ActionEnum,
        REQUESTFAILED: 'REQUEST_FAILED' as ActionEnum,
        DATAPROCESSED: 'DATA_PROCESSED' as ActionEnum,
        DATAUPDATEREQUESTSENT: 'DATA_UPDATE_REQUEST_SENT' as ActionEnum,
        LOGGEDINSOCIALNETWORK: 'LOGGED_IN_SOCIAL_NETWORK' as ActionEnum,
        DATACORRECTIONCONFIRMED: 'DATA_CORRECTION_CONFIRMED' as ActionEnum,
        DATACORRECTIONCONTRADICTED: 'DATA_CORRECTION_CONTRADICTED' as ActionEnum,
        DATADELETIONCONFIRMED: 'DATA_DELETION_CONFIRMED' as ActionEnum,
        DATADELETIONCONTRADICTED: 'DATA_DELETION_CONTRADICTED' as ActionEnum,
        VIEWLETTER: 'VIEW_LETTER' as ActionEnum,
        TOBEDECRYPTED: 'TO_BE_DECRYPTED' as ActionEnum,
        LOGGEDWITHPLUGIN: 'LOGGED_WITH_PLUGIN' as ActionEnum,
        RECEIVEDEMAIL: 'RECEIVED_EMAIL' as ActionEnum,
        CERTIFICATEPURCHASE: 'CERTIFICATE_PURCHASE' as ActionEnum,
        CERTIFICATEPAYMENT: 'CERTIFICATE_PAYMENT' as ActionEnum,
        CERTIFICATEDOWNLOAD: 'CERTIFICATE_DOWNLOAD' as ActionEnum,
        DELIVEREDBYUPLOAD: 'DELIVERED_BY_UPLOAD' as ActionEnum,
        DELIVERED: 'DELIVERED' as ActionEnum,
        REPLYRECEIVED: 'REPLY_RECEIVED' as ActionEnum,
        DATADELETED: 'DATA_DELETED' as ActionEnum,
        DELETIONREPLY: 'DELETION_REPLY' as ActionEnum,
        REPLYTOREMINDER: 'REPLY_TO_REMINDER' as ActionEnum,
        DATARECEIVEDENCRYPTED: 'DATA_RECEIVED_ENCRYPTED' as ActionEnum,
        DATARECEIVEDNODATA: 'DATA_RECEIVED_NO_DATA' as ActionEnum,
        DATARECEIVEDNOSCORE: 'DATA_RECEIVED_NO_SCORE' as ActionEnum,
        DATAPARSINGERROR: 'DATA_PARSING_ERROR' as ActionEnum,
        DATAUPLOADED: 'DATA_UPLOADED' as ActionEnum,
        REMINDERSENT: 'REMINDER_SENT' as ActionEnum,
        READYFORUPDATE: 'READY_FOR_UPDATE' as ActionEnum,
        CERTIFICATECANBEORDERED: 'CERTIFICATE_CAN_BE_ORDERED' as ActionEnum,
        CERTIFICATEPAYMENTSUCCESSFUL: 'CERTIFICATE_PAYMENT_SUCCESSFUL' as ActionEnum,
        CERTIFICATECANBEDOWNLOADED: 'CERTIFICATE_CAN_BE_DOWNLOADED' as ActionEnum,
        CERTIFICATEPAYMENTREJECTED: 'CERTIFICATE_PAYMENT_REJECTED' as ActionEnum,
        CERTIFICATEPAYMENTCHARGEBACK: 'CERTIFICATE_PAYMENT_CHARGEBACK' as ActionEnum,
        CERTIFICATEDATAEXPIRED: 'CERTIFICATE_DATA_EXPIRED' as ActionEnum,
        DIGITALSELFREQUESTPAYMENTSUCCESSFUL: 'DIGITAL_SELFREQUEST_PAYMENT_SUCCESSFUL' as ActionEnum,
        DATARECEIVEDAVAILABLE:'DATA_RECEIVED_AVAILABLE' as ActionEnum,
        DIGITALSELFREQUESTPAYMENTREJECTED: 'DIGITAL_SELFREQUEST_PAYMENT_REJECTED' as ActionEnum,
        DIGITALSELFREQUESTPAYMENTCHARGEBACK: 'DIGITAL_SELFREQUEST_PAYMENT_CHARGEBACK' as ActionEnum,
        DIGITALSELFREQUESTPAYMENTEXPIRED: 'DIGITAL_SELFREQUEST_PAYMENT_EXPIRED' as ActionEnum,
        DIGITALSELFREQUESTEXPIRED: 'DIGITAL_SELFREQUEST_EXPIRED' as ActionEnum,
        TARGETDATAEXPIRED: 'TARGET_DATA_EXPIRED' as ActionEnum,
        CERTIFICATEEXPIRED: 'CERTIFICATE_EXPIRED' as ActionEnum,
        CORRECTREQUESTLETTER: 'CORRECT_REQUEST_LETTER' as ActionEnum,
        DATADELETEDBYUSER: 'DATA_DELETED_BY_USER' as ActionEnum,
        REPLYWITHEMAIL: 'REPLY_WITH_EMAIL' as ActionEnum,
        SHAREDCERTIFICATEONEMAIL: 'SHARED_CERTIFICATE_ON_EMAIL' as ActionEnum,
        REPLYSENT: 'REPLY_SENT' as ActionEnum,
        DIGITAL_SELFREQUEST_EXPIRED_REMINDER:'DIGITAL_SELFREQUEST_EXPIRED_REMINDER' as ActionEnum,
        EXTERNAL_PARTNER_REQUEST_SUCCESSFUL:'EXTERNAL_PARTNER_REQUEST_SUCCESSFUL' as ActionEnum
    }

    export type ActionSubTypeEnum = 'DATA_SEND_BY_POST' | 'FORWARD_EMAIL_TO_IMD' | 'NO_ACTION_REQUIRED' | 'MISSING_REQUIRED_IDENTIFICATION_DATA' | 'MISSING_REQUIRED_IDENTIFICATION_SIGNATURE' | 'INCORRECT_ID' | 'INCORRECT_IDENTIFICATION_DATA';
    export const ActionSubTypeEnum = {
        DATASENDBYPOST: 'DATA_SEND_BY_POST' as ActionSubTypeEnum,
        FORWARDEMAILTOIMD: 'FORWARD_EMAIL_TO_IMD' as ActionSubTypeEnum,
        NOACTIONREQUIRED: 'NO_ACTION_REQUIRED' as ActionSubTypeEnum,
        MISSINGREQUIREDIDENTIFICATIONDATA: 'MISSING_REQUIRED_IDENTIFICATION_DATA' as ActionSubTypeEnum,
        MISSINGREQUIREDIDENTIFICATIONSIGNATURE: 'MISSING_REQUIRED_IDENTIFICATION_SIGNATURE' as ActionSubTypeEnum,
        INCORRECTIDENTIFICATIONDATA: 'INCORRECT_IDENTIFICATION_DATA' as ActionSubTypeEnum,
        INCORRECTID: 'INCORRECT_ID' as ActionSubTypeEnum,
    }
    export type ErrorTypeEnum = 'GENERAL' | 'NAME' | 'NO_DATA' | 'NO_SCORE' | 'INCOMPLETE_DOCUMENT' | 'INVALID_DOCUMENT_DATE' | 'INVALID_DOCUMENT' | 'BAD_QUALITY' | 'INVALID_NAME' | 'WRONG_DOCUMENT_SCHUFA' | 'WRONG_DOCUMENT_CRIF' | 'INVALID_NAME_OR_BIRTHDAY' | 'INVALID_POSTAL_CODE';
    export const ErrorTypeEnum = {
        GENERAL: 'GENERAL' as ErrorTypeEnum,
        NAME: 'NAME' as ErrorTypeEnum,
        NODATA: 'NO_DATA' as ErrorTypeEnum,
        NOSCORE: 'NO_SCORE' as ErrorTypeEnum,
        INCOMPLETEDOCUMENT: 'INCOMPLETE_DOCUMENT' as ErrorTypeEnum,
        INVALIDDOCUMENT: 'INVALID_DOCUMENT' as ErrorTypeEnum,
        INVALIDDOCUMENTDATE: 'INVALID_DOCUMENT_DATE' as ErrorTypeEnum,
        BADQUALITY: 'BAD_QUALITY' as ErrorTypeEnum,
        INVALIDNAME: 'INVALID_NAME' as ErrorTypeEnum,
        WRONGDOCUMENTSCHUFA: 'WRONG_DOCUMENT_SCHUFA' as ErrorTypeEnum,
        WRONGDOCUMENTCRIF: 'WRONG_DOCUMENT_CRIF' as ErrorTypeEnum,
        INVALIDNAMEORBIRTHDAY: 'INVALID_NAME_OR_BIRTHDAY' as ErrorTypeEnum,
        INVALIDPOSTALCODE: 'INVALID_POSTAL_CODE' as ErrorTypeEnum,
    }

  export type TypeEnum =
    'INCOMING'
    | 'OUTGOING'
    | 'PENDING'
    | 'WELCOME'
    | 'CERTIFICATE'
    | 'DIGITAL_SELFREQUEST_M'
    | 'DIGITAL_SELFREQUEST_L'
    | 'DIGITAL_SELFREQUEST_S'
    | 'MIETERMAPPE'
    | 'DATA_DELETED'
    | 'WUC'
    | 'REQUEST_IMD_REGISTRATION'
    | 'REQUEST_IMD_CERTIFICATE'
    | 'REQUEST_IMD_CREDIT_SCORING_DATA'
  ;
    export const TypeEnum = {
        INCOMING: 'INCOMING' as TypeEnum,
        OUTGOING: 'OUTGOING' as TypeEnum,
        PENDING: 'PENDING' as TypeEnum,
        WELCOME: 'WELCOME' as TypeEnum,
        CERTIFICATE: 'CERTIFICATE' as TypeEnum,
        MIETERMAPPE: 'MIETERMAPPE' as TypeEnum,
        DATA_DELETED: 'DATA_DELETED' as TypeEnum,
        DIGITALSELFREQUESTM: 'DIGITAL_SELFREQUEST_M' as TypeEnum,
        DIGITALSELFREQUESTL: 'DIGITAL_SELFREQUEST_L' as TypeEnum,
        DIGITALSELFREQUESTS: 'DIGITAL_SELFREQUEST_S' as TypeEnum,
        REQUEST_IMD_REGISTRATION: 'REQUEST_IMD_REGISTRATION' as TypeEnum,
        REQUEST_IMD_CERTIFICATE: 'REQUEST_IMD_CERTIFICATE' as TypeEnum,
        REQUEST_IMD_CREDIT_SCORING_DATA: 'REQUEST_IMD_CREDIT_SCORING_DATA' as TypeEnum,
        WUC: 'WUC' as TypeEnum
    }
}
