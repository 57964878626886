/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';
import { CommunicationKPIModel } from '../model/communicationKPIModel';
import { CommunicationModel } from '../model/communicationModel';
import { LetterModel } from '../model/letterModel';
import { ResponseEntity } from '../model/responseEntity';
import { SortModel } from '../model/sortModel';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import {CustomPageImplCommunicationModel} from "../model/customPageImplCommunicationModel";



@Injectable()
export class CommunicationControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getAttachment
     *
     * @param uuid uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttachmentUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public getAttachmentUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public getAttachmentUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public getAttachmentUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getAttachmentUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/communication/attachment/${encodeURIComponent(String(uuid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getUserData
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDataUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public getUserDataUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public getUserDataUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public getUserDataUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/communication/downloadData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


  /**
   * checkBirthdayCorrectionCode
   *
   * @param code code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkBirthdayCorrectionCodeUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<string> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling checkBirthdayCorrectionCodeUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    return this.httpClient.get(`${this.basePath}/checkBirthdayCorrectionCode/${encodeURIComponent(String(code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        responseType: 'text',
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
     * getCommunicationHistory
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommunicationHistoryUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<CommunicationModel>>;
    public getCommunicationHistoryUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CommunicationModel>>>;
    public getCommunicationHistoryUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CommunicationModel>>>;
    public getCommunicationHistoryUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<CommunicationModel>>(`${this.basePath}/communication`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCommunicationKPI
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommunicationKPIUsingGET(observe?: 'body', reportProgress?: boolean): Observable<CommunicationKPIModel>;
    public getCommunicationKPIUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommunicationKPIModel>>;
    public getCommunicationKPIUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommunicationKPIModel>>;
    public getCommunicationKPIUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<CommunicationKPIModel>(`${this.basePath}/communication/kpi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCommunication
     *
     * @param uuid uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommunicationUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<CommunicationModel>;
    public getCommunicationUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommunicationModel>>;
    public getCommunicationUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommunicationModel>>;
    public getCommunicationUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getCommunicationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<CommunicationModel>(`${this.basePath}/communication/${encodeURIComponent(String(uuid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


        /**
     * decryptCommunicationAttachments
     *
     * @param communicationId communicationId
     * @param password password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public decryptCommunicationAttachmentsUsingPOST(communicationId: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<string> {
        if (communicationId === null || communicationId === undefined) {
            throw new Error('Required parameter communicationId was null or undefined when calling decryptCommunicationAttachmentsUsingPOST.');
        }
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling decryptCommunicationAttachmentsUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.post(`${this.basePath}/decryptCommunicationAttachments/${encodeURIComponent(String(communicationId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRequestLetter
     *
     * @param targetId targetId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRequestLetterUsingGET(targetId: string, observe?: 'body', reportProgress?: boolean): Observable<LetterModel>;
    public getRequestLetterUsingGET(targetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LetterModel>>;
    public getRequestLetterUsingGET(targetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LetterModel>>;
    public getRequestLetterUsingGET(targetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (targetId === null || targetId === undefined) {
            throw new Error('Required parameter targetId was null or undefined when calling getRequestLetterUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<LetterModel>(`${this.basePath}/communication/letter/${encodeURIComponent(String(targetId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getIntermediateInfoReplyLetter
     *
     * @param communicationId communicationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntermediateInfoReplyLetterUsingGET(communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<LetterModel>;
    public getIntermediateInfoReplyLetterUsingGET(communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LetterModel>>;
    public getIntermediateInfoReplyLetterUsingGET(communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LetterModel>>;
    public getIntermediateInfoReplyLetterUsingGET(communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (communicationId === null || communicationId === undefined) {
            throw new Error('Required parameter communicationId was null or undefined when calling getIntermediateInfoReplyLetterUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.get<LetterModel>(`${this.basePath}/getIntermediateInfoReplyLetter/${encodeURIComponent(String(communicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


     /**
     * sendIntermediateInfoReplyLetter
     *
     * @param communicationId communicationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendIntermediateInfoReplyLetterUsingGET(communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public sendIntermediateInfoReplyLetterUsingGET(communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public sendIntermediateInfoReplyLetterUsingGET(communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public sendIntermediateInfoReplyLetterUsingGET(communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (communicationId === null || communicationId === undefined) {
            throw new Error('Required parameter communicationId was null or undefined when calling sendIntermediateInfoReplyLetterUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.get<ResponseEntity>(`${this.basePath}/sendIntermediateInfoReplyLetter/${encodeURIComponent(String(communicationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getSortedCommunicationPaged
     *
     * @param sortModel sortModel
     * @param page page
     * @param size size
     * @param cursor cursor

     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSortedCommunicationPagedUsingPUT(sortModel: SortModel, page?: number, size?: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomPageImplCommunicationModel>;
    public getSortedCommunicationPagedUsingPUT(sortModel: SortModel, page?: number, size?: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomPageImplCommunicationModel>>;
    public getSortedCommunicationPagedUsingPUT(sortModel: SortModel, page?: number, size?: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomPageImplCommunicationModel>>;
    public getSortedCommunicationPagedUsingPUT(sortModel: SortModel, page?: number, size?: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      if (sortModel === null || sortModel === undefined) {
        throw new Error('Required parameter sortModel was null or undefined when calling getSortedCommunicationPagedUsingPUT.');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (page !== undefined) {
        queryParameters = queryParameters.set('page', <any>page);
      }
      if (size !== undefined) {
        queryParameters = queryParameters.set('size', <any>size);
      }
      if (cursor !== undefined) {
        queryParameters = queryParameters.set('cursor', <any>cursor);
      }


      let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.put<CustomPageImplCommunicationModel>(`${this.basePath}/communication/sort`,
            sortModel,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
  /**
   * reuploadAttachments
   *
   * @param communicationId communicationId
   * @param files files
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public reuploadAttachmentsUsingPOST(communicationId: string, files: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public reuploadAttachmentsUsingPOST(communicationId: string, files: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public reuploadAttachmentsUsingPOST(communicationId: string, files: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public reuploadAttachmentsUsingPOST(communicationId: string, files: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (communicationId === null || communicationId === undefined) {
      throw new Error('Required parameter communicationId was null or undefined when calling reuploadAttachmentsUsingPOST.');
    }
    if (files === null || files === undefined) {
      throw new Error('Required parameter files was null or undefined when calling reuploadAttachmentsUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'multipart/form-data'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let formParams: { append(param: string, value: any): void; };
    let useForm = true;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    if (files) {
      files.forEach((element) => {
        // @ts-ignore
        formParams = formParams.append('files', <any>element) || formParams;
      })
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<ResponseEntity>(`${this.basePath}/communication/reupload/attachments/${encodeURIComponent(String(communicationId))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
    /**
     * saveAttachments
     *
     * @param targetId targetId
     * @param files files
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveAttachmentsUsingPOST(targetId: string, files: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public saveAttachmentsUsingPOST(targetId: string, files: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public saveAttachmentsUsingPOST(targetId: string, files: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public saveAttachmentsUsingPOST(targetId: string, files: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (targetId === null || targetId === undefined) {
            throw new Error('Required parameter targetId was null or undefined when calling saveAttachmentsUsingPOST.');
        }
        if (files === null || files === undefined) {
            throw new Error('Required parameter files was null or undefined when calling saveAttachmentsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'multipart/form-data'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let formParams: { append(param: string, value: any): void; };
        let useForm = true;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                // @ts-ignore
              formParams = formParams.append('files', <any>element) || formParams;
            })
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<ResponseEntity>(`${this.basePath}/communication/save/attachments/${encodeURIComponent(String(targetId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public decryptAttachmentUsingPOST(attachmentId: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<string> {
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling decryptAttachmentUsingPOST.');
        }
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling decryptAttachmentUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.post(`${this.basePath}/decryptAttachment/${encodeURIComponent(String(attachmentId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * saveUploadButtonStatus
   *
   * @param status status
   * @param communicationId communicationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveUploadButtonStatusUsingPOST(status: string, communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public saveUploadButtonStatusUsingPOST(status: string, communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public saveUploadButtonStatusUsingPOST(status: string, communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public saveUploadButtonStatusUsingPOST(status: string, communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (status === null || status === undefined) {
      throw new Error('Required parameter status was null or undefined when calling saveUploadButtonStatusUsingPOST.');
    }
    if (communicationId === null || communicationId === undefined) {
      throw new Error('Required parameter communicationId was null or undefined when calling saveUploadButtonStatusUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (communicationId !== undefined) {
      queryParameters = queryParameters.set('communicationId', <any>communicationId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<ResponseEntity>(`${this.basePath}/saveUploadButtonStatus`,
      status,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteCommunication
   *
   * @param communicationId communicationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCommunicationUsingGET(communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public deleteCommunicationUsingGET(communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public deleteCommunicationUsingGET(communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public deleteCommunicationUsingGET(communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (communicationId === null || communicationId === undefined) {
      throw new Error('Required parameter communicationId was null or undefined when calling deleteCommunicationUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<ResponseEntity>(`${this.basePath}/deleteCommunication/${encodeURIComponent(String(communicationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * replyWithEmailTarget
   *
   * @param emailMessage emailMessage
   * @param communicationId communicationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public replyWithEmailTargetUsingPOST(emailMessage: string, communicationId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public replyWithEmailTargetUsingPOST(emailMessage: string, communicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public replyWithEmailTargetUsingPOST(emailMessage: string, communicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public replyWithEmailTargetUsingPOST(emailMessage: string, communicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (emailMessage === null || emailMessage === undefined) {
      throw new Error('Required parameter emailMessage was null or undefined when calling replyWithEmailTargetUsingPOST.');
    }
    if (communicationId === null || communicationId === undefined) {
      throw new Error('Required parameter communicationId was null or undefined when calling replyWithEmailTargetUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (communicationId !== undefined) {
      queryParameters = queryParameters.set('communicationId', <any>communicationId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<ResponseEntity>(`${this.basePath}/replyWithEmail`,
      emailMessage,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


}
