import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// to fix pace.js(loading bar) bug on IE11 we will import the js here, instead of index.
function isIE() {
  const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
  let isIE = false;

  if (match !== -1) {
    isIE = true;
  }

  return isIE;
}

function inIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

// declare var System;
// if (isIE && !inIframe()) {
//   System.import('assets/libs/pace.min');
// }
