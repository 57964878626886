/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';
import { ResponseEntity } from '../model/responseEntity';

import { Observable }                                        from 'rxjs';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ChargeResponseModel, PaymentCreationModel } from '..';
import { TransactionModel } from '../model/models';
import {AppliedVoucherClientModel} from "../model/appliedVoucherClientModel";


@Injectable()
export class PaymentControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


     /**
     * chargePayment
     *
     * @param paymentCreationModel paymentCreationModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargePaymentUsingPOST(paymentCreationModel: PaymentCreationModel, observe?: 'body', reportProgress?: boolean): Observable<ChargeResponseModel>;
    public chargePaymentUsingPOST(paymentCreationModel: PaymentCreationModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChargeResponseModel>>;
    public chargePaymentUsingPOST(paymentCreationModel: PaymentCreationModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChargeResponseModel>>;
    public chargePaymentUsingPOST(paymentCreationModel: PaymentCreationModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (paymentCreationModel === null || paymentCreationModel === undefined) {
            throw new Error('Required parameter paymentCreationModel was null or undefined when calling chargePaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<ChargeResponseModel>(`${this.basePath}/payment/chargePayment`,
            paymentCreationModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


     /**
     * createTransaction
     *
     * @param productIdList productIdList
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTransactionUsingPOST(productIdList: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<TransactionModel>;
    public createTransactionUsingPOST(productIdList: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionModel>>;
    public createTransactionUsingPOST(productIdList: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionModel>>;
    public createTransactionUsingPOST(productIdList: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productIdList === null || productIdList === undefined) {
            throw new Error('Required parameter productIdList was null or undefined when calling createTransactionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<TransactionModel>(`${this.basePath}/payment/createTransaction`,
            productIdList,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



    /**
     * checkPaymentStatusAfterRedirect
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkPaymentStatusAfterRedirectUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<string> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling checkPaymentStatusAfterRedirectUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get(`${this.basePath}/payment/checkPaymentStatus/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isPaymentPermitted
     *
     * @param paymentType paymentType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isPaymentPermittedUsingGET(productType: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isPaymentPermittedUsingGET(productType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isPaymentPermittedUsingGET(productType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isPaymentPermittedUsingGET(productType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (productType !== undefined) {
        queryParams = queryParams.set('productType', <any>productType);
      }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<boolean>(`${this.basePath}/payment/paymentPermitted`,
            {
                params: queryParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * authorizePayPal
     *
     * @param crefopayOrderID crefopayOrderID
     * @param paypalOrderId paypalOrderId
     * @param voucherCode voucherCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authorizePayPalUsingPOST(crefopayOrderID: string, paypalOrderId: string, voucherCode?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public authorizePayPalUsingPOST(crefopayOrderID: string, paypalOrderId: string, voucherCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public authorizePayPalUsingPOST(crefopayOrderID: string, paypalOrderId: string, voucherCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public authorizePayPalUsingPOST(crefopayOrderID: string, paypalOrderId: string, voucherCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (crefopayOrderID === null || crefopayOrderID === undefined) {
            throw new Error('Required parameter crefopayOrderID was null or undefined when calling authorizePayPalUsingPOST.');
        }
        if (paypalOrderId === null || paypalOrderId === undefined) {
            throw new Error('Required parameter paypalOrderId was null or undefined when calling authorizePayPalUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (crefopayOrderID !== undefined) {
            queryParameters = queryParameters.set('crefopayOrderID', <any>crefopayOrderID);
        }
        if (paypalOrderId !== undefined) {
            queryParameters = queryParameters.set('paypalOrderId', <any>paypalOrderId);
        }
        if (voucherCode !== undefined) {
            queryParameters = queryParameters.set('voucherCode', <any>voucherCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<ResponseEntity>(`${this.basePath}/payment/authorizePayPal`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * calculateVoucherDiscount
     *
     * @param voucherCode voucherCode
     * @param paymentProvider paymentProvider
     * @param productIdList productIdList
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public calculateVoucherDiscountUsingPOST(voucherCode: string, paymentProvider: string, productIdList: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public calculateVoucherDiscountUsingPOST(voucherCode: string, paymentProvider: string, productIdList: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public calculateVoucherDiscountUsingPOST(voucherCode: string, paymentProvider: string, productIdList: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public calculateVoucherDiscountUsingPOST(voucherCode: string, paymentProvider: string, productIdList: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
         if (voucherCode === null || voucherCode === undefined) {
             throw new Error('Required parameter voucherCode was null or undefined when calling calculateVoucherDiscountUsingPOST.');
         }
         if (paymentProvider === null || paymentProvider === undefined) {
             throw new Error('Required parameter paymentProvider was null or undefined when calling calculateVoucherDiscountUsingPOST.');
         }
         if (productIdList === null || productIdList === undefined) {
             throw new Error('Required parameter productIdList was null or undefined when calling calculateVoucherDiscountUsingPOST.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (voucherCode !== undefined) {
             queryParameters = queryParameters.set('voucherCode', <any>voucherCode);
         }
         if (paymentProvider !== undefined) {
             queryParameters = queryParameters.set('paymentProvider', <any>paymentProvider);
         }
         if (productIdList) {
             productIdList.forEach((element) => {
                 queryParameters = queryParameters.append('productIdList', <any>element);
             })
         }

         let headers = this.defaultHeaders;

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             '*/*'
         ];
         let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set("Accept", httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         let consumes: string[] = [
             'application/json'
         ];
         let basicAuthHeader = localStorage.getItem('access_token');
         headers = headers.set("Authorization", "bearer " + basicAuthHeader);
         return this.httpClient.post(`${this.basePath}/payment/calculateVoucherDiscount`,
             null,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 responseType: 'text',
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     * crefopayReserveCall
     *
     * @param orderID orderID
     * @param paymentMethod paymentMethod
     * @param paymentInstrumentID paymentInstrumentID
     * @param voucherCode voucherCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public crefopayReserveCallUsingPOST(orderID: string, paymentMethod: string, paymentInstrumentID: string, voucherCode?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public crefopayReserveCallUsingPOST(orderID: string, paymentMethod: string, paymentInstrumentID: string, voucherCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public crefopayReserveCallUsingPOST(orderID: string, paymentMethod: string, paymentInstrumentID: string, voucherCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public crefopayReserveCallUsingPOST(orderID: string, paymentMethod: string, paymentInstrumentID: string, voucherCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (orderID === null || orderID === undefined) {
            throw new Error('Required parameter orderID was null or undefined when calling crefopayReserveCallUsingPOST.');
        }
        if (paymentMethod === null || paymentMethod === undefined) {
            throw new Error('Required parameter paymentMethod was null or undefined when calling crefopayReserveCallUsingPOST.');
        }
        if (paymentInstrumentID === null || paymentInstrumentID === undefined) {
            throw new Error('Required parameter paymentInstrumentID was null or undefined when calling crefopayReserveCallUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orderID !== undefined) {
            queryParameters = queryParameters.set('orderID', <any>orderID);
        }
        if (paymentMethod !== undefined) {
            queryParameters = queryParameters.set('paymentMethod', <any>paymentMethod);
        }
        if (paymentInstrumentID !== undefined) {
            queryParameters = queryParameters.set('paymentInstrumentID', <any>paymentInstrumentID);
        }
        if (voucherCode !== undefined) {
            queryParameters = queryParameters.set('voucherCode', <any>voucherCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post(`${this.basePath}/payment/reserve`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * validateReserveCall
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validate3DSReserveCallUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public validate3DSReserveCallUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public validate3DSReserveCallUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public validate3DSReserveCallUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/payment/validate3DSReserveCall`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


  public updateTransaction(crefopayOrderID: string, startedPaymentProcess : boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateTransaction(crefopayOrderID: string, startedPaymentProcess : boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateTransaction(crefopayOrderID: string, startedPaymentProcess : boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateTransaction(crefopayOrderID: string, startedPaymentProcess : boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (crefopayOrderID !== undefined) {
      queryParameters = queryParameters.set('crefopayOrderID', <any>crefopayOrderID);
    }
    if (startedPaymentProcess !== undefined) {
      queryParameters = queryParameters.set('startedPaymentProcess', <any>startedPaymentProcess);
    }
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.put(`${this.basePath}/payment/updatePayment`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        responseType: 'text',
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public saveAppliedVoucherClient(appliedVoucherClientModel: AppliedVoucherClientModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public saveAppliedVoucherClient(appliedVoucherClientModel: AppliedVoucherClientModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public saveAppliedVoucherClient(appliedVoucherClientModel: AppliedVoucherClientModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public saveAppliedVoucherClient(appliedVoucherClientModel: AppliedVoucherClientModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (appliedVoucherClientModel === null || appliedVoucherClientModel === undefined) {
      throw new Error('Required parameter appliedVoucherClientModel was null or undefined.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post(`${this.basePath}/voucher/save`,
      appliedVoucherClientModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getAppliedVoucherClient(productOrderId:string, paymentProvider:string,productId: string, observe?: 'body', reportProgress?: boolean): Observable<AppliedVoucherClientModel>;
  public getAppliedVoucherClient(productOrderId:string, paymentProvider:string,productId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppliedVoucherClientModel>>;
  public getAppliedVoucherClient(productOrderId:string, paymentProvider:string,productId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppliedVoucherClientModel>>;
  public getAppliedVoucherClient(productOrderId:string, paymentProvider:string,productId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (productOrderId !== undefined) {
      queryParameters = queryParameters.set('productOrderId', <any>productOrderId);
    }

    if (paymentProvider !== undefined) {
      queryParameters = queryParameters.set('paymentProvider', <any>paymentProvider);
    }

    if (productId !== undefined) {
      queryParameters = queryParameters.set('productId', <any>productId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get(`${this.basePath}/voucher/get`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getProductIdFromLastPayment
   *
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductIdFromLastPayment( observe?: 'body', reportProgress?: boolean): Observable<string>;
  public getProductIdFromLastPayment( observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public getProductIdFromLastPayment( observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public getProductIdFromLastPayment( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<boolean>(`${this.basePath}/payment/get-last-product`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
