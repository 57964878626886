<div class="address-target-success-container">
  <div class="close-dialog-button" *ngIf="modalModel.shouldCloseAutomatically==false" (click)="onCloseBtnClick()">
    <img style="width: 27px; height: 27px"
         alt="close-button"
         (mouseenter)="exitHovered = true;"
         (mouseleave)="exitHovered = false;"
         [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
  </div>
       <div class="address-target-success-title">
         <h2 class="header" *ngIf="modalModel.title1!=null" [innerHTML]="modalModel.title1"></h2>
         <h2 class="header" *ngIf="modalModel.title2!=null" >{{modalModel.title2}}</h2>
       </div>
       <p *ngIf="modalModel.body!=null" class="copy-text address-target-success-body">{{modalModel.body}}</p>
       <div class="row address-target-success-button-container">
         <button id="addressTargetCopyButton" class="imd-button close-letter-btn copy-text-btn" (click)="copyMessage(modalModel.copyText)" *ngIf="modalModel.copyText != null">{{modalModel.copyButton}}</button>
       </div>
     </div>
