<div class="dialog-container">
  <div class="logo-container">
    <img alt="logo-image" style="visibility: hidden" class="logo-image" src="../../../assets/images/logo.png">
    <img (click)="onCloseBtnClick()" class="close-button-image"
         (mouseenter)="exitHovered = true;"
         (mouseleave)="exitHovered = false;"
         alt="back-button"
         style="width: 27px; height: 27px;"
         [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
  </div>
  <hr class="line-break">
  <div class="dialog-content">

    <div>
      <label class="label-text" id="radio-checkbox">{{translate.instant('OLD_ADDRESS_POPUP.QUESTION')}}
        <span class="fas fa-info-circle info-icon" (click)="infoClicked = !infoClicked">
        </span>
      </label>
      <p [@openCloseInfo]="infoClicked ? 'visible': 'invisible'">{{translate.instant('OLD_ADDRESS_POPUP.TOOLTIP_TEXT')}}</p>
      <mat-radio-group
        class="radio-group"
        aria-labelledby="radio-checkbox"
        [(ngModel)]="checkboxData">
        <mat-radio-button
          id="negativeRadio"
          [value]="'NEGATIVE'"
          [disableRipple]="true">
          {{translate.instant('OLD_ADDRESS_POPUP.NO')}}
        </mat-radio-button>
        <mat-radio-button
          style="margin-left: 3%"
          id="positiveRadio"
          [value]="'POSITIVE'"
          [disableRipple]="true">
          {{translate.instant('OLD_ADDRESS_POPUP.YES')}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div [@openClose]="checkboxData === 'POSITIVE' ? 'visible' : 'invisible'">
      <form [formGroup]="addressFormData">
        <div class="street-div">
          <input id="streetInput" class="form-control base-input street-input" type="text" name="streetId" [placeholder]="translate.instant('OLD_ADDRESS_POPUP.STREET')"
                 formControlName="streetId">
          <input id="streetNumberInput" class="form-control base-input street-number-input" type="text" name="streetNumberId" [placeholder]="translate.instant('OLD_ADDRESS_POPUP.STREET_NUMBER')"
                 formControlName="streetNumberId">
        </div>
        <div class="postal-code-div">
          <input id="postalCodeInput" class="form-control base-input postal-code-input" type="text" name="postalCodeId" [placeholder]="translate.instant('OLD_ADDRESS_POPUP.POSTAL_CODE')"
                 formControlName="postalCodeId">
          <input id="cityInput" class="form-control base-input city-input" type="text" name="cityId" [placeholder]="translate.instant('OLD_ADDRESS_POPUP.CITY')"
                 formControlName="cityId">
        </div>
        <select id="countrySelect" class="general-select" formControlName="countryId" name="countryId">
          <option *ngFor="let country of countryArray" [ngValue]="country.label">{{country.label}}</option>
        </select>
      </form>
    </div>


  </div>

  <hr class="line-break">

  <mat-dialog-actions align="center">
    <div>
      <button id="sendButton" id="oldAddressSendButton" [disabled]="!((addressFormData.valid && checkboxData === 'POSITIVE') || (checkboxData === 'NEGATIVE'))" button (click)="sendButtonAction()"
              type="submit" class="close-letter-btn" >{{translate.instant('OLD_ADDRESS_POPUP.SEND')}}</button>
    </div>
  </mat-dialog-actions>
</div>

