<div class="dialog-container">
    <div class="close-dialog-button" *ngIf="modalModel.closeAction==true" (click)="onCloseBtnClick()">
          <img (mouseenter)="exitHovered = true;"
               (mouseleave)="exitHovered = false;"
               alt="back-button"
               style="width: 27px; height: 27px;"
               [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
    </div>
    <div class="dialog-content">
            <h2 class="header-primary" *ngIf="modalModel.title!=null">{{modalModel.title}}</h2>
            <h2 class="header-primary" *ngIf="modalModel.extendedTitle!=null" [innerHTML]="getExtendedTitle()"></h2>
            <p class="modal-body-content copy-text-primary" *ngIf="modalModel.content!=null && this.modalModel.urlLink==null" [innerHtml]="modalModel.content"> {{modalModel.content}} </p>
            <span class="modal-body-content copy-text-primary" *ngIf="modalModel.content!=null && this.modalModel.urlLink!=null" [innerHtml]="modalModel.content"> {{modalModel.content}} </span>
            <span (click)="hyperClick()" class="modal-body-content copy-text-primary hypertext">{{modalModel.clickableContent}}</span>
            <div class="modal-alignament" *ngIf="modalModel.input!=null" style="margin-bottom: 30px">

                <div *ngIf="modalModel.type == 'SuggestionModal'">
                     <input id="generalInput" type="text" class="imd-input-primary" [placeholder]="modalModel.input"  matInput [formControl]="myControl" (keyup)="updated()" [(ngModel)]="inputValue">
                </div>

                <div *ngIf="modalModel.type != 'SuggestionModal' ">
                        <input id="generalInput2" [(ngModel)]="inputValue" (input)="onTextChange()" [type]="'text'" class="imd-input-primary" [placeholder]="modalModel.input">
                </div>
             </div>

            <div class="modal-alignament" *ngIf="modalModel.action!=null">
                  <button id="actionButton" type="submit" [disabled]="disabledStatus" class="imd-button-secondary" (click)="onButtonAction($event)">{{modalModel.action}}</button>
           </div>
           <div class="modal-alignament" *ngIf="modalModel.attachments!=null">
                 <div *ngFor="let attachment of modalModel.attachments" class="attachment-container">
                        <button id="downloadButton" type="submit" class="imd-button-secondary" (click)="onAttachmentDownload(attachment)">{{attachment.name}}</button>
                 </div>

            </div>


    </div>
  <mat-dialog-actions *ngIf="modalModel.footerAction!=null" align="center">

            <div>
                    <button id="actionButton2" button type="submit" class="imd-button-secondary" (click)="onButtonAction($event)">{{modalModel.footerAction}}</button>
            </div>
    </mat-dialog-actions>
</div>

