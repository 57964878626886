/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttachmentModel } from './attachmentModel';
import { CustomerNumberModel } from './customerNumberModel';
import {ClientEmail} from "./clientEmail";


export interface ClientModel {
  activationCode?: string;
  activationCodeDate?: string;
  attachmentModel?: AttachmentModel;
  birthday?: string;
  city?: string;
  country?: string;
  customerNumberList?: Array<CustomerNumberModel>;
  dontAskAgainMietermappe?: boolean;
  email?: string;
  emailList?: Array<ClientEmail>;
  externalSourceProductTypePayment?: any;
  externalSourceProductName?: string;
  firstLogin?: boolean;
  firstName?: string;
  gender?: number;
  id?: string;
  imageUrl?: string;
  itsMyDataEmail?: string;
  language?: string;
  lastLoginDate?: string;
  lastName?: string;
  loginType?: ClientModel.LoginTypeEnum;
  memberSince?: Date;
  newsLetterStatus?: boolean;
  password?: string;
  phoneNumber?: string;
  postalCode?: string;
  securityLevel?: ClientModel.SecurityLevelEnum;
  status?: ClientModel.StatusEnum;
  street?: string;
  streetNumber?: string;
  verifiedBirthday?: boolean;
  verifiedEmail?: boolean;
  verifiedPhoneNumber?: boolean;
  driversLicenseImage?: Array<AttachmentModel>;
  signatureImage?: AttachmentModel;
  isIdentified?: boolean;
  oldStreet?: string;
  oldStreetNumber?: string;
  oldCity?: string;
  oldPostalCode?: string;
  oldCountry?: string;
  hasOldAddress?: boolean
  hasSeenOldAddressDialog?: boolean;
  thirdPartyIdFlag?: boolean;
  thirdPartyRefCode?: string;
  isUsing2FA?:boolean;
  recommendationCode?: string;
  setHasPaidDigitalSelfrequest?: boolean;
  instantBoniversumData?: boolean;
  certificatePrice?: string;
  chargebackFlag?: boolean;
  discountFlag?: string;
  idEmailSent?: Date;
  dataRequestEmailSent?: Date;
  agreementIdentifier?: string;
  hasDeletedData?: boolean;
  hasShownDeletePopup?: boolean;
  sofortauskunftLPrice?: string;
  sofortauskunftMPrice?: string;
  sofortauskunftWUCPrice?: string;
  sofortauskunftSPrice?: string;
  identificationSource?: string;
  paidWUCsCount?: number;
  identificationProcessStartingDate?: Date;

}
export namespace ClientModel {
  export type LoginTypeEnum = 'PLUGIN' | 'PORTAL';
  export const LoginTypeEnum = {
    PLUGIN: 'PLUGIN' as LoginTypeEnum,
    PORTAL: 'PORTAL' as LoginTypeEnum
  }
  export type SecurityLevelEnum = 'BASIC' | 'TWO_FACTOR' | 'VERIFY_IDENTITY';
  export const SecurityLevelEnum = {
    BASIC: 'BASIC' as SecurityLevelEnum,
    TWOFACTOR: 'TWO_FACTOR' as SecurityLevelEnum,
    VERIFYIDENTITY: 'VERIFY_IDENTITY' as SecurityLevelEnum
  }
  export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'BLOCKED' | 'DELETED';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    INACTIVE: 'INACTIVE' as StatusEnum,
    BLOCKED: 'BLOCKED' as StatusEnum,
    DELETED: 'DELETED' as StatusEnum
  }
}
