<div class="dialog-container">
    <div class="close-dialog-button" *ngIf="modalModel.closeAction==true" (click)="onCloseBtnClick()">
          <img (mouseenter)="exitHovered = true;"
               (mouseleave)="exitHovered = false;"
               alt="back-button"
               style="width: 27px; height: 27px;"
               [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
    </div>
    <div class="dialog-content">
            <h2 class="header-primary" *ngIf="modalModel.title!=null">{{modalModel.title}}</h2>
            <h2 class="header-primary" *ngIf="modalModel.extendedTitle!=null">{{modalModel.extendedTitle}}</h2>
            <p class="modal-body-content copy-text-primary" *ngIf="modalModel.content!=null" [innerHtml]="modalModel.content"> {{modalModel.content}} </p>
            <div class="modal-alignament" *ngIf="modalModel.input!=null">
              <div id="codeConfirmInput" style="margin-bottom: 10px;">
                  <input [(ngModel)]="inputValue" (input)="onTextChange($event)" [type]="'text'" class="imd-input-primary" [placeholder]="modalModel.input"></div>
             </div>
      <p *ngIf="showResendCodeMessage" class="copy-text color-green">{{translate.instant('CONFIRM_SMS_CODE.RESEND_CODE_MESSAGE',{phoneNumber: modalModel.phoneNumber})}}</p>
      <p *ngIf="errorVisibility" class="error-text copy-text" style="color: red;">{{errorMessage}}</p>

            <div class="modal-alignament button-container" *ngIf="modalModel.action!=null" style="margin-top: 20px;">
                  <button type="submit" [disabled]="!(!(smsCode === '') || !resendSMSCodeDisabled)" class="imd-button-secondary" (click)="onResendButtonAction()">{{translate.instant('CONFIRM_SMS_CODE.RESEND_ACTION')}}</button>
                  <button type="submit" [disabled]="disabledStatus" class="imd-button-secondary" (click)="onButtonAction($event)">{{modalModel.action}}</button>
           </div>



    </div>
    <mat-dialog-actions align="center">


    </mat-dialog-actions>
</div>

