/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PreviewLetterDetailsModel } from '../model/previewLetterDetailsModel';
import { RequestDataModel } from '../model/requestDataModel';
import { ResponseEntity } from '../model/responseEntity';
import { UserTargetModel } from '../model/userTargetModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {DigitalSelfrequest, LetterModel} from '../model/models';


@Injectable()
export class UserTargetControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * addUserTarget
   *
   * @param requestDataModel requestDataModel
   * @param emailId emailId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addUserTargetUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe?: 'body', reportProgress?: boolean): Observable<UserTargetModel>;
  public addUserTargetUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserTargetModel>>;
  public addUserTargetUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserTargetModel>>;
  public addUserTargetUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (requestDataModel === null || requestDataModel === undefined) {
      throw new Error('Required parameter requestDataModel was null or undefined when calling addUserTargetUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (emailId !== undefined) {
      queryParameters = queryParameters.set('emailId', <any>emailId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<UserTargetModel>(`${this.basePath}/user/target`,
      requestDataModel,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * addPluginUserTarget
   *
   * @param targetId targetId
   * @param file file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addPluginUserTargetUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public addPluginUserTargetUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public addPluginUserTargetUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public addPluginUserTargetUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling addPluginUserTargetUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    if (file !== undefined) {
      // @ts-ignore
      formParams = formParams.append('file', <any>file) || formParams;
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<ResponseEntity>(`${this.basePath}/user/pluginTarget`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

   /**
     * shouldRequestBoniverumApiData
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shouldRequestBoniverumApiDataUsingGET(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public shouldRequestBoniverumApiDataUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public shouldRequestBoniverumApiDataUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public shouldRequestBoniverumApiDataUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.get<boolean>(`${this.basePath}/shouldRequestBoniverumApiData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

   /**
     * checkBoniversumApiDataStatus
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkBoniversumApiDataStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkBoniversumApiDataStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkBoniversumApiDataStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkBoniversumApiDataStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<boolean>(`${this.basePath}/checkBoniversumApiDataStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * checkPluginStatus
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkPluginStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<string> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get(`${this.basePath}/checkPluginStatus`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'text',
        reportProgress: reportProgress
      });
  }

  /**
   * getTwitterRequestToken
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTwitterRequestTokenUsingGET(observe?: 'body', reportProgress?: boolean): Observable<string> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.get(`${this.basePath}/startTwitterAuthentication`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'text',
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getUserTarget
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserTargetUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<UserTargetModel>>;
  public getUserTargetUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserTargetModel>>>;
  public getUserTargetUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserTargetModel>>>;
  public getUserTargetUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    return this.httpClient.get<Array<UserTargetModel>>(`${this.basePath}/user/target`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * requestBoniversumApiData
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestBoniversumApiDataUsingGET(digitalSelfRequestType: DigitalSelfrequest.DigitalSelfrequestTypeEnum,observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public requestBoniversumApiDataUsingGET(digitalSelfRequestType: DigitalSelfrequest.DigitalSelfrequestTypeEnum,observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public requestBoniversumApiDataUsingGET(digitalSelfRequestType: DigitalSelfrequest.DigitalSelfrequestTypeEnum,observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public requestBoniversumApiDataUsingGET(digitalSelfRequestType: DigitalSelfrequest.DigitalSelfrequestTypeEnum,observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (digitalSelfRequestType !== undefined) {
      queryParameters = queryParameters.set('digitalSelfRequestType', <any>digitalSelfRequestType);
    }
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
   return this.httpClient.get<ResponseEntity>(`${this.basePath}/requestBoniversumApiData`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * previewLetter
   *
   * @param previewLetterDetailsModel previewLetterDetailsModel
   * @param emailId emailId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public previewLetterUsingPUT(previewLetterDetailsModel: PreviewLetterDetailsModel, emailId?: string, observe?: 'body', reportProgress?: boolean): Observable<LetterModel>;
  public previewLetterUsingPUT(previewLetterDetailsModel: PreviewLetterDetailsModel, emailId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LetterModel>>;
  public previewLetterUsingPUT(previewLetterDetailsModel: PreviewLetterDetailsModel, emailId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LetterModel>>;
  public previewLetterUsingPUT(previewLetterDetailsModel: PreviewLetterDetailsModel, emailId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (previewLetterDetailsModel === null || previewLetterDetailsModel === undefined) {
      throw new Error('Required parameter previewLetterDetailsModel was null or undefined when calling previewLetterUsingPUT.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (emailId !== undefined) {
      queryParameters = queryParameters.set('emailId', <any>emailId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    return this.httpClient.put<LetterModel>(`${this.basePath}/previewLetter`,
      previewLetterDetailsModel,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * sendCorrectionLetter
   *
   * @param previewLetterDetailsModel previewLetterDetailsModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendCorrectionLetterUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public sendCorrectionLetterUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public sendCorrectionLetterUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public sendCorrectionLetterUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (previewLetterDetailsModel === null || previewLetterDetailsModel === undefined) {
      throw new Error('Required parameter previewLetterDetailsModel was null or undefined when calling sendCorrectionLetterUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    return this.httpClient.post<ResponseEntity>(`${this.basePath}/sendCorrectionLetter`,
      previewLetterDetailsModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sendDeletionConfirmation
   *
   * @param previewLetterDetailsModel previewLetterDetailsModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendDeletionConfirmationUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public sendDeletionConfirmationUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public sendDeletionConfirmationUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public sendDeletionConfirmationUsingPOST(previewLetterDetailsModel: PreviewLetterDetailsModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (previewLetterDetailsModel === null || previewLetterDetailsModel === undefined) {
      throw new Error('Required parameter previewLetterDetailsModel was null or undefined when calling sendDeletionConfirmationUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);

    return this.httpClient.post<ResponseEntity>(`${this.basePath}/sendDeletionConfirmation`,
      previewLetterDetailsModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sendDeletionLetter
   *
   * @param code code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendDeletionLetterUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<string>{
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling sendDeletionLetterUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    return this.httpClient.get(`${this.basePath}/sendDeletionLetter/${encodeURIComponent(String(code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'text',
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sendReminderLetter
   *
   * @param requestDataModel requestDataModel
   * @param emailId emailId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendReminderLetterUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe?: 'body', reportProgress?: boolean): Observable<UserTargetModel>;
  public sendReminderLetterUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserTargetModel>>;
  public sendReminderLetterUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserTargetModel>>;
  public sendReminderLetterUsingPOST(requestDataModel: RequestDataModel, emailId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (requestDataModel === null || requestDataModel === undefined) {
      throw new Error('Required parameter requestDataModel was null or undefined when calling sendReminderLetterUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (emailId !== undefined) {
      queryParameters = queryParameters.set('emailId', <any>emailId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<UserTargetModel>(`${this.basePath}/sendReminderLetter`,
      requestDataModel,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * targetRequestCompleted
   *
   * @param targetId targetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public targetRequestCompletedUsingPUT(targetId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public targetRequestCompletedUsingPUT(targetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public targetRequestCompletedUsingPUT(targetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public targetRequestCompletedUsingPUT(targetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (targetId === null || targetId === undefined) {
      throw new Error('Required parameter targetId was null or undefined when calling targetRequestCompletedUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.put<ResponseEntity>(`${this.basePath}/targetRequestCompleted`,
      targetId,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
