import {Injectable} from '@angular/core';
import {
  CertificateControllerService,
  CommunicationControllerService, CommunicationModel,
  DigitalSelfRequestControllerService,
  LetterModel,
  UserTargetControllerService
} from "../api-models";
import {LoadingSpinnerService} from "../components/spinner/spinner.service";
import {Router} from "@angular/router";
import {UserDataService} from "./user-data.service";
import {
  AddressTargetSuccessModal, CloseNextAction,
  FilePreviewModal,
  LetterCloseResult,
  LetterModal, Modal,
  PopupDimensions, SaveSuccessModal
} from "../models/modal.model";
import {StringHelper} from "../helpers/string-helper";
import {ViewLetterDialog} from "../../dialogs/view-letter/view-letter.dialog";
import {DateFormatter} from "../helpers/date-formatter";
import {FileHelper} from "../helpers/file-helper";
import {PreviewFilePopup} from "../../dialogs/preview-file-popup/preview-file-popup";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {AddressTargetSuccess} from "../../dialogs/address-target-success/address-target-success";
import {NewGeneralPopup} from "../../dialogs/new-general-popup/new-general-popup";
import {SendTargetRequestService} from "./send-target-request.service";
import {GeneralDialog} from "../../dialogs/general.dialog";
import {SaveSuccess} from "../../dialogs/save-success/save-success";
import {DatePipe} from "@angular/common";
import {any} from "codelyzer/util/function";

@Injectable()
export class ViewLetterService {
  constructor(private digitalSelfRequestService: DigitalSelfRequestControllerService,
              private certificateService: CertificateControllerService,
              private userTargetService: UserTargetControllerService,
              protected sendTargerRequestService:SendTargetRequestService,
              private router: Router,
              public userDataService: UserDataService,
              protected communicationService: CommunicationControllerService,
              public translateService: TranslateService,
              protected matDialog: MatDialog,
              protected datePipe: DatePipe,
              private spinnerService: LoadingSpinnerService) {
  }

  isLoadingLetter: boolean = false;

  showViewLetterForCommunicationId(communicationId, callback: (closeResult:LetterCloseResult) => void) {
    if (!this.isLoadingLetter) {
      this.isLoadingLetter = true;
      this.spinnerService.show();
      this.communicationService.getRequestLetterUsingGET(communicationId)
        .subscribe(
          serverLetterModel => {
            this.spinnerService.hide();
            var letterModel : LetterModal = this.convertServerLetterToLetterModal(serverLetterModel);
            if(serverLetterModel.attatchments.length === 1 && (serverLetterModel.letterBody === undefined || serverLetterModel.letterBody.trim() === "") &&
              StringHelper.getFilePreviewType(serverLetterModel.attatchments[0].type) && !serverLetterModel.allowDeletion && !window.matchMedia('(max-width:500px)').matches){
              this.openPreviewDialog(serverLetterModel.attatchments[0], (previewCallback) => {
                this.isLoadingLetter = false;
                callback(previewCallback);
              });
            }else{
              let letterDialog = this.matDialog.open(ViewLetterDialog, {
                width: this.getLetterDimensions(letterModel).width,
                height: this.getLetterDimensions(letterModel).height,
                panelClass: 'letter-mobile',
                data: {
                  metadata: letterModel
                }
              })
              letterDialog.afterOpened().subscribe(openResult=>{
                if(letterModel.actionSubType!=null){
                  this.showOpenInformationPopupByActionSubtype(letterModel.actionSubType)
                }
              })
              letterDialog.afterClosed().subscribe(closeResult => {
                this.isLoadingLetter = false;
                if(letterModel.actionSubType!=null){
                  this.showCloseInformationPopupByActionSubtype(letterModel.actionSubType,letterModel);
                }
                this.handleCloseResultAction(closeResult);
                callback(closeResult);
              });
            }
          },
          error => {
            this.isLoadingLetter = false;
            this.spinnerService.hide();
            //throw error;
          }
        )
    }
  }


  convertServerLetterToLetterModal(serverLetterModel:LetterModel):LetterModal{
    let letterModel: LetterModal = new LetterModal();
    letterModel.cancelAction = true;
    if(serverLetterModel.date!=null){
      letterModel.date = DateFormatter.simpleStringToLocalDateString(serverLetterModel.date, this.datePipe);
    }

    if(serverLetterModel.letterBody!=null){
      letterModel.htmlContent = serverLetterModel.letterBody;
    }
    letterModel.attachments = serverLetterModel.attatchments;
    letterModel.communicationId= serverLetterModel.communicationId;
    letterModel.targetId = serverLetterModel.targetId;
    if(serverLetterModel.allowDeletion!=null){
      letterModel.allowDeletion = serverLetterModel.allowDeletion;
    }
    letterModel.targetName = serverLetterModel.targetName;
    letterModel.actionSubType = serverLetterModel.communicationActionSubtype
    if (serverLetterModel.letterSubject != null) {
      letterModel.subject = serverLetterModel.letterSubject;
    }
    if(serverLetterModel.targetStatus == 'DELIVERED_BY_UPLOAD'){
      letterModel.subject = this.translateService.instant("COMMUNICATIONS.DATA_UPLOADED",{targetName: serverLetterModel.targetName});
    }else if (serverLetterModel.targetStatus=="DELIVERED"){
      letterModel.subject = this.translateService.instant("COMMUNICATIONS.DATA_RECEIVED",{targetName: serverLetterModel.targetName});
    } else if (serverLetterModel.communicationAction == CommunicationModel.ActionEnum.SHAREDCERTIFICATEONEMAIL) {
      letterModel.subject = this.translateService.instant("CERTIFICATE_SHARED_HISTORY.TITLE");
    }
    if( serverLetterModel.communicationAction!=null){
      letterModel.actionType = serverLetterModel.communicationAction;
    }
    if(letterModel.actionType == null){
      letterModel.actionType = "VIEW_UPLOADED_DATA_FROM_TARGETS" as any;
    }
    letterModel.errorType = serverLetterModel.parsingErrorType;
    letterModel.previewLetterType = serverLetterModel.previewLetterType;
    letterModel.targetStatus = serverLetterModel.targetStatus;

    return letterModel;
  }

  openPreviewDialog(attatchment:any, callback: (closeResult: any) => void) {
    let blob = attatchment.blobContent;
    if (!blob) {
      blob = FileHelper.b64toBlob(attatchment.content, attatchment.type);
    }
    let modalModel:FilePreviewModal = {
      targetName: '',
      file:blob,
      fileName: attatchment.name,
      buttonText: this.translateService.instant('PREVIEW_FILE_POPUP.CLOSE'),
      downloadButton: this.translateService.instant('PREVIEW_FILE_POPUP.DOWNLOAD'),
      content: attatchment.name
    };
    let dialog = this.matDialog.open(PreviewFilePopup,{
      width:'60%',
      height: '80%',
      panelClass:'new-modal-container',
      data:{metadata:modalModel}
    })
    dialog.afterClosed().subscribe(res => {
      callback('');
    })
  }


  getLetterDimensions(letterModal:LetterModal):PopupDimensions{
    let width;
    let height;
    if (letterModal.htmlContent != null) {
      height = 90;
    } else {
      var baseHeight = 30;
      if(letterModal.attachments!=undefined){
        var rowNumber = Math.ceil(letterModal.attachments.length / 3);
        height = baseHeight + rowNumber * 8;
        height = height > 90 ? 90 : height < 50 ? 50 : height;
      }

    }

    if (window.matchMedia('(min-height:320px)').matches && window.matchMedia('(max-height:500px)').matches &&
      window.matchMedia('(orientation: landscape)')) {
      width = 90;
    } else {
      width = 60;
    }
    var dimensions = new PopupDimensions();
    dimensions.width = width.toString()+'%';
    if (height != undefined) {
      dimensions.height = height.toString() + '%';
    }

    return dimensions;

  }

  public showOpenInformationPopupByActionSubtype(actionSubtype: CommunicationModel.ActionSubTypeEnum){
    if(actionSubtype === CommunicationModel.ActionSubTypeEnum.NOACTIONREQUIRED){
      this.showNoActionRequiredInfoModal();
    }
  }

  public showCloseInformationPopupByActionSubtype(actionSubtype: CommunicationModel.ActionSubTypeEnum, letterModal:LetterModal){
    switch (actionSubtype){
      case CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONDATA:
        this.showRenewDataRequestModal();
        break;
      case CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONSIGNATURE:
        this.showRenewDataRequestModal();
        break;
      case CommunicationModel.ActionSubTypeEnum.INCORRECTIDENTIFICATIONDATA:
        this.showRenewDataRequestModal();
        break;
      case CommunicationModel.ActionSubTypeEnum.DATASENDBYPOST:
        this.showDataSentByPostInfoModal();
        break;
      case CommunicationModel.ActionSubTypeEnum.FORWARDEMAILTOIMD:
        this.sendTargerRequestService.openForwardEmailToImdPopup(letterModal.targetName);
        break;
      case CommunicationModel.ActionSubTypeEnum.INCORRECTID:
        this.showDataSentByPostInfoModal();
        break;
    }
  }


  private showNoActionRequiredInfoModal(){
    let modalTitle1 =  "";
    let modalTitle2 =  this.translateService.instant('COMMUNICATIONS.NO_ACTION_REQUIRED_MODAL_TITLE');
    let modalBody =  "";
    let modalModel: AddressTargetSuccessModal = {
      title1: modalTitle1,
      title2: modalTitle2,
      body: modalBody,
      action: "",
      shouldCloseAutomatically: true
    }
    this.matDialog.open(AddressTargetSuccess, {
      width: '550px',
      data: {metadata: modalModel}
    });
  }


  private showRenewDataRequestModal(){
    let modalTitle1 =  "";
    let modalTitle2 =  this.translateService.instant('COMMUNICATIONS.RENEW_DATA_REQUEST');
    let modalBody =  "";
    let modalModel: AddressTargetSuccessModal = {
      title1: modalTitle1,
      title2: modalTitle2,
      body: modalBody,
      action: "",
      shouldCloseAutomatically: true
    }
    this.matDialog.open(AddressTargetSuccess, {
      width: '550px',
      data: {metadata: modalModel}
    });
  }

  showDataSentByPostInfoModal(){
    let modalModel = {
      targetName: '',
      imdEmail: '',
      from: 'ADDRESS_POPUP',
      data: {
        content: this.translateService.instant('COMMUNICATIONS.DATA_SENT_BY_POST_MODAL_TITLE'),
        buttonText: 'OK',
        showIcon: false
      }
    };
    this.matDialog.open(NewGeneralPopup,{
      width:'550px',
      panelClass:'new-modal-container',
      data:{metadata:modalModel}
    })
  }

  public handleCloseResultAction(closeResult: LetterCloseResult):void{
    switch (closeResult.action) {
      case CloseNextAction.NAVIGATE_TO_MY_DATA:
        this.router.navigate(['']);
        break;
      case CloseNextAction.OPEN_REQUEST_LETTER:
        this.sendTargerRequestService.sendRequest(closeResult.letterModal).subscribe((response: any) => {
          if(closeResult.letterModal.communicationId != null){
            this.setUploadButtonStatus("CORRECTED", closeResult.letterModal.communicationId);
          }

        });
        break;
      case CloseNextAction.DECRYPT_DATA:
        if(closeResult.letterModal.communicationId != null) {
          this.decryptAttachmentForCommunicationId(closeResult.letterModal.communicationId, (response) => {
          });
        }
        break;
    }
  }

  private setUploadButtonStatus(status: string, communicationId: string):void {
    this.communicationService.saveUploadButtonStatusUsingPOST(status,communicationId).subscribe(res =>{
      console.log("RESPONSE: ", res);
      // this.getcommunication();
    },error =>{
      console.log("RESPONSE: ", error);
    });
  }

  decryptAttachmentForCommunicationId(communicationId: string,callback: (response: any) => void){
    let responseDialogTitle = "";
    let modalModel2: Modal = {
      closeAction: true,
      extendedTitle: '',
      title: this.translateService.instant("DECRYPT_FILE_MODAL.TITLE"),
      content: this.translateService.instant("DECRYPT_FILE_MODAL.CONTENT"),
      input: this.translateService.instant("DECRYPT_FILE_MODAL.INPUT"),
      action:this.translateService.instant("DECRYPT_FILE_MODAL.ACTION"),
      attachments: [],
      footerAction: '',
      type: ''
    }
    let dialog = this.matDialog.open(GeneralDialog, {
      width: '522px',
      data: { metadata: modalModel2 }
    });
    dialog.afterClosed().subscribe(result => {
      console.log("RESULT: ", result);
      if (result && result["data"]) {
        this.communicationService.decryptCommunicationAttachmentsUsingPOST(communicationId, result["data"]).subscribe( res => {
          console.log("DECRYPTION RESPONSE: ",res);
          if(res == "SUCCESS"){
            responseDialogTitle = this.translateService.instant('DECRYPT_FILE_DIALOG.DECRYPT_FILE_SUCCESS');
            // this.dialogRef.close({metadata: res});??
          }
          else if(res == "WRONG_PASSWORD"){
            responseDialogTitle = this.translateService.instant('DECRYPT_FILE_DIALOG.DECRYPT_FILE_WRONG_PASSWORD');
          }
          else if(res == "ERROR"){
            responseDialogTitle = this.translateService.instant('DECRYPT_FILE_DIALOG.DECRYPT_FILE_ERROR');
          }
          this.openResponseDialog(responseDialogTitle);

          callback(res);
        }, error => {
          if (error.error.includes('CLIENT_NOT_FOUND')) {
            responseDialogTitle = "Client not found";
          }
          else if (error.error.includes('ATTACHMENT_NOT_FOUND')) {
            responseDialogTitle = "Attachment not found";
          }
          else if (error.error.includes('COMMUNICATION_NOT_FOUND')) {
            responseDialogTitle = "Communication not found";
          }
          else if (error.error.includes('TARGET_NOT_FOUND')) {
            responseDialogTitle = "Target not found";
          }
          else {
            responseDialogTitle = this.translateService.instant('DECRYPT_FILE_DIALOG.DECRYPT_FILE_ERROR');
          }
          this.openResponseDialog(responseDialogTitle);
          callback(error);
        })
      }
    });
  }

  openCertificateInPDFViewer(isBrief: boolean) {
    this.isLoadingLetter = true;
    this.spinnerService.show();
    let observable = this.certificateService.generateCertificateUsingGET();
    if (isBrief) {
      observable = this.digitalSelfRequestService.generateBriefCertificateUsingPOST(true, 'certificate');
    }

    observable.subscribe(res => {
      let certificateName;
      if (isBrief) {
        certificateName = this.userDataService.getBriefName();
      } else {
        certificateName = this.userDataService.getCertificateName();
      }

      let attachment = {
        name: certificateName,
        blobContent: res
      }
      this.isLoadingLetter = false;
      this.spinnerService.hide();
      this.openPreviewDialog(attachment, () => {
      });
    }, err => {
      this.isLoadingLetter = false;
      this.spinnerService.hide();
      let reader = new FileReader();
      reader.onerror = (e) => {
        console.log(e);
      };
      reader.readAsText(err.error);
    });
  }

  private openResponseDialog(confirmationModal: string){
    let modalModel: SaveSuccessModal = {
      title: confirmationModal,
      action: ""
    }
    this.matDialog.open(SaveSuccess, {
      width: '400px',
      data: {metadata: modalModel}
    });
  }

}
