import {Component, ElementRef, Inject, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommunicationControllerService} from '../../shared/api-models/api/communicationController.service';
import * as FileSaver from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {DownloadService} from '../../shared/services/download.service';
import {
  CloseNextAction, FilePreviewModal,
  LetterCloseResult,
  LetterModal,
} from '../../shared/models/modal.model';
import {TranslateService} from "@ngx-translate/core";
import {
  CommunicationModel,
  LetterModel,
  ResponseEntity,
  UserTargetControllerService,
  UserTargetModel
} from '../../shared/api-models';
import {PreviewLetterDetailsModel} from '../../shared/api-models/model/previewLetterDetailsModel';
import {DatePipe} from '@angular/common';
import {Router} from "@angular/router";
import {TrackingService} from "../../shared/services/tracking.service";
import {PreviewFilePopup} from "../preview-file-popup/preview-file-popup";
import { FileHelper } from "../../shared/helpers/file-helper";
import {StringHelper} from "../../shared/helpers/string-helper";
import {LoadingSpinnerService} from "../../shared/components/spinner/spinner.service";

declare var $: any;

@Component({
    selector: 'view-letter-dialog',
    templateUrl: 'view-letter.dialog.html',
    styleUrls: ['./view-letter.dialog.scss'],
    providers: [UserTargetControllerService]
  })

export class ViewLetterDialog {

  modalModel: LetterModal;
  exitHovered = false;

  // isSendBtnVisible: boolean = false;

  letterDate: string = "";
  letterType: LetterModel.PreviewLetterType;
  letterSubject: string = "";
  sendLabel: string;
  showDataLabel: string;
  showCorrectDataLabel: string;
  renewDataRequestLabel: string;
  sendReplyLetterLabel: string;

  showDecryptDataLabel: string;
  cancelLabel: string;
  closeLabel: string;
  editLabel: string;
  customActionLabel: string = "";
  linkTitleLabel: string;
  targetId: string;
  inputValue: string;
  targetName: string;
  sendCallType: string;
  inputValueComplement: string;
  missingInformationLabel: string = "";
  targetSelectedSubCompanies;
  isDataLoading:boolean = false;
  receiverEmail: String = "";
  @ViewChild('dynamicElement', { static: true }) dynamicElement: ElementRef;
  constructor(
    private targetService: UserTargetControllerService,
    private communicationService: CommunicationControllerService,
    public dialogRef: MatDialogRef<ViewLetterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private downloadService: DownloadService,
    private httpClient: HttpClient,
    public translate: TranslateService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
    private tracker: TrackingService,
    private spinner: LoadingSpinnerService
    ) {}

  ngOnInit() {
       // - get data

       this.modalModel = this.data["metadata"];
       this.targetId = this.data['targetId'];
       this.inputValue = this.data['correctionText'];
       this.targetName = this.data['companyName'];
       this.sendCallType = this.data['sendCallType'];
       this.inputValueComplement = this.data['complementText'];
       this.targetSelectedSubCompanies = this.data['targetSubCompanies'];


        // - init labels
        this.initLabels();

        // - set data
        this.initLetterDialog();
        console.log("lettermodel: ", this.modalModel);

        if (this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONDATA || this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONSIGNATURE) {
          let missingDataText = this.translate.instant('INTERMEDIATE_INFO_RECEIVED.CORRECT_DATA_TEXT');
          this.missingInformationLabel = missingDataText.replace('<target_name>', this.modalModel.targetName);
          console.log("MISSING INFORMATION LABEL",this.missingInformationLabel);
        }
        this.temporaryDisableDeletion();
  }

  getReceiverEmail() {
    this.receiverEmail = document.getElementById("receiverEmail").innerHTML;
    this.letterSubject = this.letterSubject + this.receiverEmail;
  }

  temporaryDisableDeletion(){
    //Todo disable XING and N26 data uploaded deletion until parser is implemented
    if(this.modalModel.targetId == 'fbf6dd18-3e33-4705-b55e-e9de9e5f0e19' ||
      this.modalModel.targetId == '703ea0b4-b924-4ed4-ac8e-64e905a85144' ||
      this.modalModel.targetId == 'c73914f9-3cf8-4dca-b163-16863a9fdad6' ||
      this.modalModel.targetId == 'ca93e207-8824-4aae-b8a5-5f6ed7302c72' ){
      this.modalModel.allowDeletion = false;
    }
  }


  initLabels() {
    // - languages init
    this.showDataLabel = this.translate.instant('LETTER_DIALOG.SHOW_DATA');
    this.showCorrectDataLabel = this.translate.instant('TARGETS.PENDING.CORRECT_DATA');
    this.renewDataRequestLabel = this.translate.instant('LETTER_DIALOG.RENEW_DATA');
    this.sendReplyLetterLabel = this.translate.instant('LETTER_DIALOG.SEND_REPLY_LETTER');

    this.showDecryptDataLabel = this.translate.instant('LETTER_DIALOG.DECRYPT');
    this.sendLabel = this.translate.instant('LETTER_DIALOG.SEND');
    this.editLabel = this.translate.instant('LETTER_DIALOG.EDIT');
    this.cancelLabel = this.translate.instant('LETTER_DIALOG.CANCEL');
    this.closeLabel = this.translate.instant('LETTER_DIALOG.CLOSE');
    this.linkTitleLabel = this.translate.instant('LETTER_DIALOG.REQUEST_DATE_FAQ_LINK');

    this.translate.onLangChange.subscribe(trans => {
        var translations = trans.translations;
        this.sendLabel = translations.LETTER_DIALOG.SEND;
        this.editLabel = translations.LETTER_DIALOG.EDIT;
        this.cancelLabel = translations.LETTER_DIALOG.CANCEl;
        this.closeLabel = translations.LETTER_DIALOG.CLOSE;
        this.showDecryptDataLabel = translations.LETTER_DIALOG.DECRYPT;
    });
  }

  initLetterDialog(){
        this.letterDate = this.modalModel.date?this.modalModel.date:"";
        this.letterSubject = this.modalModel.subject?this.modalModel.subject:"";
        this.letterType = this.modalModel.previewLetterType;
        if(this.modalModel.cancelAction){
            this.customActionLabel = this.cancelLabel;
          if(this.modalModel.actionType == CommunicationModel.ActionEnum.SHAREDCERTIFICATEONEMAIL){
            this.customActionLabel = this.closeLabel;
          }

        }else if(this.modalModel.editAction){
            this.customActionLabel = this.editLabel;
        }

        // if(this.modalModel.sendAction){
        //     this.isSendBtnVisible = this.modalModel.sendAction;
        // }

        var emailHtml = "";
        if(this.modalModel.htmlContent){
            emailHtml = this.modalModel.htmlContent;
        }

        this.dynamicElement.nativeElement.innerHTML = emailHtml;

        this.getReceiverEmail();
  }

  checkForCertificateTargets(id){
    return (id == "dc29b2d1-c009-4377-84b1-a10eea4e3e79"
      || id == "dc28a7d1-c009-4377-84b1-a10eea4e3e79"
      || id == "dc227dc1-c009-4377-84b1-a10eea4e3e79"
      || id == "dc23c4d1-c009-4377-84b1-a10eea4e3e79")

  }

  onCloseBtnClick(): void {
     this.dialogRef.close({});
  }

  onSendAction($event){
    if(this.sendCallType === 'CHANGE') {
      this.sendLetter();
    } else if(this.sendCallType === 'DELETE'){
      this.eraseLetter();
    } else if(this.sendCallType === 'REPLY'){
      this.sendReplyLetter();
    }
    else{
          var data = {
              action: "show_success_dialog"
          }

          this.dialogRef.close(data);
    }
  }

  onButtonAction($event) {
      if (this.modalModel.editAction) {
          var data = {
                action: 'update_data',
                targetId: this.targetId,
                targetName: this.targetName,
                correctionText: this.inputValue,
                sendCallType: this.sendCallType,
                complementText: this.inputValueComplement,
                targetSubCompanies: this.targetSelectedSubCompanies
          }
          this.dialogRef.close(data);
      }
      else {
          this.dialogRef.close({});
      }

  }

  onDeleteAction(){
    this.isDataLoading = true;
    this.communicationService.deleteCommunicationUsingGET(this.modalModel.communicationId).subscribe(res => {
      this.isDataLoading = false;
      var closeResult = new LetterCloseResult();
      closeResult.action = CloseNextAction.CLOSE_AFTER_DELETE;
      this.dialogRef.close(closeResult);
    });
  }

  onDisplayInMyData($event) {
    var closeResult = new LetterCloseResult();
    closeResult.action = CloseNextAction.NAVIGATE_TO_MY_DATA;
    this.dialogRef.close(closeResult);
  }

  onCorrectDataAction($event){
    this.dialogRef.close({});
    this.router.navigate(["/dashboard/profile"]);
  }

  onRenewDataAction($event){
    var closeResult = new LetterCloseResult();
    closeResult.action = CloseNextAction.OPEN_REQUEST_LETTER;
    closeResult.letterModal = this.modalModel;
    this.dialogRef.close(closeResult);
  }

  onSendReplyLetterAction($event) {
    this.sendReplyLetter();
  }

  sendLetter(){
    const correctionModel: PreviewLetterDetailsModel = {
      targetId: this.targetId,
      correctionData: this.inputValue,
      type:'CORRECT',
      complementData: this.inputValueComplement,
      targetSubCompanies: this.targetSelectedSubCompanies
    }
    this.targetService.sendCorrectionLetterUsingPOST(correctionModel).subscribe(response => {
      const value: string = 'OK';
      if(response === value){
        this.tracker.trackEventWithCustomDimension(TrackingService.DATA_CATEGORY_EVENT, TrackingService.ACTION_CORRECTION, this.targetName, Date.now(), this.targetName);
        var data = {
          action: "show_success_dialog"
        }
        this.dialogRef.close(data);
      }else{
        var data = {
          action: "show_unsuccess_dialog"
        }
        this.dialogRef.close(data);
      }
    });
  }

  eraseLetter(){

    const correctionModel: PreviewLetterDetailsModel = {
      targetId: this.targetId,
      correctionData: this.inputValue,
      type:'DELETE',
      complementData: this.inputValueComplement,
      targetSubCompanies: this.targetSelectedSubCompanies
    }
    this.targetService.sendDeletionConfirmationUsingPOST(correctionModel).subscribe(response => {
      const value: string = 'OK';
      if(response === value){
        this.tracker.trackEventWithCustomDimension(TrackingService.DATA_CATEGORY_EVENT, TrackingService.ACTION_DELETION, this.targetName, Date.now(), this.targetName);
        var data = {
          action: "show_success_dialog"
        }
        this.dialogRef.close(data);
      }else{
        var data = {
          action: "show_unsuccess_dialog"
        }
        this.dialogRef.close(data);
      }
    });
  }

  sendReplyLetter(){
    this.communicationService.sendIntermediateInfoReplyLetterUsingGET(this.modalModel.communicationId).subscribe(response => {
      const value: string = 'OK';
      if(response === value){
        this.tracker.trackEventWithCustomDimension(TrackingService.DATA_CATEGORY_EVENT, TrackingService.ACTION_CORRECTION, this.targetName, Date.now(), this.targetName);
        var data = {
          action: "show_success_dialog"
        }
        this.dialogRef.close(data);
      }else{
        var data = {
          action: "show_unsuccess_dialog"
        }
        this.dialogRef.close(data);
      }
    });
  }

  onlyMediaFile(fileType){
    if(window.matchMedia('(max-width:500px)').matches){
      return false;
    }else{
      return StringHelper.getFilePreviewType(fileType)
    }
  }

  onAttachmentDownload(attachment) {
    console.log("view letter dialog");
    if(attachment.id !== undefined){
      this.downloadService.getAttachment(attachment.id)
        .subscribe(
          res => {
            FileSaver.saveAs(res, attachment.name)
          }
        )
    }else{
      let blob = FileHelper.b64toBlob(attachment.content,attachment.type);
      FileSaver.saveAs(blob, "RequestLetter.pdf")
    }

  }

  onAttachmentPreview(attachment) {
    this.spinner.show();
    if(attachment.id !== undefined){
      this.downloadService.getAttachment(attachment.id)
        .subscribe(
          res => {
            this.spinner.hide();
            this.showPreviewModal(res,attachment.name);
          }
        )
    }else{
      let blob = FileHelper.b64toBlob(attachment.content,attachment.type);
      this.spinner.hide();
      this.showPreviewModal(blob,attachment.name);
    }
  }

  showPreviewModal(attachmentContent, attachmentName){
    let modalModel:FilePreviewModal = {
      targetName: '',
      file:attachmentContent,
      fileName: attachmentName,
      buttonText: this.translate.instant('PREVIEW_FILE_POPUP.CLOSE'),
      downloadButton: this.translate.instant('PREVIEW_FILE_POPUP.DOWNLOAD'),
      content: attachmentName
    };
    this.dialog.open(PreviewFilePopup,{
      width:'60%',
      height: '80%',
      panelClass:'new-modal-container',
      data:{metadata:modalModel}
    })
  }

  onDecryptDataAction(){
    var closeResult = new LetterCloseResult();
    closeResult.action = CloseNextAction.DECRYPT_DATA;
    closeResult.letterModal = this.modalModel;
    this.dialogRef.close(closeResult);
  }

  goToProfile(){
    this.dialogRef.close({});
    this.router.navigate(['dashboard/profile']);
  }

  isShowDataVisible() {
    if (this.modalModel.actionType === CommunicationModel.ActionEnum.DATAPROCESSED) {
      return true;
    }
    return false;
  }

  showCorrectDataButton() {
    if (this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONDATA || this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONSIGNATURE || this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.INCORRECTIDENTIFICATIONDATA && this.modalModel.targetStatus == UserTargetModel.StatusEnum.INTERMEDIATEINFORECEIVED) {
      return true;
    }
    return false;
  }

  showRenewDataButton() {
    if (this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONDATA || this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.INCORRECTID || this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.MISSINGREQUIREDIDENTIFICATIONSIGNATURE || this.modalModel.actionSubType == CommunicationModel.ActionSubTypeEnum.INCORRECTIDENTIFICATIONDATA && this.modalModel.targetStatus == UserTargetModel.StatusEnum.INTERMEDIATEINFORECEIVED) {
      return true;
    }
    return false;
  }

  showSendReplyLetterButton() {
    if (this.modalModel.actionType == CommunicationModel.ActionEnum.INTERMEDIATEINFOREPLY && this.modalModel.previewLetterType == LetterModel.PreviewLetterType.REPLY) {
      return true;
    }
    return false;
  }

  showDecryptDataButton(){
    let canBeDecrypted = false;
    if (this.modalModel.actionType == CommunicationModel.ActionEnum.DATARECEIVEDENCRYPTED) {
      canBeDecrypted = true;
    } else if (this.modalModel.targetStatus != null && this.modalModel.targetStatus != undefined) {
      if(this.modalModel.targetStatus.includes("DATA_RECEIVED_ENCRYPTED")) {
        canBeDecrypted = true;
      }
    }
    return canBeDecrypted;
  }

}
