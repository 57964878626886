/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserTargetModel {
    customerNumber?: string;
    errorType?: UserTargetModel.ErrorTypeEnum;
    id?: string;
    lastCommunicationId?: string;
    lastDeliveredDataDate?: string;
    lastRequestDate?: string;
    status?: UserTargetModel.StatusEnum;
    targetId?: string;
    userId?: string;
}
export namespace UserTargetModel {
    export type ErrorTypeEnum = 'GENERAL' | 'NAME';
    export const ErrorTypeEnum = {
        GENERAL: 'GENERAL' as ErrorTypeEnum,
        NAME: 'NAME' as ErrorTypeEnum
    }
    export type StatusEnum = 'PENDING' | 'OVERDUE' | 'REMINDER_WAS_SENT' | 'REQUEST_FAILED' | 'READY_FOR_UPLOAD' | 'READY_FOR_DOWNLOAD' | 'DELIVERED' | 'DELIVERED_BY_UPLOAD' | 'DELIVERED_VIA_PLUGIN' | 'DATA_PROCESSED' | 'READY_FOR_UPDATE' | 'RECEIVED_EMAIL' | 'INTERMEDIATE_INFO_RECEIVED' | 'INTERMEDIATE_INFO_REPLY' | 'DATA_DELETION_REQUESTED' | 'DATA_DELETION_CONFIRMED' | 'DATA_DELETION_CONTRADICTED' | 'DATA_CORRECTION_REQUESTED' | 'DATA_CORRECTION_CONFIRMED' | 'DATA_CORRECTION_CONTRADICTED' | 'DOWNLOAD_LINK_RECEIVED' | 'PIN_RECEIVED' | 'REPLY_RECEIVED' | 'REPLY_TO_REMINDER' | 'DATA_DELETED' | 'DELETION_REPLY' | 'DATA_RECEIVED_ENCRYPTED' | 'DATA_PARSING_ERROR' | 'DATA_UPLOADED' | 'MARKED_REQUEST_COMPLETED' | 'DATA_RECEIVED_NO_DATA' | 'DATA_RECEIVED_NO_SCORE' | 'REPLY_WITH_EMAIL' | 'REPLY_SENT';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        OVERDUE: 'OVERDUE' as StatusEnum,
        REMINDERSENT: 'REMINDER_SENT' as StatusEnum,
        REQUESTFAILED: 'REQUEST_FAILED' as StatusEnum,
        READYFORUPLOAD: 'READY_FOR_UPLOAD' as StatusEnum,
        READYFORDOWNLOAD: 'READY_FOR_DOWNLOAD' as StatusEnum,
        DELIVERED: 'DELIVERED' as StatusEnum,
        DELIVEREDBYUPLOAD: 'DELIVERED_BY_UPLOAD' as StatusEnum,
        DELIVEREDVIAPLUGIN: 'DELIVERED_VIA_PLUGIN' as StatusEnum,
        DATAPROCESSED: 'DATA_PROCESSED' as StatusEnum,
        READYFORUPDATE: 'READY_FOR_UPDATE' as StatusEnum,
        RECEIVEDEMAIL: 'RECEIVED_EMAIL' as StatusEnum,
        INTERMEDIATEINFORECEIVED: 'INTERMEDIATE_INFO_RECEIVED' as StatusEnum,
        INTERMEDIATEINFOREPLY: 'INTERMEDIATE_INFO_REPLY' as StatusEnum,
        DATADELETIONREQUESTED: 'DATA_DELETION_REQUESTED' as StatusEnum,
        DATADELETIONCONFIRMED: 'DATA_DELETION_CONFIRMED' as StatusEnum,
        DATADELETIONCONTRADICTED: 'DATA_DELETION_CONTRADICTED' as StatusEnum,
        DATACORRECTIONREQUESTED: 'DATA_CORRECTION_REQUESTED' as StatusEnum,
        DATACORRECTIONCONFIRMED: 'DATA_CORRECTION_CONFIRMED' as StatusEnum,
        DATACORRECTIONCONTRADICTED: 'DATA_CORRECTION_CONTRADICTED' as StatusEnum,
        DOWNLOADLINKRECEIVED: 'DOWNLOAD_LINK_RECEIVED' as StatusEnum,
        PINRECEIVED: 'PIN_RECEIVED' as StatusEnum,        
        REPLYRECEIVED: 'REPLY_RECEIVED' as StatusEnum,
        DATA_DELETED: 'DATA_DELETED' as StatusEnum,
        DELETIONREPLY: 'DELETION_REPLY' as StatusEnum,
        REPLYTOREMINDER: 'REPLY_TO_REMINDER' as StatusEnum,
        DATARECEIVEDENCRYPTED: 'DATA_RECEIVED_ENCRYPTED' as StatusEnum,
        DATAPARSINGERROR: 'DATA_PARSING_ERROR' as StatusEnum,
        DATAUPLOADED: 'DATA_UPLOADED' as StatusEnum,
        MARKEDREQUESTCOMPLETED: 'MARKED_REQUEST_COMPLETED' as StatusEnum,
        DATARECEIVEDNOSCORE: 'DATA_RECEIVED_NO_SCORE' as StatusEnum ,
        DATARECEIVEDNODATA: 'DATA_RECEIVED_NO_DATA' as StatusEnum,
        REPLYWITHEMAIL: 'REPLY_WITH_EMAIL' as StatusEnum,
        REPLYSENT: 'REPLY_SENT' as StatusEnum
    }
}
