import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import {BrowserHelper} from "../helpers/browser-helper";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
        ) {}

    canActivate() {
      //check for ie11 browser
      if(BrowserHelper.isIE()){
        this.router.navigate(['/outdated-browser']);
      }else{
        if(this.authService.isAuthenticated()) {
          return true;
        } else {
          this.router.navigate(["/login"]);
          return false;
        }
      }
    }
}

@Injectable()
export class LoadingGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
        ) {}

    canActivate() {
      //check for ie11 browser
      if(BrowserHelper.isIE()){
        this.router.navigate(['/outdated-browser']);
      }else{
        if(this.authService.isAuthenticated()) {
          this.router.navigate(['/dashboard']);
          return true;
        } else {
          this.router.navigate(["/signup"]);
          return false;
        }
      }
    }
}

@Injectable()
export class BrowserOutdatedGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate() {
    // check for ie11 browser
    if(BrowserHelper.isIE()){
      this.router.navigate(['/outdated-browser']);
      return false;
    }else{
      return true;
    }
  }
}
