import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NewGeneralModal} from '../../shared/models/modal.model';


@Component({
  selector: 'new-general-popup.dialog',
  templateUrl: 'new-general-popup.html',
  styleUrls: ['./new-general-popup.scss'],
})
export class NewGeneralPopup implements OnInit {

  modalModel: NewGeneralModal;
  public popupText: string = null;

  exitHovered = false;

  constructor(public dialogRef: MatDialogRef<NewGeneralPopup>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalModel = data["metadata"];
  }

  ngOnInit() {

    if(this.modalModel.autoClose){
      setTimeout(() => {
        this.dialogRef.close() //close dialog
      }, 10000); //1000 equals the time in miliseconds
    }
  }

  onCloseBtnClick(): void {
    this.dialogRef.close({});
  }

  copyButton(){
    if(this.modalModel.from === 'FORWARD_EMAIL'){
      this.copyFunction()
    } else{
      this.dialogRef.close();
    }
  }

  copyFunction(){
    // document.execCommand('copy');
    let body = document.getElementsByTagName('body')[0];
    let secretInfo = document.getElementById('secretInfo').innerHTML;

    // hacky way to copy text to clipboard
    let tempInput = document.createElement('INPUT');
    body.appendChild(tempInput);
    tempInput.setAttribute('value', secretInfo)
    // @ts-ignore
    tempInput.select();
    document.execCommand('copy');
    body.removeChild(tempInput);
  }

}
