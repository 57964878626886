/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable , BehaviorSubject}                                        from 'rxjs';

import { ClientModel } from '../model/clientModel';
import { ResetPasswordModel } from '../model/resetPasswordModel';
import { ResponseEntity } from '../model/responseEntity';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { CheckCodeModel } from '../model/checkCodeModel';
import {UserSuggestion} from '../model/userSuggestion';
import { ParameterModel } from '../model/parameterModel';
import {UserSuggestionModel} from '../model/userSuggestionModel';
import {AttachmentModel, OldAddressModel} from "..";
import { MietermappeModel, ExternalSourceClientModel } from '../model/models';
import {ClientEmail} from "../model/clientEmail";
import { BridgeConsent } from '../model/bridgeConsent';


@Injectable()
export class ClientControllerService {

    protected basePath = 'https://localhost:8082';
    private  emailSource = new BehaviorSubject<string>("");
    currentEmail = this.emailSource.asObservable();
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    changeEmail(email:string){
      this.emailSource.next(email);
    }
    sendEmail(email:string){
      this.emailSource.next(email);
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

  /**
   * addUserSuggestion
   *
   * @param userSuggestionModel userSuggestionModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addUserSuggestionUsingPOST(userSuggestionModel: UserSuggestionModel, observe?: 'body', reportProgress?: boolean): Observable<UserSuggestion>;
  public addUserSuggestionUsingPOST(userSuggestionModel: UserSuggestionModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSuggestion>>;
  public addUserSuggestionUsingPOST(userSuggestionModel: UserSuggestionModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSuggestion>>;
  public addUserSuggestionUsingPOST(userSuggestionModel: UserSuggestionModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (userSuggestionModel === null || userSuggestionModel === undefined) {
      throw new Error('Required parameter userSuggestionModel was null or undefined when calling addUserSuggestionUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<UserSuggestion>(`${this.basePath}/userSuggestion`,
      userSuggestionModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
     * changeBridgeConsentIsActive
     *
     * @param bridgeConsentId bridgeConsentId
     * @param isActive isActive
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
   public changeBridgeConsentIsActiveUsingGET(bridgeConsentId: string, isActive: boolean, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
   public changeBridgeConsentIsActiveUsingGET(bridgeConsentId: string, isActive: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
   public changeBridgeConsentIsActiveUsingGET(bridgeConsentId: string, isActive: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
   public changeBridgeConsentIsActiveUsingGET(bridgeConsentId: string, isActive: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
       if (bridgeConsentId === null || bridgeConsentId === undefined) {
           throw new Error('Required parameter bridgeConsentId was null or undefined when calling changeBridgeConsentIsActiveUsingGET.');
       }
       if (isActive === null || isActive === undefined) {
           throw new Error('Required parameter isActive was null or undefined when calling changeBridgeConsentIsActiveUsingGET.');
       }

       let headers = this.defaultHeaders;

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           '*/*'
       ];
       let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set("Accept", httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       let consumes: string[] = [
           'application/json'
       ];

       let basicAuthHeader = localStorage.getItem('access_token');
       headers = headers.set("Authorization", "bearer " + basicAuthHeader);
       return this.httpClient.get<ResponseEntity>(`${this.basePath}/client/changeBridgeConsent/${encodeURIComponent(String(bridgeConsentId))}/${encodeURIComponent(String(isActive))}`,
           {
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }


   /**
     * checkIdentityForPaymentWithDeutscheBank
     *
     * @param code code
     * @param path path
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIdentityForPaymentWithDeutscheBankUsingGET(code: string, path: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkIdentityForPaymentWithDeutscheBankUsingGET(code: string, path: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkIdentityForPaymentWithDeutscheBankUsingGET(code: string, path: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkIdentityForPaymentWithDeutscheBankUsingGET(code: string, path: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling checkIdentityForPaymentWithDeutscheBankUsingGET.');
        }
        if (path === null || path === undefined) {
            throw new Error('Required parameter path was null or undefined when calling checkIdentityForPaymentWithDeutscheBankUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<boolean>(`${this.basePath}/checkIdentityForPaymentWithDeutscheBank/${encodeURIComponent(String(code))}/${encodeURIComponent(String(path))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



  /**
     * checkCodeValidity
     *
     * @param model model
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkCodeValidityUsingPOST(model: CheckCodeModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkCodeValidityUsingPOST(model: CheckCodeModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkCodeValidityUsingPOST(model: CheckCodeModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkCodeValidityUsingPOST(model: CheckCodeModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling checkCodeValidityUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/checkCodeValidity`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkIfUserDataIsReady
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfUserDataIsReadyUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<string> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get(`${this.basePath}/checkIfUserDataIsReady`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


      /**
     * checkIfUserDataIsReady
     *
     * @param lastUserDevice lastUserDevice
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkUserDeviceMatchUsingPOST(lastUserDevice: string, observe: any = 'body', reportProgress: boolean = false ): Observable<string> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post(`${this.basePath}/checkUserDeviceMatch`,
        lastUserDevice,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmDataDownload
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmDataDownloadUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<string> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling confirmDataDownloadUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        return this.httpClient.get(`${this.basePath}/confirmDataDownload/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBridgeConsent
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getBridgeConsentUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BridgeConsent>>;
     public getBridgeConsentUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BridgeConsent>>>;
     public getBridgeConsentUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BridgeConsent>>>;
     public getBridgeConsentUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         let headers = this.defaultHeaders;

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             '*/*'
         ];
         let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set("Accept", httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         let consumes: string[] = [
             'application/json'
         ];

         let basicAuthHeader = localStorage.getItem('access_token');
         headers = headers.set("Authorization", "bearer " + basicAuthHeader);
         return this.httpClient.get<Array<BridgeConsent>>(`${this.basePath}/client/bridgeConsent`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     * getClientDataFromDb
     *
     * @param code code
     * @param path path
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientDataFromDbUsingGET(code: string, path: string, observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
    public getClientDataFromDbUsingGET(code: string, path: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
    public getClientDataFromDbUsingGET(code: string, path: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
    public getClientDataFromDbUsingGET(code: string, path: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getClientDataFromDbUsingGET.');
        }
        if (path === null || path === undefined) {
            throw new Error('Required parameter path was null or undefined when calling getClientDataFromDbUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ClientModel>(`${this.basePath}/getClientDataFromDeutscheBank/${encodeURIComponent(String(code))}/${encodeURIComponent(String(path))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


     /**
     * getClientRent
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientRentUsingPOST(code?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getClientRentUsingPOST(code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getClientRentUsingPOST(code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getClientRentUsingPOST(code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<string>(`${this.basePath}/getClientRent`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

      /**
     * checkIfUserHasMietermappe
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfUserHasMietermappeUsingGET(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkIfUserHasMietermappeUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkIfUserHasMietermappeUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkIfUserHasMietermappeUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<boolean>(`${this.basePath}/checkIfUserHasMietermappe`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMietermappeModel
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMietermappeModelUsingGET(observe?: 'body', reportProgress?: boolean): Observable<MietermappeModel>;
    public getMietermappeModelUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MietermappeModel>>;
    public getMietermappeModelUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MietermappeModel>>;
    public getMietermappeModelUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<MietermappeModel>(`${this.basePath}/getMietermappeModel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



    /**
     * saveClientIncomeAndRent
     *
     * @param selectedRentJson selectedRentJson
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveClientIncomeAndRentUsingPOST(selectedRentJson: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public saveClientIncomeAndRentUsingPOST(selectedRentJson: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public saveClientIncomeAndRentUsingPOST(selectedRentJson: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public saveClientIncomeAndRentUsingPOST(selectedRentJson: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (selectedRentJson === null || selectedRentJson === undefined) {
            throw new Error('Required parameter selectedRentJson was null or undefined when calling saveClientIncomeAndRentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<string>(`${this.basePath}/saveClientIncomeAndRent`,
            selectedRentJson,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * deleteAccount
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAccountUsingPUT(code: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteAccountUsingPUT(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteAccountUsingPUT(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteAccountUsingPUT(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling deleteAccountUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        return this.httpClient.put<ResponseEntity>(`${this.basePath}/validationDelete/${encodeURIComponent(String(code))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * emailValidation
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailValidationUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<string> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling emailValidationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        return this.httpClient.get(`${this.basePath}/validation/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'text',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * forgotPassowrd
     *
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forgotPassowrdUsingPOST(email: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public forgotPassowrdUsingPOST(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public forgotPassowrdUsingPOST(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public forgotPassowrdUsingPOST(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling forgotPassowrdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/forgotPassword`,
            email,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getClientProfile
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientProfileUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
    public getClientProfileUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
    public getClientProfileUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
    public getClientProfileUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ClientModel>(`${this.basePath}/client/profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * getFullProfile
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getClientFullProfileUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
  public getClientFullProfileUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
  public getClientFullProfileUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
  public getClientFullProfileUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<ClientModel>(`${this.basePath}/client/fullprofile`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

    /**
     * registerClient
     *
     * @param clientModel clientModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerClientUsingPUT(clientModel: ClientModel, observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
    public registerClientUsingPUT(clientModel: ClientModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
    public registerClientUsingPUT(clientModel: ClientModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
    public registerClientUsingPUT(clientModel: ClientModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clientModel === null || clientModel === undefined) {
            throw new Error('Required parameter clientModel was null or undefined when calling registerClientUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<ClientModel>(`${this.basePath}/client/register`,
            clientModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerFromExternalSource
     *
     * @param clientModel clientModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerFromExternalSourceUsingPUT(clientModel: ExternalSourceClientModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public registerFromExternalSourceUsingPUT(clientModel: ExternalSourceClientModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public registerFromExternalSourceUsingPUT(clientModel: ExternalSourceClientModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public registerFromExternalSourceUsingPUT(clientModel: ExternalSourceClientModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clientModel === null || clientModel === undefined) {
            throw new Error('Required parameter clientModel was null or undefined when calling registerFromExternalSourceUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/client/registerFromExternalSource`,
            clientModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * requestAccountDataDeletion
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestAccountDataDeletionUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public requestAccountDataDeletionUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public requestAccountDataDeletionUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public requestAccountDataDeletionUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/requestDeleteAccount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

   /**
     * sendDataDownloadConfirmationEmail
     *
     * @param lastUserDevice email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendDataDownloadConfirmationEmailUsingPOST(lastUserDevice: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public sendDataDownloadConfirmationEmailUsingPOST(lastUserDevice: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public sendDataDownloadConfirmationEmailUsingPOST(lastUserDevice: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public sendDataDownloadConfirmationEmailUsingPOST(lastUserDevice: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (lastUserDevice === null || lastUserDevice === undefined) {
            throw new Error('Required parameter email was null or undefined when calling forgotPassowrdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.post<ResponseEntity>(`${this.basePath}/sendDataDownloadConfirmationEmail`,
        lastUserDevice,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestPhoneNumberValidation
     *
     * @param phoneNumber phoneNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestPhoneNumberValidationUsingGET(phoneNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public requestPhoneNumberValidationUsingGET(phoneNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public requestPhoneNumberValidationUsingGET(phoneNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public requestPhoneNumberValidationUsingGET(phoneNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling requestPhoneNumberValidationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<ResponseEntity>(`${this.basePath}/requestPhoneNumberValidation/${encodeURIComponent(String(phoneNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resendPhoneNumberValidation
     *
     * @param phoneNumber phoneNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public resendPhoneNumberValidationUsingGET(phoneNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
     public resendPhoneNumberValidationUsingGET(phoneNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
     public resendPhoneNumberValidationUsingGET(phoneNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
     public resendPhoneNumberValidationUsingGET(phoneNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
         if (phoneNumber === null || phoneNumber === undefined) {
             throw new Error('Required parameter phoneNumber was null or undefined when calling resendPhoneNumberValidationUsingGET.');
         }

         let headers = this.defaultHeaders;

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             '*/*'
         ];
         let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set("Accept", httpHeaderAcceptSelected);
         }
         let basicAuthHeader = localStorage.getItem('access_token');
         headers = headers.set("Authorization", "bearer " + basicAuthHeader);
         return this.httpClient.get<ResponseEntity>(`${this.basePath}/resendPhoneNumberValidation/${encodeURIComponent(String(phoneNumber))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

    /**
     * resetPassword
     *
     * @param resetPasswordModel resetPasswordModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordUsingPOST(resetPasswordModel: ResetPasswordModel, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public resetPasswordUsingPOST(resetPasswordModel: ResetPasswordModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public resetPasswordUsingPOST(resetPasswordModel: ResetPasswordModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public resetPasswordUsingPOST(resetPasswordModel: ResetPasswordModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resetPasswordModel === null || resetPasswordModel === undefined) {
            throw new Error('Required parameter resetPasswordModel was null or undefined when calling resetPasswordUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/resetPassword`,
            resetPasswordModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * getProfileImage
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfileImageUsingGET(observe?: 'body', reportProgress?: boolean): Observable<AttachmentModel>;
  public getProfileImageUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttachmentModel>>;
  public getProfileImageUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttachmentModel>>;
  public getProfileImageUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<ClientModel>(`${this.basePath}/client/profileImage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

     /**
     * saveProfileImage
     *
     * @param file file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveProfileImageUsingPUT(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public saveProfileImageUsingPUT(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public saveProfileImageUsingPUT(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public saveProfileImageUsingPUT(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling saveProfileImageUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.put<ResponseEntity>(`${this.basePath}/saveProfileImage`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveUserImageAttachments
     *
     * @param files files
     * @param attachmentSourceType attachmentSourceType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveUserImageAttachmentsUsingPUT(files: Array<Blob>, attachmentSourceType: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public saveUserImageAttachmentsUsingPUT(files: Array<Blob>, attachmentSourceType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public saveUserImageAttachmentsUsingPUT(files: Array<Blob>, attachmentSourceType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public saveUserImageAttachmentsUsingPUT(files: Array<Blob>, attachmentSourceType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (files === null || files === undefined) {
            throw new Error('Required parameter files was null or undefined when calling saveUserImageAttachmentsUsingPUT.');
        }
        if (attachmentSourceType === null || attachmentSourceType === undefined) {
            throw new Error('Required parameter attachmentSourceType was null or undefined when calling saveUserImageAttachmentsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'multipart/form-data'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let formParams: { append(param: string, value: any): void; };
        let useForm = true;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                formParams.append('files', <any>element);
            })
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.put<ResponseEntity>(`${this.basePath}/saveUserImageAttachments/${encodeURIComponent(String(attachmentSourceType))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * deleteUserImageAttachments
     *
     * @param attachmentSourceType attachmentSourceType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserImageAttachmentsUsingDELETE(attachmentSourceType: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteUserImageAttachmentsUsingDELETE(attachmentSourceType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteUserImageAttachmentsUsingDELETE(attachmentSourceType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteUserImageAttachmentsUsingDELETE(attachmentSourceType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (attachmentSourceType === null || attachmentSourceType === undefined) {
            throw new Error('Required parameter attachmentSourceType was null or undefined when calling deleteUserImageAttachmentsUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.delete<ResponseEntity>(`${this.basePath}/deleteUserImageAttachments/${encodeURIComponent(String(attachmentSourceType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


     /**
     * deleteProfileImage
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProfileImageUsingDELETE(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteProfileImageUsingDELETE(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteProfileImageUsingDELETE(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteProfileImageUsingDELETE(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);

        return this.httpClient.delete<ResponseEntity>(`${this.basePath}/deleteProfileImage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sendValidationEmail
     *
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendValidationEmailUsingPOST(email: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public sendValidationEmailUsingPOST(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public sendValidationEmailUsingPOST(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public sendValidationEmailUsingPOST(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sendValidationEmailUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/validation/send`,
            email,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

      /**
     * reportDuplicateIdentityAttempt
     *
     * @param requestClientId requestClientId
     * @param reportedClientId reportedClientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
       public reportDuplicateIdentityAttemptUsingGET(requestClientId: string, reportedClientId: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
       public reportDuplicateIdentityAttemptUsingGET(requestClientId: string, reportedClientId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
       public reportDuplicateIdentityAttemptUsingGET(requestClientId: string, reportedClientId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
       public reportDuplicateIdentityAttemptUsingGET(requestClientId: string, reportedClientId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
           if (requestClientId === null || requestClientId === undefined) {
               throw new Error('Required parameter requestClientId was null or undefined when calling reportDuplicateIdentityAttemptUsingGET.');
           }
           if (reportedClientId === null || reportedClientId === undefined) {
               throw new Error('Required parameter reportedClientId was null or undefined when calling reportDuplicateIdentityAttemptUsingGET.');
           }

           let headers = this.defaultHeaders;

           // to determine the Accept header
           let httpHeaderAccepts: string[] = [
               '*/*'
           ];
           let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set("Accept", httpHeaderAcceptSelected);
           }
           return this.httpClient.get<ResponseEntity>(`${this.basePath}/duplicateIdentityReport/${encodeURIComponent(String(requestClientId))}/${encodeURIComponent(String(reportedClientId))}`,
               {
                   withCredentials: this.configuration.withCredentials,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress
               }
           );
       }

     /**
     * updateClientProfileFromPrerequisites
     *
     * @param parameterModels parameterModels
     * @param identificationSource identificationSource
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClientProfileFromPrerequisitesUsingPUT(parameterModels: Array<ParameterModel>, identificationSource?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public updateClientProfileFromPrerequisitesUsingPUT(parameterModels: Array<ParameterModel>, identificationSource?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public updateClientProfileFromPrerequisitesUsingPUT(parameterModels: Array<ParameterModel>, identificationSource?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public updateClientProfileFromPrerequisitesUsingPUT(parameterModels: Array<ParameterModel>, identificationSource?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (parameterModels === null || parameterModels === undefined) {
            throw new Error('Required parameter parameterModels was null or undefined when calling updateClientProfileFromPrerequisitesUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (identificationSource !== undefined) {
            queryParameters = queryParameters.set('identificationSource', <any>identificationSource);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.put<ResponseEntity>(`${this.basePath}/updateFromPrerequisites`,
            parameterModels,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * updateClientProfile
     *
     * @param clientModel clientModel
     * @param identificationSource idSource
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClientProfileUsingPUT(clientModel: ClientModel, identificationSource?: string, observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
    public updateClientProfileUsingPUT(clientModel: ClientModel, identificationSource?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
    public updateClientProfileUsingPUT(clientModel: ClientModel, identificationSource?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
    public updateClientProfileUsingPUT(clientModel: ClientModel, identificationSource?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clientModel === null || clientModel === undefined) {
            throw new Error('Required parameter clientModel was null or undefined when calling updateClientProfileUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (identificationSource !== undefined) {
            queryParameters = queryParameters.set('identificationSource', identificationSource);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.put<ClientModel>(`${this.basePath}/update`,
            clientModel,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateFirstLogin
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateFirstLoginUsingPUT(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public validateFirstLoginUsingPUT(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public validateFirstLoginUsingPUT(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public validateFirstLoginUsingPUT(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.put<ResponseEntity>(`${this.basePath}/validateFirstLogin`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validatePhoneNumber
     *
     * @param code code
     * @param phoneNumber phoneNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validatePhoneNumberUsingPUT(code: string, phoneNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
    public validatePhoneNumberUsingPUT(code: string, phoneNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
    public validatePhoneNumberUsingPUT(code: string, phoneNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
    public validatePhoneNumberUsingPUT(code: string, phoneNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling validatePhoneNumberUsingPUT.');
        }
        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling validatePhoneNumberUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.put<ClientModel>(`${this.basePath}/validationPhoneNumber/${encodeURIComponent(String(code))}/${encodeURIComponent(String(phoneNumber))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * saveOldAddress
   *
   * @param oldAddressModel oldAddressModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveOldAddressUsingPUT(oldAddressModel: OldAddressModel, observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
  public saveOldAddressUsingPUT(oldAddressModel: OldAddressModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
  public saveOldAddressUsingPUT(oldAddressModel: OldAddressModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
  public saveOldAddressUsingPUT(oldAddressModel: OldAddressModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (oldAddressModel === null || oldAddressModel === undefined) {
      throw new Error('Required parameter oldAddressModel was null or undefined when calling saveOldAddressUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.put<ClientModel>(`${this.basePath}/saveOldAddress`,
      oldAddressModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  /**
   * saveNewEmail
   *
   * @param email email
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveNewEmailUsingPOST(email: string, observe?: 'body', reportProgress?: boolean): Observable<ClientEmail>;
  public saveNewEmailUsingPOST(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientEmail>>;
  public saveNewEmailUsingPOST(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientEmail>>;
  public saveNewEmailUsingPOST(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (email === null || email === undefined) {
      throw new Error('Required parameter email was null or undefined when calling saveNewEmailUsingPOST.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (email !== undefined) {
      queryParameters = queryParameters.set('email', <any>email);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<ClientEmail>(`${this.basePath}/client/email`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteEmail
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteEmailUsingDELETE(id: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public deleteEmailUsingDELETE(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public deleteEmailUsingDELETE(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public deleteEmailUsingDELETE(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteEmailUsingDELETE.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (id !== undefined) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.delete<boolean>(`${this.basePath}/client/email`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllClientEmails
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllClientEmailsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<ClientEmail>>;
  public getAllClientEmailsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClientEmail>>>;
  public getAllClientEmailsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClientEmail>>>;
  public getAllClientEmailsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<Array<ClientEmail>>(`${this.basePath}/client/email`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public startIdentificationProcess(observe?: 'body', reportProgress?: boolean): Observable<ClientModel>;
  public startIdentificationProcess(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientModel>>;
  public startIdentificationProcess(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientModel>>;
  public startIdentificationProcess(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<ClientModel>(`${this.basePath}/updateIdentification`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
