/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomBucketValueModel } from './customBucketValueModel';
import { LinechartDataModel } from './linechartDataModel';
import { StackBarDataModel } from './stackBarDataModel';
import { TimelineDataModel } from './timelineDataModel';


export interface BucketSubCategoryModel {
    bucketValueModels?: Array<CustomBucketValueModel>;
    contentType?: BucketSubCategoryModel.ContentTypeEnum;
    imageName?: string;
    linechartModel?: LinechartDataModel;
    name?: string;
    stackBarModel?: StackBarDataModel;
    timelineModel?: Array<TimelineDataModel>;
    type?: BucketSubCategoryModel.TypeEnum;
    lastMonthItemsCount?: number;
}
export namespace BucketSubCategoryModel {
    export type ContentTypeEnum = 'SUMMARY' | 'TEXT' | 'TABLE' | 'PEER_COMPARISON' | 'TIMELINE' | 'STACKBAR' | 'LINECHART' | 'HASHTAG' | 'LIST' | 'DONUT' | 'PROFILE_SUMMARY' | 'LOCATION_MAP' | 'CERTIFICATE_SCORE_LINE_CHART';
    export const ContentTypeEnum = {
        SUMMARY: 'SUMMARY' as ContentTypeEnum,
        TEXT: 'TEXT' as ContentTypeEnum,
        TABLE: 'TABLE' as ContentTypeEnum,
        PEERCOMPARISON: 'PEER_COMPARISON' as ContentTypeEnum,
        TIMELINE: 'TIMELINE' as ContentTypeEnum,
        STACKBAR: 'STACKBAR' as ContentTypeEnum,
        LINECHART: 'LINECHART' as ContentTypeEnum,
        HASHTAG: 'HASHTAG' as ContentTypeEnum,
        LIST: 'LIST' as ContentTypeEnum,
        DONUT: 'DONUT' as ContentTypeEnum,
        PROFILESUMMARY: 'PROFILE_SUMMARY' as ContentTypeEnum,
        LOCATIONMAP: 'LOCATION_MAP' as ContentTypeEnum,
        CERTIFICATESCORELINECHART: 'CERTIFICATE_SCORE_LINE_CHART' as ContentTypeEnum
    }
    export type TypeEnum =  'HOME' | 'LOCATIONS' | 'FAMILY_RELATIVES' | 'DAILY_STEPS' | 'DAILY_DISTANCE' | 'DAILY_MOVEMENT' | 'INTERESTS' | 'BODY_MEASUREMENTS' | 'SOLVENCY' | 'ACCOUNT' | 'SPENDINGS_DIGITAL_GOODS' | 'MONTHLY_SPENDINGS' | 'DESTINATIONS' | 'REST' | 'RENT' | 'PURCHASES' | 'DIGITAL_GOODS' | 'COLLECTION' | 'PROFESSION' | 'PROFILE_SUMMARY' | 'CAREER' | 'EDUCATION' | 'QUALIFICATIONS_SKILLS' | 'KNOWLEDGE' | 'SOCIAL_MEDIA' | 'SOCIAL_ACTIVITIES_YOU_OTHERS_STACK_BAR' | 'SOCIAL_ACTIVITIES_YOU_OTHERS_LINE_CHART' | 'SOCIAL_ACTIVITIES_OTHERS_YOU_STACK_BAR' | 'SOCIAL_ACTIVITIES_OTHERS_YOU_LINE_CHART' | 'MESSAGES' | 'ASSOCIATIONS' | 'SOCIAL_FRIENDS_LIST' | 'SOCIAL_FRIENDS_LINE_CHART' | 'SOCIAL_FOLLOWERS_LIST' | 'SOCIAL_FOLLOWERS_LINE_CHART' | 'IDENTIFICATION' | 'FURTHER_INFORMATION' | 'PROFILE' | 'ABOUT_ME' | 'LIFE_EVENTS' | 'OVERVIEW' | 'MUSIC_BOOKS' | 'MUSIC' | 'ACHIEVEMENTS_GAMES' | 'MOVIES_SHOWS' | 'APPS' | 'VOLUNTEER_WORK' | 'TV' | 'SUBSCRIPTIONS' | 'FAVOURITES' | 'COLLECTIONS' | 'HASHTAGS' | 'ONLINE_ACCOUNTS' | 'JOB' | 'FAMILY' | 'DEVICES' | 'SKILLS' | 'ADVERTISING' | 'SPORTS' | 'ACTIVITIES' | 'ACTIVITY' | 'ARTS_LITERATURE' | 'ENTERTAINMENT' | 'PLACES' | 'CHARACTERIZATION' | 'FRIENDS' | 'MONEY_PAYMENTS' | 'BUSINESS' | 'ACCOUNTS' | 'POSITION' | 'GAMES' | 'MOVIES_TV' | 'LOCATION' | 'FOLLOWERS' | 'COMMUNICATION' | 'ADMINISTRATION' | 'QUALIFICATIONS' | 'ONLINE' | 'TERMINALS';
    export const TypeEnum = {
        HOME: 'HOME' as TypeEnum,
        LOCATIONS: 'LOCATIONS' as TypeEnum,
        FAMILYRELATIVES: 'FAMILY_RELATIVES' as TypeEnum,
        DAILYSTEPS: 'DAILY_STEPS' as TypeEnum,
        DAILYDISTANCE: 'DAILY_DISTANCE' as TypeEnum,
        DAILYMOVEMENT: 'DAILY_MOVEMENT' as TypeEnum,
        INTERESTS: 'INTERESTS' as TypeEnum,
        BODYMEASUREMENTS: 'BODY_MEASUREMENTS' as TypeEnum,
        SOLVENCY: 'SOLVENCY' as TypeEnum,
        ACCOUNT: 'ACCOUNT' as TypeEnum,
        SPENDINGSDIGITALGOODS: 'SPENDINGS_DIGITAL_GOODS' as TypeEnum,
        MONTHLYSPENDINGS: 'MONTHLY_SPENDINGS' as TypeEnum,
        DESTINATIONS: 'DESTINATIONS' as TypeEnum,
        REST: 'REST' as TypeEnum,
        RENT: 'RENT' as TypeEnum,
        PURCHASES: 'PURCHASES' as TypeEnum,
        DIGITALGOODS: 'DIGITAL_GOODS' as TypeEnum,
        COLLECTION: 'COLLECTION' as TypeEnum,
        PROFESSION: 'PROFESSION' as TypeEnum,
        PROFILESUMMARY: 'PROFILE_SUMMARY' as TypeEnum,
        CAREER: 'CAREER' as TypeEnum,
        EDUCATION: 'EDUCATION' as TypeEnum,
        QUALIFICATIONSSKILLS: 'QUALIFICATIONS_SKILLS' as TypeEnum,
        KNOWLEDGE: 'KNOWLEDGE' as TypeEnum,
        SOCIALMEDIA: 'SOCIAL_MEDIA' as TypeEnum,
        SOCIALACTIVITIESYOUOTHERSSTACKBAR: 'SOCIAL_ACTIVITIES_YOU_OTHERS_STACK_BAR' as TypeEnum,
        SOCIALACTIVITIESYOUOTHERSLINECHART: 'SOCIAL_ACTIVITIES_YOU_OTHERS_LINE_CHART' as TypeEnum,
        SOCIALACTIVITIESOTHERSYOUSTACKBAR: 'SOCIAL_ACTIVITIES_OTHERS_YOU_STACK_BAR' as TypeEnum,
        SOCIALACTIVITIESOTHERSYOULINECHART: 'SOCIAL_ACTIVITIES_OTHERS_YOU_LINE_CHART' as TypeEnum,
        MESSAGES: 'MESSAGES' as TypeEnum,
        ASSOCIATIONS: 'ASSOCIATIONS' as TypeEnum,
        SOCIALFRIENDSLIST: 'SOCIAL_FRIENDS_LIST' as TypeEnum,
        SOCIALFRIENDSLINECHART: 'SOCIAL_FRIENDS_LINE_CHART' as TypeEnum,
        SOCIALFOLLOWERSLIST: 'SOCIAL_FOLLOWERS_LIST' as TypeEnum,
        SOCIALFOLLOWERSLINECHART: 'SOCIAL_FOLLOWERS_LINE_CHART' as TypeEnum,
        IDENTIFICATION: 'IDENTIFICATION' as TypeEnum,
        FURTHERINFORMATION: 'FURTHER_INFORMATION' as TypeEnum,
        PROFILE: 'PROFILE' as TypeEnum,
        ABOUTME: 'ABOUT_ME' as TypeEnum,
        LIFEEVENTS: 'LIFE_EVENTS' as TypeEnum,
        OVERVIEW: 'OVERVIEW' as TypeEnum,
        MUSICBOOKS: 'MUSIC_BOOKS' as TypeEnum,
        MUSIC: 'MUSIC' as TypeEnum,
        ACHIEVEMENTSGAMES: 'ACHIEVEMENTS_GAMES' as TypeEnum,
        MOVIESSHOWS: 'MOVIES_SHOWS' as TypeEnum,
        APPS: 'APPS' as TypeEnum,
        VOLUNTEERWORK: 'VOLUNTEER_WORK' as TypeEnum,
        TV: 'TV' as TypeEnum,
        SUBSCRIPTIONS: 'SUBSCRIPTIONS' as TypeEnum,
        FAVOURITES: 'FAVOURITES' as TypeEnum,
        COLLECTIONS: 'COLLECTIONS' as TypeEnum,
        HASHTAGS: 'HASHTAGS' as TypeEnum,
        ONLINEACCOUNTS: 'ONLINE_ACCOUNTS' as TypeEnum,
        JOB: 'JOB' as TypeEnum,
        FAMILY: 'FAMILY' as TypeEnum,
        DEVICES: 'DEVICES' as TypeEnum,
        SKILLS: 'SKILLS' as TypeEnum,
        ADVERTISING: 'ADVERTISING' as TypeEnum,
        SPORTS: 'SPORTS' as TypeEnum,
        ACTIVITIES: 'ACTIVITIES' as TypeEnum,
        ACTIVITY: 'ACTIVITY' as TypeEnum,
        ARTSLITERATURE: 'ARTS_LITERATURE' as TypeEnum,
        ENTERTAINMENT: 'ENTERTAINMENT' as TypeEnum,
        PLACES: 'PLACES' as TypeEnum,
        CHARACTERIZATION: 'CHARACTERIZATION' as TypeEnum,
        FRIENDS: 'FRIENDS' as TypeEnum,
        MONEYPAYMENTS: 'MONEY_PAYMENTS' as TypeEnum,
        BUSINESS: 'BUSINESS' as TypeEnum,
        ACCOUNTS: 'ACCOUNTS' as TypeEnum,
        POSITION: 'POSITION' as TypeEnum,
        GAMES: 'GAMES' as TypeEnum,
        MOVIESTV: 'MOVIES_TV' as TypeEnum,
        LOCATION: 'LOCATION' as TypeEnum,
        FOLLOWERS: 'FOLLOWERS' as TypeEnum,
        COMMUNICATION: 'COMMUNICATION' as TypeEnum,
        ADMINISTRATION: 'ADMINISTRATION' as TypeEnum,
        QUALIFICATIONS: 'QUALIFICATIONS' as TypeEnum,
        ONLINE: 'ONLINE' as TypeEnum,
        TERMINALS: 'TERMINALS' as TypeEnum
    }
}
