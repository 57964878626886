export class FileHelper {

  public static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});

  }

  public static dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    try {
      return new File([u8arr], filename, {type:mime});
    } catch (err) {
      // Unfortunately, IE and Edge does not support the File constructor from File API, so we are using Blob constructor instead
      let blob = new Blob([u8arr], {type: mime});
      // @ts-ignore
      blob.name = fileName;
      return blob;
    }
  }

  public static getFileExtension(fileName: string){
    return fileName.split('.')[fileName.split('.').length - 1];
  }

  public static blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = () => {
      callback(a.result);
    }
    a.readAsDataURL(blob);
  }

}
