import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BrowserOutdatedGuard} from './shared/services/auth-guard.service';
import { AppPreloadingStrategy } from './shared/services/preloading-strategy.service';

import {
  BoniCertificateInfoPageModule
} from "./main-components/boni-certificate-info-page/boni-certificate-info-page.module";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-components/loading/loading.module').then(m => m.LoadingModule),
    data: { preload: true, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'admin/:code',
    loadChildren: () => import('./main-components/admin-panel/admin-panel.module').then(m => m.AdminPanelModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'techAdmin/:code',
    loadChildren: () => import('./main-components/techAdmin-panel/techAdmin-panel.module').then(m => m.TechAdminPanelModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main-components/dashboard/dashboard.module').then(m => m.DashboardComponentModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./main-components/login/login.module').then(m => m.LoginModule),
    data: { preload: true, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'login/:code',
    loadChildren: () => import('./main-components/login/login.module').then(m => m.LoginModule),
    data: { preload: true, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./main-components/register/register.module').then(m => m.RegisterComponentModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'signup/:code',
    loadChildren: () => import('./main-components/register/register.module').then(m => m.RegisterComponentModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'signup/recommendation/:code',
    loadChildren: () => import('./main-components/register/register.module').then(m => m.RegisterComponentModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./main-components/terms/terms.module').then(m => m.TermsModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'terms/digitale-sofortauskunft',
    loadChildren: () => import('./main-components/terms-digitale-sofortauskunft/terms-digitale-sofortauskunft.module').then(m => m.TermsDigitaleSofortauskunftModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'privacy',
    loadChildren: () => import('./main-components/privacy/privacy.module').then(m => m.PrivacyModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./main-components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'outdated-browser',
    loadChildren: () => import('./main-components/outdated-browser-page/outdated-browser.module').then(m => m.OutdatedBrowserModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'delete-confirmation/:code',
    loadChildren: () => import('./main-components/delete-confirmation/delete-confirmation.module').then(m => m.DeleteConfirmationModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'download-data-confirmed/:code',
    loadChildren: () => import('./main-components/download-data-confirmation/download-data-confirmation.module').then(m => m.DownloadDataConfirmationModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'email-activation',
    loadChildren: () => import('./main-components/activation-email/activation-email.module').then(m => m.ActivationEmailModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'certificate-landing/:code',
    loadChildren: () => import('./main-components/certificate-validation-page/certificate-validation-page.module').then(m => m.CertificateValidationPageModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'validierung-bonitaetszertifikat',
    loadChildren: () => import('./main-components/certificate-validation-page/certificate-validation-page.module').then(m => m.CertificateValidationPageModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'tenant-certificate-landing/:code',
    loadChildren: () => import('./main-components/certificate-tenant-landing-page/certificate-tenant-landing-page.module').then(m => m.CertificateTenantLandingPageModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'tenant-certificate-landing',
    loadChildren: () => import('./main-components/certificate-tenant-landing-page/certificate-tenant-landing-page.module').then(m => m.CertificateTenantLandingPageModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'certificate-details/:code',
    loadChildren: () => import('./main-components/certificate-details/certificate-details.module').then(m => m.CertificateDetailsModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'certificate-details',
    loadChildren: () => import('./main-components/certificate-details/certificate-details.module').then(m => m.CertificateDetailsModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'bonitat-certificate',
    loadChildren: () => import('./main-components/boni-certificate-info-page/boni-certificate-info-page.module').then(m => m.BoniCertificateInfoPageModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'wohnumfeld-check-details',
    loadChildren: () => import('./main-components/living-environment-details/living-environment-details.module').then(m => m.LivingEnvironmentDetailsModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'terms/wohnumfeld-check',
    loadChildren: () => import('./main-components/product-terms/wuc-product-terms.module').then(m => m.WucProductTermsModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'terms/bonitaetszertifikat',
    loadChildren: () => import('./main-components/terms-conditions-boni-certificate/terms-conditions-boni-certificate.module').then(m => m.TermsConditionsBoniCertificateModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'third-party-landing',
    loadChildren: () => import('./main-components/third-party-info-page/third-party-landing.module').then(m => m.ThirdPartyLandingModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('./main-components/third-party-signup/third-party-signup.module').then(m => m.ThirdPartySignupModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'registration/:code',
    loadChildren: () => import('./main-components/third-party-signup/third-party-signup.module').then(m => m.ThirdPartySignupModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'validate-email',
    loadChildren: () => import('./main-components/validate-email/validate-email.module').then(m => m.ValidateEmailModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'expired-code',
    loadChildren: () => import('./main-components/expired-code/expired-code.module').then(m => m.ExpiredCodeModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'change-password/:code',
    loadChildren: () => import('./main-components/change-password/change-password.module').then(m => m.ChangePasswordControlModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'impressum',
    loadChildren: () => import('./main-components/impressum/impressum.module').then(m => m.ImpressumModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./main-components/faq-section/faq-section.module').then(m => m.FaqSectionModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'delete-account-email/:code',
    loadChildren: () => import('./main-components/delete-account-email/delete-account-email.module').then(m => m.DeleteAccountEmailModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'download-success-plugin',
    loadChildren:() => import('./main-components/download-success-plugin/download-success-plugin.module').then(m => m.DownloadSuccessPluginModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  {
    path: 'unauthorized-access-report/:code',
    loadChildren: () => import('./main-components/unauthorized-access-report/unauthorized-access-report.module').then(m => m.UnauthorizedAccessReportModule),
    data: { preload: false, delay: false },
    canActivate: [BrowserOutdatedGuard]
  },
  // {
  //   path: 'birthday-correction-sent/:code',
  //   loadChildren: './main-components/birthday-correction-sent/birthday-correction-sent.module#BirthdayCorrectionSentModule',
  //   data: { preload: false, delay: false },
  //   canActivate: [BrowserOutdatedGuard]
  // },
  // {
  //   path: 'premium',
  //   loadChildren: './main-components/premium-section/premium-section.module#PremiumSectionModule',
  //   data: { preload: false, delay: false },
  //   canActivate: [BrowserOutdatedGuard]
  // },

  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: AppPreloadingStrategy, initialNavigation: 'enabledNonBlocking', scrollPositionRestoration: 'enabled'})],
  providers: [AppPreloadingStrategy],
  exports: [RouterModule]
})
export class AppRoutingModule { }
