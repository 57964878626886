import { CommunicationModel } from "./communicationModel";
import {TargetSubCompany} from "./targetSubCompany";

/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TargetDataModel {
    bucketUpdatesNotSeen?: number;
    errorType?: TargetDataModel.ErrorTypeEnum;
    isDataCorrected?: boolean;
    lastCommunicationId?: string;
    lastRequestDate?: string;
    logoImageName?: string;
    targetId?: string;
    targetName?: string;
    lastStatusDate?: string;
    website?: string;
    street?: string;
    city?: string;
    statusHistory?: Array<TargetDataModel.StatusHistoryEnum>;
    targetStatus?: TargetDataModel.TargetStatusEnum;
    targetSubcategory?: TargetDataModel.TargetSubcategoryEnum;
    targetSubcategorySection?: TargetDataModel.TargetSubcategorySectionEnum;
    responseType?: TargetDataModel.TargetResponseTypeEnum;
    targetResponseType?: TargetDataModel.TargetLetterResponseTypeEnum;
    actionSubType?: CommunicationModel.ActionSubTypeEnum;
    otherCategoryTargetImage?: { [key: string]: string; };
    deliveryMethodIcons?: any;
    timeIcon?: string;
    complexityIcon?: string;
    targetSubCompanies?: Array<TargetSubCompany>;
    selectedSubCompanies?: Array<TargetSubCompany>;
    showMoreInfo?: boolean;
    targetType?: TargetDataModel.TargetTypeEnum;
    qualityDataNumberOfStars?: number;
    shippingTimeNumberOfStars?: number;
}
export namespace TargetDataModel {
    export type ErrorTypeEnum = 'GENERAL' | 'NAME';
    export const ErrorTypeEnum = {
        GENERAL: 'GENERAL' as ErrorTypeEnum,
        NAME: 'NAME' as ErrorTypeEnum
    }
    export type TargetStatusEnum = 'PENDING' | 'OVERDUE' | 'REMINDER_WAS_SENT' | 'REQUEST_FAILED' | 'READY_FOR_UPLOAD' | 'READY_FOR_DOWNLOAD' | 'DELIVERED' | 'DELIVERED_BY_UPLOAD' | 'DELIVERED_VIA_PLUGIN' | 'DATA_PROCESSED' | 'READY_FOR_UPDATE' | 'RECEIVED_EMAIL' | 'INTERMEDIATE_INFO_RECEIVED' | 'INTERMEDIATE_INFO_REPLY' | 'DATA_DELETION_REQUESTED' | 'DATA_DELETION_CONFIRMED' | 'DATA_DELETION_CONTRADICTED' | 'DATA_CORRECTION_REQUESTED' | 'DATA_CORRECTION_CONFIRMED' | 'DATA_CORRECTION_CONTRADICTED' | 'DOWNLOAD_LINK_RECEIVED' | 'PIN_RECEIVED' | 'REPLY_RECEIVED' | 'DATA_DELETED' | 'DELETION_REPLY' | 'REPLY_TO_REMINDER' | 'DATA_RECEIVED_ENCRYPTED' | 'DATA_PARSING_ERROR' | 'DATA_UPLOADED' | 'DATA_EXPIRED' | 'MARKED_REQUEST_COMPLETED' | 'DATA_RECEIVED_NO_DATA' | 'DATA_RECEIVED_NO_SCORE' | 'REPLY_WITH_EMAIL' | 'REPLY_SENT';
    export const TargetStatusEnum = {
        PENDING: 'PENDING' as TargetStatusEnum,
        OVERDUE: 'OVERDUE' as TargetStatusEnum,
        REMINDERSENT: 'REMINDER_SENT' as TargetStatusEnum,
        REQUESTFAILED: 'REQUEST_FAILED' as TargetStatusEnum,
        READYFORUPLOAD: 'READY_FOR_UPLOAD' as TargetStatusEnum,
        READYFORDOWNLOAD: 'READY_FOR_DOWNLOAD' as TargetStatusEnum,
        DELIVERED: 'DELIVERED' as TargetStatusEnum,
        DELIVEREDBYUPLOAD: 'DELIVERED_BY_UPLOAD' as TargetStatusEnum,
        DELIVEREDVIAPLUGIN: 'DELIVERED_VIA_PLUGIN' as TargetStatusEnum,
        DATAPROCESSED: 'DATA_PROCESSED' as TargetStatusEnum,
        READYFORUPDATE: 'READY_FOR_UPDATE' as TargetStatusEnum,
        RECEIVEDEMAIL: 'RECEIVED_EMAIL' as TargetStatusEnum,
        INTERMEDIATEINFORECEIVED: 'INTERMEDIATE_INFO_RECEIVED' as TargetStatusEnum,
        INTERMEDIATEINFOREPLY: 'INTERMEDIATE_INFO_REPLY' as TargetStatusEnum,
        DATADELETIONREQUESTED: 'DATA_DELETION_REQUESTED' as TargetStatusEnum,
        DATADELETIONCONFIRMED: 'DATA_DELETION_CONFIRMED' as TargetStatusEnum,
        DATADELETIONCONTRADICTED: 'DATA_DELETION_CONTRADICTED' as TargetStatusEnum,
        DATACORRECTIONREQUESTED: 'DATA_CORRECTION_REQUESTED' as TargetStatusEnum,
        DATACORRECTIONCONFIRMED: 'DATA_CORRECTION_CONFIRMED' as TargetStatusEnum,
        DATACORRECTIONCONTRADICTED: 'DATA_CORRECTION_CONTRADICTED' as TargetStatusEnum,
        DOWNLOADLINKRECEIVED: 'DOWNLOAD_LINK_RECEIVED' as TargetStatusEnum,
        PINRECEIVED: 'PIN_RECEIVED' as TargetStatusEnum,
        REPLYRECEIVED: 'REPLY_RECEIVED' as TargetStatusEnum,
        DATADELETED: 'DATA_DELETED' as TargetStatusEnum,
        REPLYTOREMINDER: 'REPLY_TO_REMINDER' as TargetStatusEnum,
        DELETIONREPLY: 'DELETION_REPLY' as TargetStatusEnum,
        DATARECEIVEDENCRYPTED: 'DATA_RECEIVED_ENCRYPTED' as TargetStatusEnum,
        DATAPARSINGERROR: 'DATA_PARSING_ERROR' as TargetStatusEnum,
        DATAUPLOADED: 'DATA_UPLOADED' as TargetStatusEnum,
        DATAEXPIRED: 'DATA_EXPIRED' as TargetStatusEnum,
        MARKEDREQUESTCOMPLETED: 'MARKED_REQUEST_COMPLETED' as TargetStatusEnum,
        DATARECEIVEDNOSCORE: 'DATA_RECEIVED_NO_SCORE' as TargetStatusEnum,
        DATARECEIVEDNODATA: 'DATA_RECEIVED_NO_DATA' as TargetStatusEnum,
        REPLYWITHEMAIL: 'REPLY_WITH_EMAIL' as TargetStatusEnum,
        REPLYSENT: 'REPLY_SENT' as TargetStatusEnum
    }
  export type TargetSubcategoryEnum = 'SOCIAL_MEDIA' | 'CREDIT_SCORING' | 'DATA_BROKER' | 'TELECOMMUNICATION_UTILITIES' | 'LOYALTY_AGGREGATORS' | 'RETAIL' | 'BANKING_FINANCIAL' | 'INSURANCES' | 'MOBILITY' | 'MEDIA' | 'AUTHORITIES' | 'BROWSER_EXTENSION' | 'SEARCH' | 'SPORTS';
  export const TargetSubcategoryEnum = {
    SOCIALMEDIA: 'SOCIAL_MEDIA' as TargetSubcategoryEnum,
    CREDITSCORING: 'CREDIT_SCORING' as TargetSubcategoryEnum,
    DATABROKER: 'DATA_BROKER' as TargetSubcategoryEnum,
    TELECOMMUNICATIONUTILITIES: 'TELECOMMUNICATION_UTILITIES' as TargetSubcategoryEnum,
    LOYALTYAGGREGATORS: 'LOYALTY_AGGREGATORS' as TargetSubcategoryEnum,
    RETAIL: 'RETAIL' as TargetSubcategoryEnum,
    BANKINGFINANCIAL: 'BANKING_FINANCIAL' as TargetSubcategoryEnum,
    INSURANCES: 'INSURANCES' as TargetSubcategoryEnum,
    MOBILITY: 'MOBILITY' as TargetSubcategoryEnum,
    MEDIA: 'MEDIA' as TargetSubcategoryEnum,
    AUTHORITIES: 'AUTHORITIES' as TargetSubcategoryEnum,
    BROWSEREXTENSION: 'BROWSER_EXTENSION' as TargetSubcategoryEnum,
    SEARCH: 'SEARCH' as TargetSubcategoryEnum,
    SPORTS: 'SPORTS' as TargetSubcategoryEnum
  }

  export type StatusHistoryEnum = 'PENDING' | 'OVERDUE' | 'REMINDER_SENT' | 'DELIVERED' | 'DELIVERED_BY_UPLOAD' | 'DELIVERED_VIA_PLUGIN' | 'DATA_PROCESSED' | 'READY_FOR_UPDATE' | 'INTERMEDIATE_INFO_RECEIVED' | 'DATA_DELETION_REQUESTED' | 'DATA_DELETION_CONFIRMED' | 'DATA_DELETION_CONTRADICTED' | 'DATA_CORRECTION_REQUESTED' | 'DATA_CORRECTION_CONFIRMED' | 'DATA_CORRECTION_CONTRADICTED' | 'DOWNLOAD_LINK_RECEIVED' | 'PIN_RECEIVED' | 'REPLY_RECEIVED' | 'MARKED_REQUEST_COMPLETED' | 'DATA_RECEIVED_ENCRYPTED' | 'DATA_RECEIVED_NO_DATA' | 'DATA_RECEIVED_NO_SCORE' | 'DATA_PARSING_ERROR' | 'DATA_EXPIRED' | 'REPLY_TO_REMINDER' | 'DELETION_REPLY' | 'DATA_DELETED' | 'REPLY_WITH_EMAIL' | 'REPLY_SENT';
  export const StatusHistoryEnum = {
      PENDING: 'PENDING' as StatusHistoryEnum,
      OVERDUE: 'OVERDUE' as StatusHistoryEnum,
      REMINDERSENT: 'REMINDER_SENT' as StatusHistoryEnum,
      DELIVERED: 'DELIVERED' as StatusHistoryEnum,
      DELIVEREDBYUPLOAD: 'DELIVERED_BY_UPLOAD' as StatusHistoryEnum,
      DELIVEREDVIAPLUGIN: 'DELIVERED_VIA_PLUGIN' as StatusHistoryEnum,
      DATAPROCESSED: 'DATA_PROCESSED' as StatusHistoryEnum,
      READYFORUPDATE: 'READY_FOR_UPDATE' as StatusHistoryEnum,
      INTERMEDIATEINFORECEIVED: 'INTERMEDIATE_INFO_RECEIVED' as StatusHistoryEnum,
      DATADELETIONREQUESTED: 'DATA_DELETION_REQUESTED' as StatusHistoryEnum,
      DATADELETIONCONFIRMED: 'DATA_DELETION_CONFIRMED' as StatusHistoryEnum,
      DATADELETIONCONTRADICTED: 'DATA_DELETION_CONTRADICTED' as StatusHistoryEnum,
      DATACORRECTIONREQUESTED: 'DATA_CORRECTION_REQUESTED' as StatusHistoryEnum,
      DATACORRECTIONCONFIRMED: 'DATA_CORRECTION_CONFIRMED' as StatusHistoryEnum,
      DATACORRECTIONCONTRADICTED: 'DATA_CORRECTION_CONTRADICTED' as StatusHistoryEnum,
      DOWNLOADLINKRECEIVED: 'DOWNLOAD_LINK_RECEIVED' as StatusHistoryEnum,
      PINRECEIVED: 'PIN_RECEIVED' as StatusHistoryEnum,
      REPLYRECEIVED: 'REPLY_RECEIVED' as StatusHistoryEnum,
      MARKEDREQUESTCOMPLETED: 'MARKED_REQUEST_COMPLETED' as StatusHistoryEnum,
      DATARECEIVEDENCRYPTED: 'DATA_RECEIVED_ENCRYPTED' as StatusHistoryEnum,
      DATARECEIVEDNODATA: 'DATA_RECEIVED_NO_DATA' as StatusHistoryEnum,
      DATARECEIVEDNOSCORE: 'DATA_RECEIVED_NO_SCORE' as StatusHistoryEnum,
      DATAPARSINGERROR: 'DATA_PARSING_ERROR' as StatusHistoryEnum,
      DATAEXPIRED: 'DATA_EXPIRED' as StatusHistoryEnum,
      REPLYTOREMINDER: 'REPLY_TO_REMINDER' as StatusHistoryEnum,
      DELETIONREPLY: 'DELETION_REPLY' as StatusHistoryEnum,
      DATADELETED: 'DATA_DELETED' as StatusHistoryEnum,
      REPLYWITHEMAIL: 'REPLY_WITH_EMAIL' as StatusHistoryEnum,
      REPLYSENT: 'REPLY_SENT' as StatusHistoryEnum
  }

    export type TargetResponseTypeEnum = 'FORWARD_EMAIL_TO_IMD';
    export const TargetResponseTypeEnum = {
        FORWARDEMAILTOIMD: 'FORWARD_EMAIL_TO_IMD' as TargetResponseTypeEnum,
    }

  export type TargetLetterResponseTypeEnum = 'POSTAL' | 'ELECTRONIC' | 'SOCIAL' | 'PORTAL' | 'DOWNLOAD_LINK' | 'PERSONAL_EMAIL_REQUEST';
  export const TargetLetterResponseTypeEnum = {
    POSTAL: 'POSTAL' as TargetLetterResponseTypeEnum,
    ELECTRONIC: 'ELECTRONIC' as TargetLetterResponseTypeEnum,
    SOCIAL: 'SOCIAL' as TargetLetterResponseTypeEnum,
    PORTAL: 'PORTAL' as TargetLetterResponseTypeEnum,
    DOWNLOADLINK: 'DOWNLOAD_LINK' as TargetLetterResponseTypeEnum,
    PERSONALEMAILREQUEST: 'PERSONAL_EMAIL_REQUEST' as TargetLetterResponseTypeEnum
  }
  export type TargetSubcategorySectionEnum = 'CONSUMER' | 'BUSINESS';
  export const TargetSubcategorySectionEnum = {
    CONSUMER: 'CONSUMER' as TargetSubcategorySectionEnum,
    BUSINESS: 'BUSINESS' as TargetSubcategorySectionEnum
  }

  export type TargetTypeEnum = 'FACEBOOK' | 'TWITTER' | 'LINKED_IN' | 'PINTEREST' | 'GOOGLE' | 'FOURSQUARE' | 'SNAPCHAT' | 'WHATSAPP' | 'INSTAGRAM' | 'XING' | 'YOUTUBE' | 'TINDER' | 'SHAZAM' | 'SCHUFA' | 'INFOSCORE' | 'CRIF_BURGEL' | 'BONIVERSUM' | 'INFORMA_HIS' | 'IHD' | 'UNIVERSUM' | 'CS_CONNECT' | 'CREDITSAFE' | 'ACXIOM' | 'AZ_DIRECT' | 'POSTADRESS' | 'EOS' | 'SCHOBER' | 'AD_PUBLISHER' | 'REGIS' | 'E_GENTIC' | 'DEUTSCHE_POST_DIREKT' | 'BISNODE' | 'MIS' | 'DRILLISCH' | 'IONOS' | 'FREENET' | 'TELEFONICA' | 'TELEKOM' | 'VODAFONE' | 'BLAU' | 'CONGSTAR' | 'EON' | 'RWE' | 'YELLO' | 'SWM' | 'VATTENFALL' | 'GAS_DE' | 'DEUTSCHE_BAHN' | 'DHL' | 'KUEHNE_NAGEL' | 'PAYBACK' | 'DEUTSCHLAND_CARD' | 'MILES_MORE' | 'TCHIBO' | 'DOUGLAS' | 'REWE' | 'DM_MARKT' | 'ROSSMANN' | 'IKEA' | 'ZALANDO' | 'TICKETMASTER' | 'HANDELSBLATT' | 'CHECK_24' | 'SMAVA' | 'VERIVOX' | 'DEUTSCHE_BANK' | 'ING' | 'DEUTSCHE_KREDITBANK' | 'COMMERZBANK' | 'POSTBANK_DIREKT' | 'N26' | 'ALLIANZ' | 'AXA' | 'HUK' | 'GOTHAER' | 'GENERALI' | 'DEUTSCHE_FAMILIENVERSICHERUNG' | 'DEURAG' | 'TALANX' | 'SIGNAL_IDUNA' | 'DEBEKA' | 'ERGO_GROUP' | 'ZURICH_GRUPPE_DEUTSCHLAND' | 'RV_VERSICHERUNG' | 'VERSICHERUNGSKAMMER_BAYERN' | 'JDC' | 'DWS' | 'DEKAFONDS' | 'KLARNA' | 'TUI' | 'SIXT' | 'ADAC' | 'DEUTSCHE_LUFTHANSA_AG' | 'EUROWINGS' | 'BMW' | 'MERCEDES' | 'VOLKSWAGEN' | 'AXEL_SPRINGER' | 'GRUNER_JAHR' | 'SZ' | 'SPIEGEL' | 'HEISE_VERLANG' | 'GOOGLE_CHROME' | 'MOZILLA_FIREFOX' | 'MICROSOFT_EDGE' | 'KRAFTFAHRT_BUNDESAMT';
  export const TargetTypeEnum = {
    FACEBOOK: 'FACEBOOK' as TargetTypeEnum,
    TWITTER: 'TWITTER' as TargetTypeEnum,
    LINKEDIN: 'LINKED_IN' as TargetTypeEnum,
    PINTEREST: 'PINTEREST' as TargetTypeEnum,
    GOOGLE: 'GOOGLE' as TargetTypeEnum,
    FOURSQUARE: 'FOURSQUARE' as TargetTypeEnum,
    SNAPCHAT: 'SNAPCHAT' as TargetTypeEnum,
    WHATSAPP: 'WHATSAPP' as TargetTypeEnum,
    INSTAGRAM: 'INSTAGRAM' as TargetTypeEnum,
    XING: 'XING' as TargetTypeEnum,
    YOUTUBE: 'YOUTUBE' as TargetTypeEnum,
    TINDER: 'TINDER' as TargetTypeEnum,
    SHAZAM: 'SHAZAM' as TargetTypeEnum,
    SCHUFA: 'SCHUFA' as TargetTypeEnum,
    INFOSCORE: 'INFOSCORE' as TargetTypeEnum,
    CRIFBURGEL: 'CRIF_BURGEL' as TargetTypeEnum,
    BONIVERSUM: 'BONIVERSUM' as TargetTypeEnum,
    INFORMAHIS: 'INFORMA_HIS' as TargetTypeEnum,
    IHD: 'IHD' as TargetTypeEnum,
    UNIVERSUM: 'UNIVERSUM' as TargetTypeEnum,
    CSCONNECT: 'CS_CONNECT' as TargetTypeEnum,
    CREDITSAFE: 'CREDITSAFE' as TargetTypeEnum,
    ACXIOM: 'ACXIOM' as TargetTypeEnum,
    AZDIRECT: 'AZ_DIRECT' as TargetTypeEnum,
    POSTADRESS: 'POSTADRESS' as TargetTypeEnum,
    EOS: 'EOS' as TargetTypeEnum,
    SCHOBER: 'SCHOBER' as TargetTypeEnum,
    ADPUBLISHER: 'AD_PUBLISHER' as TargetTypeEnum,
    REGIS: 'REGIS' as TargetTypeEnum,
    EGENTIC: 'E_GENTIC' as TargetTypeEnum,
    DEUTSCHEPOSTDIREKT: 'DEUTSCHE_POST_DIREKT' as TargetTypeEnum,
    BISNODE: 'BISNODE' as TargetTypeEnum,
    MIS: 'MIS' as TargetTypeEnum,
    DRILLISCH: 'DRILLISCH' as TargetTypeEnum,
    IONOS: 'IONOS' as TargetTypeEnum,
    FREENET: 'FREENET' as TargetTypeEnum,
    TELEFONICA: 'TELEFONICA' as TargetTypeEnum,
    TELEKOM: 'TELEKOM' as TargetTypeEnum,
    VODAFONE: 'VODAFONE' as TargetTypeEnum,
    BLAU: 'BLAU' as TargetTypeEnum,
    CONGSTAR: 'CONGSTAR' as TargetTypeEnum,
    EON: 'EON' as TargetTypeEnum,
    RWE: 'RWE' as TargetTypeEnum,
    YELLO: 'YELLO' as TargetTypeEnum,
    SWM: 'SWM' as TargetTypeEnum,
    VATTENFALL: 'VATTENFALL' as TargetTypeEnum,
    GASDE: 'GAS_DE' as TargetTypeEnum,
    DEUTSCHEBAHN: 'DEUTSCHE_BAHN' as TargetTypeEnum,
    DHL: 'DHL' as TargetTypeEnum,
    KUEHNENAGEL: 'KUEHNE_NAGEL' as TargetTypeEnum,
    PAYBACK: 'PAYBACK' as TargetTypeEnum,
    DEUTSCHLANDCARD: 'DEUTSCHLAND_CARD' as TargetTypeEnum,
    MILESMORE: 'MILES_MORE' as TargetTypeEnum,
    TCHIBO: 'TCHIBO' as TargetTypeEnum,
    DOUGLAS: 'DOUGLAS' as TargetTypeEnum,
    REWE: 'REWE' as TargetTypeEnum,
    DMMARKT: 'DM_MARKT' as TargetTypeEnum,
    ROSSMANN: 'ROSSMANN' as TargetTypeEnum,
    IKEA: 'IKEA' as TargetTypeEnum,
    ZALANDO: 'ZALANDO' as TargetTypeEnum,
    TICKETMASTER: 'TICKETMASTER' as TargetTypeEnum,
    HANDELSBLATT: 'HANDELSBLATT' as TargetTypeEnum,
    CHECK24: 'CHECK_24' as TargetTypeEnum,
    SMAVA: 'SMAVA' as TargetTypeEnum,
    VERIVOX: 'VERIVOX' as TargetTypeEnum,
    DEUTSCHEBANK: 'DEUTSCHE_BANK' as TargetTypeEnum,
    ING: 'ING' as TargetTypeEnum,
    DEUTSCHEKREDITBANK: 'DEUTSCHE_KREDITBANK' as TargetTypeEnum,
    COMMERZBANK: 'COMMERZBANK' as TargetTypeEnum,
    POSTBANKDIREKT: 'POSTBANK_DIREKT' as TargetTypeEnum,
    N26: 'N26' as TargetTypeEnum,
    ALLIANZ: 'ALLIANZ' as TargetTypeEnum,
    AXA: 'AXA' as TargetTypeEnum,
    HUK: 'HUK' as TargetTypeEnum,
    GOTHAER: 'GOTHAER' as TargetTypeEnum,
    GENERALI: 'GENERALI' as TargetTypeEnum,
    DEUTSCHEFAMILIENVERSICHERUNG: 'DEUTSCHE_FAMILIENVERSICHERUNG' as TargetTypeEnum,
    DEURAG: 'DEURAG' as TargetTypeEnum,
    TALANX: 'TALANX' as TargetTypeEnum,
    SIGNALIDUNA: 'SIGNAL_IDUNA' as TargetTypeEnum,
    DEBEKA: 'DEBEKA' as TargetTypeEnum,
    ERGOGROUP: 'ERGO_GROUP' as TargetTypeEnum,
    ZURICHGRUPPEDEUTSCHLAND: 'ZURICH_GRUPPE_DEUTSCHLAND' as TargetTypeEnum,
    RVVERSICHERUNG: 'RV_VERSICHERUNG' as TargetTypeEnum,
    VERSICHERUNGSKAMMERBAYERN: 'VERSICHERUNGSKAMMER_BAYERN' as TargetTypeEnum,
    JDC: 'JDC' as TargetTypeEnum,
    DWS: 'DWS' as TargetTypeEnum,
    DEKAFONDS: 'DEKAFONDS' as TargetTypeEnum,
    KLARNA: 'KLARNA' as TargetTypeEnum,
    TUI: 'TUI' as TargetTypeEnum,
    SIXT: 'SIXT' as TargetTypeEnum,
    ADAC: 'ADAC' as TargetTypeEnum,
    DEUTSCHELUFTHANSAAG: 'DEUTSCHE_LUFTHANSA_AG' as TargetTypeEnum,
    EUROWINGS: 'EUROWINGS' as TargetTypeEnum,
    BMW: 'BMW' as TargetTypeEnum,
    MERCEDES: 'MERCEDES' as TargetTypeEnum,
    VOLKSWAGEN: 'VOLKSWAGEN' as TargetTypeEnum,
    AXELSPRINGER: 'AXEL_SPRINGER' as TargetTypeEnum,
    GRUNERJAHR: 'GRUNER_JAHR' as TargetTypeEnum,
    SZ: 'SZ' as TargetTypeEnum,
    SPIEGEL: 'SPIEGEL' as TargetTypeEnum,
    HEISEVERLANG: 'HEISE_VERLANG' as TargetTypeEnum,
    GOOGLECHROME: 'GOOGLE_CHROME' as TargetTypeEnum,
    MOZILLAFIREFOX: 'MOZILLA_FIREFOX' as TargetTypeEnum,
    MICROSOFTEDGE: 'MICROSOFT_EDGE' as TargetTypeEnum,
    KRAFTFAHRTBUNDESAMT: 'KRAFTFAHRT_BUNDESAMT' as TargetTypeEnum
  }
}
