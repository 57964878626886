/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BucketDataModel } from '../model/bucketDataModel';
import { BucketModel } from '../model/bucketModel';
import { BucketSubCategoryModel } from '../model/bucketSubCategoryModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {HiddenTargetsModel, ResponseEntity} from "..";
import {BucketCertificateScoreLineChartModel} from "../model/bucketCertificateScoreLineChartModel";


@Injectable()
export class BucketControllerService {

    protected basePath = 'https://localhost:8082';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getAvailableBuckers
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailableBuckersUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BucketModel>>;
    public getAvailableBuckersUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BucketModel>>>;
    public getAvailableBuckersUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BucketModel>>>;
    public getAvailableBuckersUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<BucketModel>>(`${this.basePath}/availableBuckets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategories
     *
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoriesUsingGET(bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BucketSubCategoryModel>>;
    public getBucketSubcategoriesUsingGET(bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BucketSubCategoryModel>>>;
    public getBucketSubcategoriesUsingGET(bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BucketSubCategoryModel>>>;
    public getBucketSubcategoriesUsingGET(bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoriesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<Array<BucketSubCategoryModel>>(`${this.basePath}/bucket/subcategories/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * getBucketSubcategoryChildValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param parentId parentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryChildValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryChildValuesUsingGET.');
        }
        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling getBucketSubcategoryChildValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryChildValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}/${encodeURIComponent(String(parentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryTableChildValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param parentId parentId
     * @param tableValue tableValue
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryTableChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, tableValue: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryTableChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, tableValue: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryTableChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, tableValue: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryTableChildValuesUsingGET(subcategoryType: string, bucketType: string, parentId: string, tableValue: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryTableChildValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryTableChildValuesUsingGET.');
        }
        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling getBucketSubcategoryTableChildValuesUsingGET.');
        }
        if (tableValue === null || tableValue === undefined) {
            throw new Error('Required parameter tableValue was null or undefined when calling getBucketSubcategoryTableChildValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        let basicAuthHeader = localStorage.getItem('access_token');
        headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryTableChildValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}/${encodeURIComponent(String(parentId))}/${encodeURIComponent(String(tableValue))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryDetailValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryDetailValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryDetailValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryDetailValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryDetailValuesUsingGET(subcategoryType: string, bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryDetailValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryDetailValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryDetailValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryDonutValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryDonutValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryDonutValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryDonutValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryDonutValuesUsingGET(subcategoryType: string, bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryDonutValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryDonutValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryDonutValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryLineChartValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryLineChartValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryLineChartValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryLineChartValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryLineChartValuesUsingGET(subcategoryType: string, bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryLineChartValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryLineChartValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryLinechartValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryListValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryListValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryListValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryListValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryListValuesUsingGET(subcategoryType: string, bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryListValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryListValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryListValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryStackBarValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryStackBarValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryStackBarValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryStackBarValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryStackBarValuesUsingGET(subcategoryType: string, bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryStackBarValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryStackBarValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryStackbarValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBucketSubcategoryTimeLineValues
     *
     * @param subcategoryType subcategoryType
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBucketSubcategoryTimeLineValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketSubCategoryModel>;
    public getBucketSubcategoryTimeLineValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketSubCategoryModel>>;
    public getBucketSubcategoryTimeLineValuesUsingGET(subcategoryType: string, bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketSubCategoryModel>>;
    public getBucketSubcategoryTimeLineValuesUsingGET(subcategoryType: string, bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subcategoryType === null || subcategoryType === undefined) {
            throw new Error('Required parameter subcategoryType was null or undefined when calling getBucketSubcategoryTimeLineValuesUsingGET.');
        }
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getBucketSubcategoryTimeLineValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketSubCategoryModel>(`${this.basePath}/bucket/subcategoryTimelineValues/${encodeURIComponent(String(subcategoryType))}/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getUncategorizedBucketValues
     *
     * @param bucketType bucketType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUncategorizedBucketValuesUsingGET(bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<BucketDataModel>;
    public getUncategorizedBucketValuesUsingGET(bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BucketDataModel>>;
    public getUncategorizedBucketValuesUsingGET(bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BucketDataModel>>;
    public getUncategorizedBucketValuesUsingGET(bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (bucketType === null || bucketType === undefined) {
            throw new Error('Required parameter bucketType was null or undefined when calling getUncategorizedBucketValuesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
      let basicAuthHeader = localStorage.getItem('access_token');
      headers = headers.set("Authorization", "bearer " + basicAuthHeader);
        return this.httpClient.get<BucketDataModel>(`${this.basePath}/bucket/uncategorizedValues/${encodeURIComponent(String(bucketType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * getBucketHiddenTargets
   *
   * @param bucketType bucketType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBucketHiddenTargetsUsingGET(bucketType: string, observe?: 'body', reportProgress?: boolean): Observable<HiddenTargetsModel>;
  public getBucketHiddenTargetsUsingGET(bucketType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HiddenTargetsModel>>;
  public getBucketHiddenTargetsUsingGET(bucketType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HiddenTargetsModel>>;
  public getBucketHiddenTargetsUsingGET(bucketType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (bucketType === null || bucketType === undefined) {
      throw new Error('Required parameter bucketType was null or undefined when calling getBucketHiddenTargetsUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<HiddenTargetsModel>(`${this.basePath}/hiddenBucketTargets/${encodeURIComponent(String(bucketType))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * setBucketHiddenTargets
   *
   * @param targetList targetList
   * @param bucketType bucketType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setBucketHiddenTargetsUsingPUT(targetList: Array<string>, bucketType?: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public setBucketHiddenTargetsUsingPUT(targetList: Array<string>, bucketType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public setBucketHiddenTargetsUsingPUT(targetList: Array<string>, bucketType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public setBucketHiddenTargetsUsingPUT(targetList: Array<string>, bucketType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (targetList === null || targetList === undefined) {
      throw new Error('Required parameter targetList was null or undefined when calling setBucketHiddenTargetsUsingPUT.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (bucketType !== undefined) {
      queryParameters = queryParameters.set('bucketType', <any>bucketType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.put<ResponseEntity>(`${this.basePath}/hiddenBucketTargets/${encodeURIComponent(String(bucketType))}`,
      targetList,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getCertificateChartValues
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCertificateChartValuesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BucketCertificateScoreLineChartModel>>;
  public getCertificateChartValuesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BucketCertificateScoreLineChartModel>>>;
  public getCertificateChartValuesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BucketCertificateScoreLineChartModel>>>;
  public getCertificateChartValuesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<Array<BucketCertificateScoreLineChartModel>>(`${this.basePath}/bucket/subcategoryCertificateChart`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getBucketTotalHitsDetailsPerSite
   *
   * @param url url
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBucketTotalHitsDetailsPerSiteUsingGET(url?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
  public getBucketTotalHitsDetailsPerSiteUsingGET(url?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
  public getBucketTotalHitsDetailsPerSiteUsingGET(url?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
  public getBucketTotalHitsDetailsPerSiteUsingGET(url?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (url !== undefined) {
      queryParameters = queryParameters.set('url', <any>url);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get<Array<string>>(`${this.basePath}/bucket/totalHitsPerUrl`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
