import {Inject, Injectable, Optional} from '@angular/core';
import {BASE_PATH, BASE_AUTH_PATH} from '../api-models/variables';
import { environment} from "../../../environments/environment"
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class HttpClient2 {
      //TODO: add dev auth server
      basePath : string = "https://localhost:8083";
      baseAuthPath : string = "https://localhost:8083";

    constructor(private http: HttpClient,
                @Optional()@Inject(BASE_AUTH_PATH) baseAuthPath: string,
                @Optional()@Inject(BASE_PATH) basePath: string) {
      if (basePath) {
        this.basePath = basePath;
      }
      if (baseAuthPath) {
        this.baseAuthPath = baseAuthPath;
      }
    }

  createAuthorizationHeader() : HttpHeaders {
      let headers = new HttpHeaders();
      let basicAuthHeader = btoa(environment.oAuth2ClientId+`:`+environment.oAuth2ClientSecret);
      headers = headers.append("Authorization", "Basic " + basicAuthHeader);
      headers = headers.append('Accept', `application/json`);
      headers = headers.append('Content-Type', `application/x-www-form-urlencoded`);
      headers = headers.append('X-Requested-With', `XMLHttpRequest`);

      return headers;
  }

  createFullAuthorizationHeader() : HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', `application/json`);
    headers = headers.append('Content-Type', `application/x-www-form-urlencoded`);
    headers = headers.append('X-Requested-With', `XMLHttpRequest`);
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization',  `Bearer ${localStorage.getItem('pre_access_token')}`);
    return headers;
}

  getAuth(url){
      let headers = this.createFullAuthorizationHeader();
      return this.http.get(this.baseAuthPath + url, {
          headers: headers,
          withCredentials: true
      });
    }

    postAuth(url, data) {
        let headers = this.createAuthorizationHeader();
      return this.http.post(this.baseAuthPath + url, data, {
        headers: headers,
        withCredentials: true
      });
    }


  get(url) {
    let headers = this.createAuthorizationHeader();
    return this.http.get(this.basePath + url, {
      headers: headers,
      withCredentials: true
    });
  }

  post(url, data) {
      let headers = this.createAuthorizationHeader();
      return this.http.post(this.basePath + url, data, {
          headers: headers,
          withCredentials: true
    });
  }

}
