import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {Observable} from "rxjs";
import {WebActivityReport} from "../model/webActivityReport";
import {WebsiteReportModel} from "../model/websiteReportModel";
import {WebActivityOverTimeStatistics} from "../model/webActivityOverTimeStatistics";
import {SearchQueryStatisticModel} from "../model/searchQueryStatisticModel";
import {WebsiteCategoryReportModel} from "../model/websiteCategoryReportModel";
import {WebActivityHabitsStatistics} from "../model/webActivityHabitsStatistics";

@Injectable()
export class WebActivityControllerService {

  protected basePath = 'https://localhost:8082';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  public computeTopWebsitesForUser(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<Array<WebsiteReportModel>>;
  public computeTopWebsitesForUser(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebsiteReportModel>>>;
  public computeTopWebsitesForUser(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebsiteReportModel>>>;
  public computeTopWebsitesForUser(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<Array<WebsiteReportModel>>(`${this.basePath}/webActivity/topWebsites/me`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public computeTopWebsitesForPeerGroup(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<Array<WebsiteReportModel>>;
  public computeTopWebsitesForPeerGroup(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebsiteReportModel>>>;
  public computeTopWebsitesForPeerGroup(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebsiteReportModel>>>;
  public computeTopWebsitesForPeerGroup(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<Array<WebsiteReportModel>>(`${this.basePath}/webActivity/topWebsites/peerGroup`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  public computePersonalBrowsingActivityOverTime(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<WebActivityOverTimeStatistics>;
  public computePersonalBrowsingActivityOverTime(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WebActivityOverTimeStatistics>>;
  public computePersonalBrowsingActivityOverTime(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WebActivityOverTimeStatistics>>;
  public computePersonalBrowsingActivityOverTime(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<WebActivityOverTimeStatistics>(`${this.basePath}/webActivity/browsingActivity/me`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public computePersonalTopSearchQueries(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<Array<SearchQueryStatisticModel>>;
  public computePersonalTopSearchQueries(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SearchQueryStatisticModel>>>;
  public computePersonalTopSearchQueries(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SearchQueryStatisticModel>>>;
  public computePersonalTopSearchQueries(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<Array<SearchQueryStatisticModel>>(`${this.basePath}/webActivity/searchEngine/topSearches/me`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public computePersonalLastSearchQueries(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<Array<SearchQueryStatisticModel>>;
  public computePersonalLastSearchQueries(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SearchQueryStatisticModel>>>;
  public computePersonalLastSearchQueries(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SearchQueryStatisticModel>>>;
  public computePersonalLastSearchQueries(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<Array<SearchQueryStatisticModel>>(`${this.basePath}/webActivity/searchEngine/lastSearches/me`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public computePersonalTopCategories(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<Array<WebsiteCategoryReportModel>>;
  public computePersonalTopCategories(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebsiteCategoryReportModel>>>;
  public computePersonalTopCategories(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebsiteCategoryReportModel>>>;
  public computePersonalTopCategories(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<Array<WebsiteCategoryReportModel>>(`${this.basePath}/webActivity/topCategories/me`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public computeBrowsingBehaviourStatistics(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<WebsiteReportModel>;
  public computeBrowsingBehaviourStatistics(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WebsiteReportModel>>;
  public computeBrowsingBehaviourStatistics(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WebsiteReportModel>>;
  public computeBrowsingBehaviourStatistics(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<WebsiteReportModel>(`${this.basePath}/webActivity/browsingBehaviour`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public computeBrowsingHabitsStatistics(webActivityReport: WebActivityReport, observe?: 'body', reportProgress?: boolean): Observable<WebActivityHabitsStatistics>;
  public computeBrowsingHabitsStatistics(webActivityReport: WebActivityReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WebActivityHabitsStatistics>>;
  public computeBrowsingHabitsStatistics(webActivityReport: WebActivityReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WebActivityHabitsStatistics>>;
  public computeBrowsingHabitsStatistics(webActivityReport: WebActivityReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
      'application/json'
    ];

    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.post<WebActivityHabitsStatistics>(`${this.basePath}/webActivity/browsingHabits/me`,
      webActivityReport,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
