import { Observable }                                        from 'rxjs';
import {CommunicationControllerService} from '../api-models/api/communicationController.service';
import {Injectable} from '@angular/core';

@Injectable()
export class DownloadService extends CommunicationControllerService {
  public getAttachment(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>{
    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling getAttachmentUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);


    return this.httpClient.get(`${this.basePath}/communication/attachment/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob',
        reportProgress: reportProgress
      }
    );
  }


 public downloadUserData(lastUserDevice: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>{
    let headers = this.defaultHeaders;
    headers = headers.set("Content-Type", "text/plain");
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    let basicAuthHeader = localStorage.getItem('access_token');
    headers = headers.set("Authorization", "bearer " + basicAuthHeader);
    return this.httpClient.get(`${this.basePath}/downloadUserData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob',
        reportProgress: reportProgress
      }
    );
  }

}
