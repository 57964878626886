/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AttachmentModel {
    communicationId?: string;
    content?: Array<string>;
    id?: string;
    name?: string;
    ownerId?: string;
    type?: string;
    isEncrypted?: boolean;
    uploadDate ?: string;
}

export namespace AttachmentModel {
    export type AttachmentSourceTypeEnum = 'ID_CARD' | 'FORM' | 'SIGNATURE' |'DRIVERS_LICENSE';
    export const AttachmentSourceTypeEnum = {
        IDCARD: 'ID_CARD' as AttachmentSourceTypeEnum,
        FORM: 'FORM' as AttachmentSourceTypeEnum,
        SIGNATURE: 'SIGNATURE' as AttachmentSourceTypeEnum,
        DRIVERSLICENSE: 'DRIVERS_LICENSE' as AttachmentSourceTypeEnum
    }
}
