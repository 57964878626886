<div class="dialog-container">
  <div class="close-dialog-button" *ngIf="componentName == 'YES_BANK_ERROR'" (click)="onButtonAction()">
    <img style="width: 27px; height: 27px" (mouseenter)="exitHovered = true;"
         alt="close-button"
         (mouseleave)="exitHovered = false;"
         [src]="exitHovered ? '../../../assets/images/cross-icon-red.svg' : '../../../assets/images/cross-icon.svg'">
  </div>
  <div class="dialog-content">
    <h2 *ngIf="modalModel.title!=null" class="header-primary">{{modalModel.title}}</h2>
  </div>
  <div class="row" style="text-align: center;">
    <button class="imd-button-secondary" (click)="onButtonAction()">OK</button>
  </div>
</div>
