import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {Input} from '../../shared/models/modal.model';
import {
  TargetControllerService,
  AttachmentModel,
  PdfFormData
} from '../../shared/api-models';
import {TranslateService} from '@ngx-translate/core';
import { ChooseDataDialog } from '../choose-data/choose-data.dialog';
import { FileHelper } from '../../shared/helpers/file-helper';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {UserDataService} from "../../shared/services/user-data.service";
import {TchiboFormInputTypes} from "../../shared/models/TchiboFormInputTypes";
import * as moment from 'moment';

@Component({
  selector: 'client-pdf-form.dialog',
  templateUrl: 'client-pdf-form.dialog.html',
  styleUrls: ['./client-pdf-form.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class ClientPdfForm {

  formAttachment: AttachmentModel;
  readonly dpiRatio = 96 / 72;
  pdfSrc: any;
  public myForm: UntypedFormGroup;
  public inputList: Input[] = [];
  formDataPrefill: PdfFormData;
  TchiboFormDataInputTypes = TchiboFormInputTypes;

  isCertificateTarget: boolean = false;

  constructor(public translate: TranslateService,
        private dialog: MatDialog,
        private targetService: TargetControllerService,
        private userService: UserDataService,
        private _fb: UntypedFormBuilder,
       public dialogRef: MatDialogRef<ChooseDataDialog>,
       @Inject(MAT_DIALOG_DATA) public data: any) {
    this.myForm = this._fb.group({});
  }

   ngOnInit() {
       this.targetService.getFormPrerequisiteUsingGET("c6c87f3e-89b4-11e8-9a84-a6cf81072f73").subscribe(result => {
              this.formAttachment = result.attachmentModel;
              let formBlob = FileHelper.b64toBlob(this.formAttachment.content,this.formAttachment.type);
              var fileURL = URL.createObjectURL(formBlob);
              this.pdfSrc = fileURL;
              this.loadComplete(this.pdfSrc);
              this.formDataPrefill = result.pdfFormData;
       })
   }

   loadComplete(pdf: PDFDocumentProxy): void {
       for (let i = 1; i <= pdf.numPages; i++) {

           // track the current page
           let currentPage = null;
           pdf.getPage(i).then(p => {
               currentPage = p;

               // get the annotations of the current page
               return p.getAnnotations();
           }).then(ann => {

               // ugly cast due to missing typescript definitions
               // const annotations = (<any>ann) as PDFAnnotationData[];
               //
               // annotations
               //     .filter(a => a.subtype === 'Widget') // get the form field annotation only
               //     .forEach(a => {
               //
               //         // get the rectangle that represent the single field
               //         // and resize it according to the current DPI
               //         const fieldRect = currentPage.getViewport({scale: this.dpiRatio})
               //                                      .convertToViewportRectangle(a.rect);
               //
               //         // add the corresponding input
               //         this.addInput(a, i, fieldRect);
               //     });
           });
       }
   }

   createInput(annotation, page: number, rect: number[] = null) {
       let formControl = new UntypedFormControl(annotation.fieldValue || '');

       const input = new Input();
       input.name = annotation.fieldName;
       input.value = annotation.fieldValue;

     // Calculate all the positions and sizes
     if (rect) {
       input.top = rect[1] - (rect[1] - rect[3]) + (1132 * (page - 1));
       input.left = rect[0] - 196;
       input.height = (rect[1] - rect[3]) * .9;
       input.width = (rect[2] - rect[0]);
     }

       if (annotation.fieldType === 'Tx') {
           input.type = 'text';
           input.value = annotation.fieldValue || '';
       }

     if (annotation.fieldType === 'Btn' && !annotation.checkBox) {
       input.type = 'radio';
       input.width = 0;
       input.height = 0;
       input.value = annotation.fieldValue || '';
     }

     if (annotation.checkBox) {
       // some checkboxes are too small, this may make them bigger
       if(input.height < 8){
         input.height = input.height + 2;
       }
       input.type = 'checkbox';
       input.value = annotation.fieldValue || false;
       // correct top
       input.top = input.top - 5;
       formControl = new UntypedFormControl(annotation.buttonValue || false);
     }

     input.value = this.setInputValue(annotation.fieldName);
       //
       // // Calculate all the positions and sizes
       // if (rect) {
       //     input.top = rect[1] - (rect[1] - rect[3]);
       //     input.left = rect[0];
       //     input.height = (rect[1] - rect[3]);
       //     input.width = (rect[2] - rect[0]);
       // }

       this.inputList.push(input);
       formControl.setValue(input.value);
       return formControl;
   }

  private addInput(annotation, page, rect: number[] = null): void {
    // add input to page
    this.myForm.addControl(annotation.fieldName, this.createInput(annotation, page, rect));
  }

  private setInputValue(fieldName: string): string | boolean{
    switch(fieldName){
      case this.TchiboFormDataInputTypes.TypeEnum.PERSONAL_DATA_NAME_INPUT:
        return this.formDataPrefill.userFirstName + " " + this.formDataPrefill.userLastName;
      case this.TchiboFormDataInputTypes.TypeEnum.PERSONAL_DATA_ADDRESS_INPUT:
        return this.formDataPrefill.userStreet + " " + this.formDataPrefill.userStreetNumber + ", " +
          this.formDataPrefill.userCity + ", " + this.formDataPrefill.userPostalCode;
      case this.TchiboFormDataInputTypes.TypeEnum.PERSONAL_DATA_EMAIL_INPUT:
        return this.formDataPrefill.userImdEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.PERSONAL_DATA_PHONE_INPUT:
        return this.formDataPrefill.userMobilePhoneNumber;
      case this.TchiboFormDataInputTypes.TypeEnum.CLIENT_CLIENTNUMBER_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.CLIENT_CLIENTNUMBER_INPUT:
        return this.formDataPrefill.userCustomerNumber;
      case this.TchiboFormDataInputTypes.TypeEnum.NEWLETTER_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.NEWLETTER_EMAIL_INPUT:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.TCHIBOEXPERT_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.TCHIBOEXPERT_EMAIL:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.QBO_INHABER_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.QBO_INHABER_EMAIL_INPUT:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.BEWERBER_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.BEWERBER_EMAIL_INPUT:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.TCHIBO_BLOG_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.TCHIBO_BLOG_EMAIL_INPUT:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.TCHIBO_COMMUNITY_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.TCHIBO_COMMUNITY_INPUT:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.SONSTIGE_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.SONSTIGE_LIEFERANT_NUMBER_INPUT:
        return this.formDataPrefill.userEmail;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_ALL_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_BANKKONTO_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_BONITATSDATEN_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_BESTELLHISTORIE_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_KONTOAUSZUG_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_TCHIBOCARD_DATEN_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_SERVICEKONTAKTE_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_INFORMATIONEN_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_REISEBUCHUNGEN:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_TCHIBO_EXPERTS_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_BLOG_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.AUSKUNFT_QBO_PROFIL_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.SOFERN_BEWERBUNGSUNTERLAGEN_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.ZUR_BEARBEITUNG_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.JA_EMAIL_DATEN_CHECKBOX:
        return true;
      case this.TchiboFormDataInputTypes.TypeEnum.PLACE_DATE_INPUT:
        return this.formDataPrefill.userCity + ', ' + moment().format('DD.MM.YYYY');
      default:
        return '';
    }
  }

  public getInputPosition(input: Input): any {
    return {
      top: `${input.top}px`,
      left: `${input.left}px`,
      height: `${input.height}px`,
      width: `${input.width}px`,
    };
  }

    saveChanges() {
        console.log(this.myForm);
      this.dialogRef.close({data: this.myForm.value});
      //   this.dialogRef.close({data: this.inputList});
    }

}
