import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../environments/environment';
// import {Angulartics2Piwik} from 'angulartics2/piwik';
import * as $ from 'jquery';
import {PersistenceService} from "./shared/services/persistence-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private translate: TranslateService,private persistence: PersistenceService) {
    // angulartics2Piwik.startTracking();
    translate.setDefaultLang('de');
    this.translate.use('de'); // use starting language
  }

  ngOnInit() {
    if (environment.production == true) {
      console.log = function (){};
    }
    this.persistence.resetLanguageToDefault();

    //android keyboard shrink fix
    if(window.matchMedia('(max-width:500px)').matches){
      setTimeout(function () {
        console.log('a intrat')
        let viewheight = $(window).height();
        let viewwidth = $(window).width();
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
      }, 300);
    }
  }
}
