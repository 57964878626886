import {Injectable} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import {environment} from '../../../environments/environment'
import {AuthService} from './auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from "@angular/common";

const STORE_KEY = 'userLastActionTimestamp';

@Injectable()
export class PresenceService {
  public static runTimer: boolean;
  public USER_MAX_IDLE_TIMER_VALUE_IN_MIN: number = environment.idleTimeoutForAutomaticLogout;
  private sessionIdleMonitor: Observable<number>;

  constructor(private authService: AuthService, private router: Router,
              private location: Location) {
    let path = this.location.path();
    if (!path?.includes('/privacy') && !path?.includes('/impressum')
      && !path?.includes('/forgot-password') && !path?.includes('/login')
      && !path?.includes('/change-password/') && !path?.includes('/terms')
      && !path?.includes('/validierung-bonitaetszertifikat')
      && !path?.includes('/delete-account-email')){
      this.initializePresenceMonitoring();
    }
  }

  public initializePresenceMonitoring(): void {
    if (!PresenceService.runTimer) {
      this.resetLastActionTimestamp();
      PresenceService.runTimer = true;
      this.initSessionIdleMonitor();
      this.subscribeToIdleMonitor();
    }
  }

  private initSessionIdleMonitor(): void {
    const intervalDuration = 30 * 1000; //every 30 seconds
    this.sessionIdleMonitor = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => PresenceService.runTimer)
    );
  }

  private subscribeToIdleMonitor(): void {
    this.sessionIdleMonitor.subscribe(next => {
      const sessionIsIdle = this.sessionIsIdle();
      if (sessionIsIdle && PresenceService.runTimer) {
        PresenceService.runTimer = false;
        this.automaticallyLogoutUser();
      }
    });
  }

  private automaticallyLogoutUser() {
    this.router.navigate(['login']);
    this.cleanUpPresenceLocalStorage();
    this.authService.logout();
    this.authService.showSessionLogOut();
  }

  public resetLastActionTimestamp(): void {
    if (PresenceService.runTimer) {
      this.lastAction = Date.now();
    }
  }

  public cleanUpPresenceLocalStorage(): void {
    localStorage.removeItem(STORE_KEY);
  }

  private sessionIsIdle(): boolean {
    const now = Date.now();
    const timeLeft = this.lastAction + this.USER_MAX_IDLE_TIMER_VALUE_IN_MIN * 60 * 1000;
    const diff = timeLeft - now;
    return diff < 0;
  }

  get lastAction(): number {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

}
